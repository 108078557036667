///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { returnCombinedTaskRoles, returnExpandedTaskTableData } from 'app/models/projects/project_services'
import { tableColumns_ProjectRecentlyClosedTasks } from 'app/models/tasks'
import { tableColumns_AllProjectsOpenTasks, tableColumns_MyProjectsOpenTasks } from 'app/models/tasks/task_table'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
// Services
import { Badge, Box, Button, Card, Divider, Typography } from '@mui/material/'
import { globalTaskCategories, globalTaskTypes } from 'app/models/tasks/global_tasks'
import { useNavigate } from 'react-router-dom'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveTaskBlueprints_Query } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_InternalUsers_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import {
  DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query,
  DatabaseRef_UserCreatedOpenAndReadyTasks_Query,
  DatabaseRef_UserCreatedRecentlyCompletedTasks_Query,
  DatabaseRef_UserOpenAndReadyTasks_Query,
  DatabaseRef_UserRecentlyCompletedTasks_Query,
} from 'rfbp_aux/services/database_endpoints/operations/tasks'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsInterface_InputHooksObject,
} from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'
import { TableBasic, TsInterface_TableAdditionalData, TsInterface_TableSettings } from 'rfbp_core/components/table'
import { TabsUrl, TsInterface_TabsSettingsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_RootData_GlobalUser,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { getProp, millisecondsPerDay, objectToArray, returnDateFromUnknownDateFormat, returnFormattedDateKey } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AllAssignedTasksListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_IN_THE_PAST_2_WEEKS: JSX.Element = <Trans>In the past 2 weeks</Trans>
const s_MY_ACTIVE_TASKS: JSX.Element = <Trans>My Active Tasks</Trans>
const s_MY_RECENTLY_COMPLETED_TASKS: JSX.Element = <Trans>My Recently Completed Tasks</Trans>
const s_MY_SNOOZED_TASKS: JSX.Element = <Trans>My Snoozed Tasks</Trans>
const s_NO_OPEN_TASKS: JSX.Element = <Trans>No Open Tasks</Trans>
const s_NO_RECENTLY_COMPLETED_TASKS: JSX.Element = <Trans>No Recently Completed Tasks</Trans>
const s_NO_SNOOZED_TASKS: JSX.Element = <Trans>No Snoozed Tasks</Trans>
const s_OPEN_TASKS: JSX.Element = <Trans>Open Tasks</Trans>
const s_PHONE: JSX.Element = <Trans>Phone</Trans>
const s_SEARCH_ACTIVE_TASKS: JSX.Element = <Trans>Search Active Tasks</Trans>
const s_USER: JSX.Element = <Trans>User</Trans>
const s_VIEW_USERS_ACTIVE_TASKS: JSX.Element = <Trans>View User's Active Tasks</Trans>
const s_TASK_FORM: JSX.Element = <Trans>Task Form</Trans>
const s_TASK_CATEGORY: JSX.Element = <Trans>Task Category</Trans>
const s_TASK_TYPE: JSX.Element = <Trans>Task Type</Trans>
const s_ASSIGNED_USER: JSX.Element = <Trans>Assigned User</Trans>
const s_DUE_DATE: JSX.Element = <Trans>Due Date</Trans>
const s_SPECIAL_INSTRUCTIONS: JSX.Element = <Trans>Special Instructions</Trans>
const se_TASKS = 'Tasks'
// { sort-end } - displayed text

// Tables
const tableSettings_ProjectTasks: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'id_number',
  sortable: true,
}

///////////////////////////////
// Functions
///////////////////////////////

const filterCompletedWithinLastTwoWeeks = (obj: TsInterface_UnspecifiedObject) => {
  const filteredObj: TsInterface_UnspecifiedObject = {}
  for (const key in obj) {
    const subObj = obj[key]
    if (subObj.status_complete === false) {
      filteredObj[key] = subObj
    } else if (subObj.timestamp_completed != null && isCompletedWithinLastTwoWeeks(returnDateFromUnknownDateFormat(subObj.timestamp_completed))) {
      filteredObj[key] = subObj
    }
  }
  return filteredObj
}

const isCompletedWithinLastTwoWeeks = (timestamp: Date) => {
  const twoWeeksAgo = new Date()
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14) // Subtract 14 days
  const completedDate = new Date(timestamp)
  return completedDate >= twoWeeksAgo && completedDate <= new Date()
}

const getStartAndEndOfWeek = (date: Date) => {
  const startOfWeek = new Date(date)
  startOfWeek.setHours(0, 0, 0, 0)
  startOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 0)
  const endOfWeek = new Date(date)
  endOfWeek.setHours(23, 59, 59, 999)
  endOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 6)
  return {
    startOfWeek,
    endOfWeek,
  }
}

const getDatesInRange = (startDate: string, endDate: string) => {
  const dates = []
  let currentDate = new Date(startDate)
  while (currentDate <= new Date(endDate)) {
    dates.push(currentDate.toISOString().slice(0, 10))
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return dates
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  const [us_loadedAllOpenTaskData, us_setLoadedAllOpenTaskData] = useState<boolean>(false)
  const [us_loadedMyOpenTaskData, us_setLoadedMyOpenTaskData] = useState<boolean>(false)
  const [us_loadedMyRecentTaskData, us_setLoadedMyRecentTaskData] = useState<boolean>(false)
  const [us_myOpenTasks, us_setMyOpenTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_myOpenCreatedTasks, us_setMyOpenCreatedTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_mySnoozedTasks, us_setMySnoozedTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_recentTasks, us_setRecentTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_recentCreatedTasks, us_setRecentCreatedTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasks, us_setScheduledTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasksFri, us_setScheduledTasksFri] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasksMon, us_setScheduledTasksMon] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasksSat, us_setScheduledTasksSat] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasksSun, us_setScheduledTasksSun] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasksThu, us_setScheduledTasksThu] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasksTue, us_setScheduledTasksTue] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasksWed, us_setScheduledTasksWed] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskBlueprints, us_setTaskBlueprints] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_internalUsers, us_setInternalUsers] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_searchOpenTasks, us_setSearchOpenTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_searchSelectedUserKey, us_setSearchSelectedUserKey] = useState<string | null>(null)
  const [us_selectedDate, us_setSelectedDate] = useState<Date>(new Date())
  const [us_weekDates, us_setWeekDates] = useState<string[]>([])
  // const [us_weekEndDate, us_setWeekEndDate] = useState<Date | null>(null)
  // const [us_weekStartDate, us_setWeekStartDate] = useState<Date | null>(null)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let weekBoundingDates = getStartAndEndOfWeek(us_selectedDate)
    // us_setWeekStartDate(weekBoundingDates.startOfWeek)
    // us_setWeekEndDate(weekBoundingDates.endOfWeek)
    let startDateKey = returnFormattedDateKey(weekBoundingDates.startOfWeek)
    let endDateKey = returnFormattedDateKey(weekBoundingDates.endOfWeek)
    let dateArray = getDatesInRange(startDateKey, endDateKey)
    us_setWeekDates(dateArray)
    return () => {}
  }, [us_selectedDate])

  // useEffect(() => {
  // 	document.title = se_TASKS
  // }, [ ])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let openTasksList: TsInterface_UnspecifiedObject = {}
      let snoozedTasksList: TsInterface_UnspecifiedObject = {}
      let cutoffTimestamp = new Date()
      for (let loopTaskKey in newData) {
        let loopTask = newData[loopTaskKey]
        let hasIncompletePrerequisites = false
        for (let loopPrerequisiteTaskKey in loopTask.prerequisite_tasks) {
          if (loopTask.prerequisite_tasks_completion == null || loopTask.prerequisite_tasks_completion[loopPrerequisiteTaskKey] == null) {
            hasIncompletePrerequisites = true
          }
        }
        if (hasIncompletePrerequisites !== true) {
          if (
            loopTask['timestamp_snooze_until'] == null ||
            returnDateFromUnknownDateFormat(loopTask['timestamp_snooze_until']).getTime() < cutoffTimestamp.getTime()
          ) {
            openTasksList[loopTaskKey] = loopTask
          } else {
            snoozedTasksList[loopTaskKey] = loopTask
          }
        }
      }
      us_setMyOpenTasks(openTasksList)
      us_setMySnoozedTasks(snoozedTasksList)
      us_setLoadedMyOpenTaskData(true)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_UserOpenAndReadyTasks_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setMyOpenTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let openCreatedTasksList: TsInterface_UnspecifiedObject = {}
      for (let loopTaskKey in newData) {
        let loopTask = newData[loopTaskKey]
        openCreatedTasksList[loopTaskKey] = loopTask
      }
      us_setMyOpenCreatedTasks(openCreatedTasksList)
      us_setLoadedMyOpenTaskData(true)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_UserCreatedOpenAndReadyTasks_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setMyOpenCreatedTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRecentTasks(newData)
      us_setLoadedMyRecentTaskData(true)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let cutoffDate = new Date(new Date().getTime() - 14 * millisecondsPerDay)
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_UserRecentlyCompletedTasks_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string, cutoffDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setRecentTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRecentCreatedTasks(newData)
      us_setLoadedMyRecentTaskData(true)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let cutoffDate = new Date(new Date().getTime() - 14 * millisecondsPerDay)
          console.log('cutoffDate', cutoffDate)
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_UserCreatedRecentlyCompletedTasks_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string, cutoffDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setRecentCreatedTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let newDataArray: TsInterface_UnspecifiedObject[] = []
      for (let loopUserKey in newData) {
        let loopUser = newData[loopUserKey]
        if (loopUser.status === 'active') {
          loopUser['value'] = loopUser.name
          newDataArray.push(loopUser)
        }
      }
      newDataArray.sort((a, b) => (a.name > b.name ? 1 : -1))
      us_setInternalUsers(newDataArray)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_InternalUsers_Query(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setInternalUsers([])
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let tasksToComplete: TsInterface_UnspecifiedObject = {}
      for (let loopTaskKey in newData) {
        let loopTask = newData[loopTaskKey]
        let hasIncompletePrerequisites = false
        for (let loopPrerequisiteTaskKey in loopTask.prerequisite_tasks) {
          if (loopTask.prerequisite_tasks_completion == null || loopTask.prerequisite_tasks_completion[loopPrerequisiteTaskKey] == null) {
            hasIncompletePrerequisites = true
          }
        }
        if (hasIncompletePrerequisites !== true) {
          tasksToComplete[loopTaskKey] = loopTask
        }
      }
      us_setSearchOpenTasks(tasksToComplete)
      us_setLoadedAllOpenTaskData(true)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_searchSelectedUserKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_UserOpenAndReadyTasks_Query(res_GCK.clientKey, us_searchSelectedUserKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSearchOpenTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_searchSelectedUserKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasksMon(filterCompletedWithinLastTwoWeeks(newData))
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_weekDates != null && us_weekDates.length > 0 && us_weekDates[0] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_weekDates[0], uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasksMon({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_weekDates])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasksTue(filterCompletedWithinLastTwoWeeks(newData))
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_weekDates != null && us_weekDates.length > 0 && us_weekDates[1] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_weekDates[1], uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasksTue({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_weekDates])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasksWed(filterCompletedWithinLastTwoWeeks(newData))
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_weekDates != null && us_weekDates.length > 0 && us_weekDates[2] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_weekDates[2], uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasksWed({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_weekDates])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasksThu(filterCompletedWithinLastTwoWeeks(newData))
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_weekDates != null && us_weekDates.length > 0 && us_weekDates[3] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_weekDates[3], uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasksThu({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_weekDates])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasksFri(filterCompletedWithinLastTwoWeeks(newData))
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_weekDates != null && us_weekDates.length > 0 && us_weekDates[4] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_weekDates[4], uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasksFri({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_weekDates])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasksSat(filterCompletedWithinLastTwoWeeks(newData))
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_weekDates != null && us_weekDates.length > 0 && us_weekDates[5] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_weekDates[5], uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasksSat({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_weekDates])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasksSun(filterCompletedWithinLastTwoWeeks(newData))
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_weekDates != null && us_weekDates.length > 0 && us_weekDates[6] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_weekDates[6], uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasksSun({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_weekDates])

  useEffect(() => {
    let groupedScheduledTasks: TsInterface_UnspecifiedObject = {}
    for (let loopTaskKey in us_scheduledTasksMon) {
      groupedScheduledTasks[loopTaskKey] = us_scheduledTasksMon[loopTaskKey]
    }
    for (let loopTaskKey in us_scheduledTasksTue) {
      groupedScheduledTasks[loopTaskKey] = us_scheduledTasksTue[loopTaskKey]
    }
    for (let loopTaskKey in us_scheduledTasksWed) {
      groupedScheduledTasks[loopTaskKey] = us_scheduledTasksWed[loopTaskKey]
    }
    for (let loopTaskKey in us_scheduledTasksThu) {
      groupedScheduledTasks[loopTaskKey] = us_scheduledTasksThu[loopTaskKey]
    }
    for (let loopTaskKey in us_scheduledTasksFri) {
      groupedScheduledTasks[loopTaskKey] = us_scheduledTasksFri[loopTaskKey]
    }
    for (let loopTaskKey in us_scheduledTasksSat) {
      groupedScheduledTasks[loopTaskKey] = us_scheduledTasksSat[loopTaskKey]
    }
    for (let loopTaskKey in us_scheduledTasksSun) {
      groupedScheduledTasks[loopTaskKey] = us_scheduledTasksSun[loopTaskKey]
    }
    us_setScheduledTasks(groupedScheduledTasks)
    return () => {}
  }, [us_scheduledTasksMon, us_scheduledTasksTue, us_scheduledTasksWed, us_scheduledTasksThu, us_scheduledTasksFri, us_scheduledTasksSat, us_scheduledTasksSun])

  useEffect(() => {
    // TEMP - Forms - only load if tasks can be created
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let taskBlueprints: TsInterface_UnspecifiedObject[] = []
      for (let loopBlueprintKey in newData) {
        let loopBlueprint = newData[loopBlueprintKey]
        loopBlueprint['value'] = loopBlueprint.name
        taskBlueprints.push(loopBlueprint)
      }
      us_setTaskBlueprints(taskBlueprints)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveTaskBlueprints_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Other Variables
  const tableAdditionalData_ProjectTasks: TsInterface_TableAdditionalData = {
    // projectTaskWorkflow: projectTaskWorkflow,
    combinedUserRoles: returnCombinedTaskRoles(uc_RootData_ClientKey),
    // projectTasks: projectTasks,
  }
  const tabsSettings: TsInterface_TabsSettingsUrl = {
    baseUrl: ApplicationPages.AllAssignedTasksListPage.url(),
    tabQueryParam: 'tab',
    overridePageTitle: true,
    basePageTitle: se_TASKS,
  }

  const formInputs_NewTask: TsInterface_FormInputs = {
    task_category: {
      data_type: 'string',
      input_type: 'multiple_choice_select',
      key: 'task_category',
      label: s_TASK_CATEGORY,
      required: true,
      options: objectToArray(globalTaskCategories).filter((taskCategory) => taskCategory.key !== 'project'),
    },
    task_type: {
      data_type: 'string',
      input_type: 'multiple_choice_select',
      key: 'task_type',
      label: s_TASK_TYPE,
      required: true,
      disabled: true,
      options: [], // Dynamically populated when form used
    },
    associated_task_blueprint_key: {
      data_type: 'string',
      input_type: 'multiple_choice_select',
      key: 'associated_task_blueprint_key',
      label: s_TASK_FORM,
      required: true,
      disabled: true,
      options: [], // Dynamically populated when form used
    },
    associated_owner_key: {
      data_type: 'string',
      input_type: 'association_autocomplete_combo_box',
      key: 'associated_owner_key',
      label: s_ASSIGNED_USER,
      required: true,
      options: us_internalUsers,
    },
    timestamp_last_scheduled_date_key: {
      data_type: 'string',
      input_type: 'timestamp_date',
      key: 'timestamp_last_scheduled_date_key',
      label: s_DUE_DATE,
      required: true,
      options: us_internalUsers,
    },
    special_instructions: {
      data_type: 'string',
      input_type: 'text_multiline',
      key: 'special_instructions',
      label: s_SPECIAL_INSTRUCTIONS,
      disabled: true,
    },
  }

  // Functions
  const rJSX_UserSearchResultWithStatus = (
    option: TsInterface_UnspecifiedObject,
    searchInputValue: string | null,
    inputHooks: TsInterface_InputHooksObject,
    additionalSearchData: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let searchResultJSX = (
      <Box sx={{ marginLeft: '8px', marginRight: '8px' }}>
        <Typography>
          {s_USER}: {rJSX_HighlightedSearchString(searchInputValue, option.name)}{' '}
        </Typography>
        <Typography>
          {rLIB('Email')}: {rJSX_HighlightedSearchString(searchInputValue, option.email)}{' '}
        </Typography>
        <Typography>
          {s_PHONE}: {rJSX_HighlightedSearchString(searchInputValue, option.phone)}{' '}
        </Typography>
        <Button
          color={'info'}
          onClick={() => {
            if (option.id != null) {
              us_setSearchSelectedUserKey(option.id)
            }
          }}
          variant="contained"
          sx={{ marginBottom: '5px' }}
        >
          <Icon icon="magnifying-glass" />
          {s_VIEW_USERS_ACTIVE_TASKS}
        </Button>
        <Divider />
      </Box>
    )
    return searchResultJSX
  }

  const createTask = (uc_setUserInterface_FormDialogDisplay: any, taskBlueprints: TsInterface_UnspecifiedObject[]): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_NewTask,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {
            return new Promise<void>((resolve, reject) => {
              if (formData['task_category'] != null) {
                let taskCategory = formData['task_category']
                let taskTypesFiltered = objectToArray(globalTaskTypes).filter((taskType) => taskType.task_category === taskCategory)
                formInputs['task_type']['options'] = taskTypesFiltered
                formInputs['task_type']['disabled'] = false
              }
              if (formData['task_type'] != null) {
                let taskType = formData['task_type']
                let taskBlueprintsFiltered = taskBlueprints.filter((taskBlueprint) => {
                  return taskBlueprint.task_type === taskType
                })
                formInputs['associated_task_blueprint_key']['options'] = taskBlueprintsFiltered
                formInputs['associated_task_blueprint_key']['disabled'] = false
              }
              if (formData['associated_task_blueprint_key'] != null) {
                let taskBlueprintKey = formData['associated_task_blueprint_key']
                let taskBlueprint = taskBlueprints.filter((taskBlueprint) => taskBlueprint.key === taskBlueprintKey)[0]
                formInputs['special_instructions']['disabled'] = false
                formData['special_instructions'] = taskBlueprint.special_instructions
              }
              resolve()
            }).catch((error) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
            })
          },
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let params = {
                function: 'createTask',
                client_key: uc_RootData_ClientKey,
                owner_key: formSubmittedData['associated_owner_key_key'],
                scheduled_date_key: formSubmittedData['timestamp_last_scheduled_date_key'],
                task_blueprint_key: formSubmittedData['associated_task_blueprint_key'],
                special_instructions: formSubmittedData['special_instructions'],
              }

              cloudFunctionManageRequest('manageTasks', params)
                .then((res_CFMMLFR) => {
                  resolve(res_CFMMLFR)
                })
                .catch((rej_CFMMLFR) => {
                  reject(rej_CFMMLFR)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Create Task')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_MyActiveTasksTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_loadedMyOpenTaskData === true) {
      let activeTaskList = returnExpandedTaskTableData(
        uc_RootData_ClientKey,
        { ...us_myOpenTasks, ...us_myOpenCreatedTasks },
        {},
        'not_deleted',
        getProp(uc_RootData_GlobalUser, 'key', null),
        {},
        ['rJSX_MyActiveTasksTab'],
      )
      if (activeTaskList.length > 0) {
        tabJSX = (
          <Card className="tw-mt-0">
            <TableBasic
              tableAdditionalData={tableAdditionalData_ProjectTasks}
              tableColumns={tableColumns_MyProjectsOpenTasks}
              tableData={activeTaskList}
              tableSettings={tableSettings_ProjectTasks}
            />
          </Card>
        )
      } else {
        tabJSX = (
          <Card className="tw-p-4 tw-text-center">
            <Typography variant="body1">{s_NO_OPEN_TASKS}</Typography>
          </Card>
        )
      }
    }
    return tabJSX
  }

  const rJSX_MySnoozedTasksTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_loadedMyOpenTaskData === true) {
      let snoozedTaskList = returnExpandedTaskTableData(
        uc_RootData_ClientKey,
        us_mySnoozedTasks,
        {},
        'not_deleted',
        getProp(uc_RootData_GlobalUser, 'key', null),
        {},
        ['rJSX_MyActiveTasksTab'],
      )
      if (objectToArray(snoozedTaskList).length > 0) {
        tabJSX = (
          <Card className="tw-mt-0">
            <TableBasic
              tableAdditionalData={tableAdditionalData_ProjectTasks}
              tableColumns={tableColumns_MyProjectsOpenTasks}
              tableData={returnExpandedTaskTableData(
                uc_RootData_ClientKey,
                us_mySnoozedTasks,
                {},
                'not_deleted',
                getProp(uc_RootData_GlobalUser, 'key', null),
                {},
                ['rJSX_MyActiveTasksTab'],
              )}
              tableSettings={tableSettings_ProjectTasks}
            />
          </Card>
        )
      } else {
        tabJSX = (
          <Card className="tw-p-4 tw-text-center">
            <Typography variant="body1">{s_NO_SNOOZED_TASKS}</Typography>
          </Card>
        )
      }
    }
    return tabJSX
  }

  const rJSX_SearchActiveTasksTab = (): JSX.Element => {
    let tableJSX = <></>
    if (us_loadedAllOpenTaskData === true) {
      if (objectToArray(us_searchOpenTasks).length > 0) {
        tableJSX = (
          <Card className="tw-mt-0">
            <TableBasic
              tableAdditionalData={tableAdditionalData_ProjectTasks}
              tableColumns={tableColumns_AllProjectsOpenTasks}
              tableData={returnExpandedTaskTableData(
                uc_RootData_ClientKey,
                us_searchOpenTasks,
                {},
                'not_deleted',
                getProp(uc_RootData_GlobalUser, 'key', null),
                {},
                ['rJSX_SearchActiveTasksTab'],
              )}
              tableSettings={tableSettings_ProjectTasks}
            />
          </Card>
        )
      } else {
        tableJSX = (
          <Card className="tw-p-4 tw-text-center">
            <Typography variant="body1">{s_NO_OPEN_TASKS}</Typography>
          </Card>
        )
      }
    }
    let searchJSX = <></>
    if (uc_RootData_ClientKey != null) {
      searchJSX = (
        <Box className="tw-my-2">
          <SearchInput
            clientKey={uc_RootData_ClientKey}
            searchIndexKey={'users'}
            searchFilters={[]}
            searchResultRenderer={rJSX_UserSearchResultWithStatus}
            additionalSearchData={{}}
          />
        </Box>
      )
    }
    let tabJSX = (
      <Box>
        {searchJSX}
        {tableJSX}
      </Box>
    )
    return tabJSX
  }

  const rJSX_RecentlyCompletedTasksTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_loadedMyRecentTaskData === true) {
      if (objectToArray(us_recentTasks).length > 0 || objectToArray(us_recentCreatedTasks).length > 0) {
        tabJSX = (
          <Card className="tw-mt-0">
            <TableBasic
              tableAdditionalData={tableAdditionalData_ProjectTasks}
              tableColumns={tableColumns_ProjectRecentlyClosedTasks}
              tableData={returnExpandedTaskTableData(
                uc_RootData_ClientKey,
                { ...us_recentTasks, ...us_recentCreatedTasks },
                {},
                'not_deleted',
                getProp(uc_RootData_GlobalUser, 'key', null),
                {},
                ['rJSX_RecentlyCompletedTasksTab'],
              )}
              tableSettings={tableSettings_ProjectTasks}
            />
          </Card>
        )
      } else {
        tabJSX = (
          <Card className="tw-p-4 tw-text-center">
            <Typography variant="body1">{s_NO_RECENTLY_COMPLETED_TASKS}</Typography>
            <Typography
              variant="body1"
              className="tw-opacity-50"
            >
              {s_IN_THE_PAST_2_WEEKS}
            </Typography>
          </Card>
        )
      }
    }
    return tabJSX
  }

  // const returnJSXScheduledTasksTab = (): JSX.Element => {
  //   let tabJSX = <></>
  //   let datePickerJSX = (
  //     <Box>
  //       <DatePicker
  //         key={'calendar_date'}
  //         datePickerText={s_SCHEDULED_DATE}
  //         datePickerDate={us_selectedDate}
  //         datePickerDisabled={false}
  //         datePickerDateOnChange={changeCalendarDate}
  //         datePickerSettings={{ thin_input: true }}
  //       />
  //       <Box className="tw-inline-block tw-pl-2 tw-pt-1.5 tw-opacity-30">
  //         {s_TASKS_FROM} {returnFormattedDate(us_weekStartDate, 'D MMM YYYY')} {rLIB('to')} {returnFormattedDate(us_weekEndDate, 'D MMM YYYY')}
  //       </Box>
  //     </Box>
  //   )
  //   if (objectToArray(us_scheduledTasks).length > 0) {
  //     tabJSX = (
  //       <Box>
  //         {datePickerJSX}
  //         <Card className="tw-mt-2">
  //           <TableBasic
  //             tableAdditionalData={tableAdditionalData_ProjectTasks}
  //             tableColumns={tableColumns_MyProjectsOpenTasks}
  //             tableData={objectToArray(us_scheduledTasks)}
  //             tableSettings={tableSettings_ProjectTasks}
  //           />
  //         </Card>
  //       </Box>
  //     )
  //   } else {
  //     tabJSX = (
  //       <Box>
  //         {datePickerJSX}
  //         <Card className="tw-p-4 tw-text-center tw-mt-2">
  //           <Typography variant="body1">{s_NO_TASKS_FOR_SELECTED_DATE}</Typography>
  //         </Card>
  //       </Box>
  //     )
  //   }
  //   return tabJSX
  // }

  const rJSX_NewTaskButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="success"
        variant="contained"
        startIcon={<Icon icon="circle-plus" />}
        onClick={() => {
          createTask(uc_setUserInterface_FormDialogDisplay, us_taskBlueprints)
        }}
      >
        {rLIB('New Task')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let activeTaskList = returnExpandedTaskTableData(
      uc_RootData_ClientKey,
      { ...us_myOpenTasks, ...us_myOpenCreatedTasks },
      {},
      'not_deleted',
      getProp(uc_RootData_GlobalUser, 'key', null),
      {},
      ['rJSX_MyActiveTasksTab'],
    )
    let snoozedTaskList = returnExpandedTaskTableData(
      uc_RootData_ClientKey,
      us_mySnoozedTasks,
      {},
      'not_deleted',
      getProp(uc_RootData_GlobalUser, 'key', null),
      {},
      ['rJSX_MyActiveTasksTab'],
    )
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_OPEN_TASKS}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Active_Tasks',
                  tabButtons: [{ fullJSX: rJSX_NewTaskButton(), minJSX: rJSX_NewTaskButton(), sizeCutoff: 0 }],
                  tabHeader: (
                    <Badge
                      badgeContent={activeTaskList.length}
                      color="error"
                    >
                      {s_MY_ACTIVE_TASKS}
                    </Badge>
                  ),
                  tabContent: rJSX_MyActiveTasksTab(),
                },
                {
                  tabUrlKey: 'Snoozed_Tasks',
                  tabButtons: [{ fullJSX: rJSX_NewTaskButton(), minJSX: rJSX_NewTaskButton(), sizeCutoff: 0 }],
                  tabHeader: (
                    <Badge
                      badgeContent={snoozedTaskList.length}
                      color="info"
                    >
                      {s_MY_SNOOZED_TASKS}
                    </Badge>
                  ),
                  tabContent: rJSX_MySnoozedTasksTab(),
                },
                {
                  tabUrlKey: 'Search',
                  tabButtons: [{ fullJSX: rJSX_NewTaskButton(), minJSX: rJSX_NewTaskButton(), sizeCutoff: 0 }],
                  tabHeader: (
                    <Badge
                      badgeContent={objectToArray(us_searchOpenTasks).length}
                      color="error"
                    >
                      {s_SEARCH_ACTIVE_TASKS}
                    </Badge>
                  ),
                  tabContent: rJSX_SearchActiveTasksTab(),
                },
                {
                  tabUrlKey: 'Recently_Completed',
                  tabButtons: [{ fullJSX: rJSX_NewTaskButton(), minJSX: rJSX_NewTaskButton(), sizeCutoff: 0 }],
                  tabHeader: s_MY_RECENTLY_COMPLETED_TASKS,
                  tabContent: rJSX_RecentlyCompletedTasksTab(),
                },
              ]}
              tabsSettings={tabsSettings}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
