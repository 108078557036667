///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Tooltip, Typography } from '@mui/material/'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SalesPartner_Collection, DatabaseRef_SalesPartner_Document } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_PromptDialog,
  TsInterface_PromptDialogObject,
} from 'rfbp_core/services/context'
import {
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp, keyFromString, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

import { DatabaseRef_TaskRequiringPartnerApproval_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { tableColumns_ReadOnlyTasksPendingSalesPartnerApproval, tableSettings_TasksPendingSalesPartnerApproval } from '../payroll/tables/approval_pending'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseSalesPartnersListPage']['key']

// Table
const tableSettings_SalesPartner: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
}

const tableColumns_SalesPartner: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Sales Partner'), 'name'),

  api_organization: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Public API Project Query Field')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        // Edit Icon
        let editIconJSX = <></>
        if (rowData != null && rowData.key !== true) {
          editIconJSX = (
            <Tooltip
              title={rLIB('Edit Public API Project Query Field')}
              placement="right"
            >
              <Box className="tw-inline-block">
                <Icon
                  icon="pen-to-square"
                  className="tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100 tw-inline-block"
                  onClick={() => {
                    tableHooks.uc_setUserInterface_FormDialogDisplay({
                      display: true,
                      form: {
                        form: {
                          formAdditionalData: {},
                          formData: rowData,
                          formInputs: {
                            public_api_config_project_query_field: {
                              data_type: 'string',
                              input_type: 'text_basic',
                              key: 'public_api_config_project_query_field',
                              label: rLIB('Public API Project Query Field'),
                              required: true,
                            },
                          },
                          formOnChange: (
                            formAdditionalData: TsInterface_FormAdditionalData,
                            formData: TsInterface_FormData,
                            formInputs: TsInterface_FormInputs,
                            formSettings: TsInterface_FormSettings,
                          ) => {},
                          formSettings: {},
                          formSubmission: (
                            formSubmittedData: TsInterface_FormSubmittedData,
                            formAdditionalData: TsInterface_FormAdditionalData,
                            formHooks: TsInterface_FormHooksObject,
                          ) => {
                            return new Promise((resolve, reject) => {
                              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                                .then((res_GCK) => {
                                  let updateObject = {
                                    public_api_config_project_query_field: formSubmittedData.public_api_config_project_query_field,
                                  }
                                  DatabaseSetMergeDocument(DatabaseRef_SalesPartner_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                                    .then((res_DSMD) => {
                                      resolve(res_DSMD)
                                    })
                                    .catch((rej_DSMD) => {
                                      console.error(rej_DSMD)
                                      reject(rej_DSMD)
                                    })
                                })
                                .catch((rej_GCK) => {
                                  console.error(rej_GCK)
                                  reject(rej_GCK)
                                })
                            })
                          },
                        },
                        dialog: {
                          formDialogHeaderColor: 'success',
                          formDialogHeaderText: rLIB('Public API Project Query Field'),
                          formDialogIcon: (
                            <Icon
                              type="solid"
                              icon="pen-to-square"
                            />
                          ),
                        },
                      },
                    })
                  }}
                />
              </Box>
            </Tooltip>
          )
        }
        // Full JSX
        if (rowData != null && rowData['public_api_config_project_query_field'] != null) {
          cellJSX = (
            <Box>
              <Typography
                variant="body1"
                className="tw-mr-2 tw-inline-block"
              >
                {rowData['public_api_config_project_query_field']}
              </Typography>
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Typography
                variant="body1"
                className="tw-opacity-20 tw-italic tw-mr-2 tw-inline-block"
              >
                {rLIB('Missing')}
              </Typography>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  const [us_tasksRequiringPartnerApproval, us_setTasksRequiringPartnerApproval] = useState<TsInterface_UnspecifiedObject>({})

  // Hooks - useEffect

  useEffect(() => {
    document.title = rLIB('Sales Partners', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTasksRequiringPartnerApproval(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskRequiringPartnerApproval_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Other Variables
  const tableAdditionalData_SalesPartner: TsInterface_TableAdditionalData = {}

  // Functions
  const tableDatabaseEndpoint_SalesPartner = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_SalesPartner_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const promptObject: TsInterface_PromptDialogObject = {
    color: 'success',
    confirm_text: rLIB('Create') as JSX.Element,
    default_value: null,
    header: rLIB('Create New Sales Partner'),
    icon: (
      <Icon
        icon="badge-dollar"
        type="solid"
      />
    ),
    input_label: rLIB('Sales Partner') as JSX.Element,
    input_type: 'text',
    text: rLIB('Enter a name for your new sales partner'),
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let salesPartnerKey = keyFromString(promptValue)
        let updateObject = {
          name: promptValue,
          status: 'active',
          key: salesPartnerKey,
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseSetMergeDocument(DatabaseRef_SalesPartner_Document(res_GCK.clientKey, salesPartnerKey), updateObject)
              .then((res_DSMD) => {
                // Nothing
              })
              .catch((rej_DSMD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
        resolve({ success: true })
      })
    },
  }

  // JSX Generation
  const rJSX_NewSalesPartnerButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: promptObject,
          })
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Sales Partner')}
      </Button>
    )
  }

  const rJSX_ImportSalesHierarchyButton = (): JSX.Element => {
    return (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.SalesHierarchyImportPage.url())
        }}
        disableElevation
        startIcon={
          <Icon
            icon="sitemap"
            type="light"
          />
        }
        className="tw-ml-2"
      >
        {rLIB('Import Sales Hierarchy')}
      </Button>
    )
  }

  const rJSX_SalesPartnerTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={tableAdditionalData_SalesPartner}
            tableColumns={tableColumns_SalesPartner}
            tableDatabaseEndpoint={tableDatabaseEndpoint_SalesPartner}
            tableSettings={tableSettings_SalesPartner}
          />
        </Card>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_TasksAwaitingApprovalTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <Card>
          <TableBasic
            tableAdditionalData={{ editable: false }}
            tableColumns={tableColumns_ReadOnlyTasksPendingSalesPartnerApproval}
            tableData={objectToArray(us_tasksRequiringPartnerApproval)}
            tableSettings={tableSettings_TasksPendingSalesPartnerApproval}
          />
        </Card>
      </Box>
    )
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Sales Partners')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.AdminDatabaseSalesPartnersListPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Sales Tools', false) as string,
              }}
              tabs={[
                {
                  tabHeader: rLIB('Sales Partners'),
                  tabUrlKey: 'sales_partners',
                  tabButtons: [
                    { fullJSX: rJSX_NewSalesPartnerButton(), minJSX: rJSX_NewSalesPartnerButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_ImportSalesHierarchyButton(), minJSX: rJSX_ImportSalesHierarchyButton(), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_SalesPartnerTable(),
                },
                {
                  tabHeader: rLIB('Tasks Pending Partner Approval'),
                  tabUrlKey: 'tasks_pending_approval',
                  tabButtons: [
                    { fullJSX: rJSX_NewSalesPartnerButton(), minJSX: rJSX_NewSalesPartnerButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_ImportSalesHierarchyButton(), minJSX: rJSX_ImportSalesHierarchyButton(), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_TasksAwaitingApprovalTab(),
                },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
