///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_OnboardingTaskPageFile = (clientKey: string, taskKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/onboarding/' + taskKey + '/images/' + fileName)
}

export const StorageRef_OnboardingImageThumbnailsFolder = (clientKey: string, taskKey: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/onboarding/' + taskKey + '/images/thumbnails/')
}

export const StorageRef_OnboardingImageThumbnailFile = (clientKey: string, taskKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/onboarding/' + taskKey + '/images/thumbnails/' + fileName)
}

export const StorageRef_OnboardingDocumentsFolder = (clientKey: string, taskKey: string, folderKey: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/onboarding/' + taskKey + '/documents/' + folderKey + '/')
}

export const StorageRef_OnboardingDocumentsFile = (clientKey: string, taskKey: string, folderKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/onboarding/' + taskKey + '/documents/' + folderKey + '/' + fileName)
}
