///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Badge, Box, Button, Card, Divider, FormControl, MenuItem, Select, Stack, Switch, Tooltip, Typography } from '@mui/material/'
import { formSettings_Project } from 'app/models/projects/project_forms'
import {
  rJSX_ProjectSearchResultWithStatus,
  tableColumns_ActiveProjects,
  tableColumns_PowerwallOrders,
  tableColumns_UnlinkedProjects,
  tableSettings_AllProjects,
  tableSettings_MyProjects,
  tableSettings_PowerwallOrders,
  tableSettings_SearchProjects,
  tableSettings_UnassignedProjects,
  tableSettings_UnlinkedProjects,
} from 'app/models/projects/project_table'
import { returnTaskRows } from 'app/models/tasks'
import { findRecursiveTasks, returnTaskPrerequisiteAnalysisObject } from 'app/models/tasks/task_workflow_services'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskWorkflowProd_Document, DatabaseRef_TaskWorkflowsActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DatabaseRef_UsersWithTaskRole_Query, DatabaseRef_UsersWithUserRole_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import {
  DatabaseRef_ActiveProjectsForSpecificWorkflow_Query,
  DatabaseRef_ActiveProjectTimestampsForSpecificWorkflow_Query,
  DatabaseRef_ActiveProtoProjects_Query,
  DatabaseRef_Projects_Collection,
  DatabaseRef_ProtoProjects_Collection,
  DatabaseRef_ProtoProjects_Search,
  DatabaseRef_UnassignedProjects_Query,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_PowerwallOrders_Collection, DatabaseRef_PowerwallOrders_Query } from 'rfbp_aux/services/database_endpoints/webstore/orders'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { SearchInput } from 'rfbp_core/components/search'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl, TsInterface_TabContentUrlArray, TsInterface_TabsSettingsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import {
  cloneObjectWithoutReference,
  downloadCSV,
  dynamicSort,
  getProp,
  objectToArray,
  returnFormattedDate,
  returnTimestampFromUnknownDateFormat,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { rJSX_ProjectPhasesTabContent } from './components/milestone_dashboard'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminActiveProjectsListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// const s_NEW_PROJECT: JSX.Element = 									<Trans>New Project</Trans>
const s_ACTIVE: JSX.Element = <Trans>Active</Trans>
const s_ACTIVE_PROJECTS: JSX.Element = <Trans>Active Projects</Trans>
const s_CANCELLED: JSX.Element = <Trans>Cancelled</Trans>
const s_CITY: JSX.Element = <Trans>City</Trans>
const s_COMPLETED: JSX.Element = <Trans>Completed</Trans>
const s_CUSTOMER_EMAIL: JSX.Element = <Trans>Customer Email</Trans>
const s_CUSTOMER_NAME: JSX.Element = <Trans>Customer Name</Trans>
const s_CUSTOMER_PHONE: JSX.Element = <Trans>Customer Phone</Trans>
const s_DIRECT_PROJECT_SEARCH: JSX.Element = <Trans>Direct Project Search</Trans>
const s_DOWNLOAD_CONVERSION_STATS: JSX.Element = <Trans>Download Conversion Stats</Trans>
const s_DOWNLOAD_POWERWALL_CONVERSION_STATS: JSX.Element = <Trans>Download Powerwall Conversion Stats</Trans>
const s_END_DATE: JSX.Element = <Trans>End Date</Trans>
const s_FILTER_BY_CSS_REP: JSX.Element = <Trans>Filter by CSS Rep</Trans>
const s_FILTER_BY_CUSTOMER_SATISFACTION: JSX.Element = <Trans>Filter by Customer Satisfaction</Trans>
const s_FILTER_BY_PROJECT_STATUS: JSX.Element = <Trans>Filter by Project Status</Trans>
const s_FILTER_BY_SALES_REP: JSX.Element = <Trans>Filter by Sales Rep</Trans>
const s_FILTER_BY_WORKFLOW: JSX.Element = <Trans>Filter by Workflow</Trans>
const s_FILTER_PROJECT_SEARCH: JSX.Element = <Trans>Filter Project Search</Trans>
const s_HAPPY_CUSTOMER: JSX.Element = <Trans>Happy Customer</Trans>
const s_HIGHLY_ESCALATED_CUSTOMER: JSX.Element = <Trans>Highly Escalated Customer</Trans>
const s_JOB_CODE: JSX.Element = <Trans>Job Code</Trans>
const s_MY: JSX.Element = <Trans>My</Trans>
const s_NEUTRAL_CUSTOMER: JSX.Element = <Trans>Neutral Customer</Trans>
const s_NEW_UNLINKED_PROJECT: JSX.Element = <Trans>New Unlinked Project</Trans>
const s_NOTES: JSX.Element = <Trans>Notes</Trans>
const s_ON_HOLD: JSX.Element = <Trans>On Hold</Trans>
const s_PROJECTS: JSX.Element = <Trans>Projects</Trans>
const s_PROJECT_STATUS: JSX.Element = <Trans>Project Status</Trans>
const s_PROMOTER: JSX.Element = <Trans>Promoter</Trans>
const s_SEARCH: JSX.Element = <Trans>Search</Trans>
const s_SEARCH_AGAIN: JSX.Element = <Trans>Search Again</Trans>
const s_START_DATE: JSX.Element = <Trans>Start Date</Trans>
const s_STATE: JSX.Element = <Trans>State</Trans>
const s_STREET_ADDRESS: JSX.Element = <Trans>Street Address</Trans>
const s_TABLE_STATUS_FILTER: JSX.Element = <Trans>Table Status Filter</Trans>
const s_UNASSIGNED_PROJECTS: JSX.Element = <Trans>Unassigned Projects</Trans>
const s_UNHAPPY_CUSTOMER: JSX.Element = <Trans>Unhappy Customer</Trans>
const s_UNLINKED_PROJECTS: JSX.Element = <Trans>Unlinked Projects</Trans>
const s_VIEW_ACTIVE_ORDERS: JSX.Element = <Trans>View Active Orders</Trans>
const s_VIEW_ARCHIVED_ORDERS: JSX.Element = <Trans>View Archived Orders</Trans>
const s_WORKFLOW: JSX.Element = <Trans>Workflow</Trans>
const s_ZIP: JSX.Element = <Trans>Zip</Trans>
const se_ACTIVE_PROJECTS = 'Active Projects'
// { sort-end } - displayed text

// Form
const formInputs_ProtoProjectNew: TsInterface_FormInputs = {
  unique_identifier: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'unique_identifier',
    label: s_JOB_CODE,
    required: true,
  },
  associated_customer_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'associated_customer_name',
    label: s_CUSTOMER_NAME,
    required: true,
  },
  associated_customer_email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'associated_customer_email',
    label: s_CUSTOMER_EMAIL,
    required: true,
  },
  associated_customer_phone: {
    data_type: 'string',
    // input_type: "phone_number_usa",
    input_type: 'text_basic',
    key: 'associated_customer_phone',
    label: s_CUSTOMER_PHONE,
    required: true,
  },
  location_address: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_address',
    label: s_STREET_ADDRESS,
    required: true,
  },
  location_city: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_city',
    label: s_CITY,
    required: true,
  },
  location_state: {
    data_type: 'string',
    input_type: 'multiple_choice_select_state_usa',
    key: 'location_state',
    label: s_STATE,
    required: true,
  },
  location_zip: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_zip',
    label: s_ZIP,
    required: true,
  },
  project_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'project_notes',
    label: s_NOTES,
    required: true,
  },
}

let projectStatusOptions: TsInterface_UnspecifiedObject = {
  active: { key: 'active', value: s_ACTIVE, disabled: false },
  on_hold: { key: 'on_hold', value: s_ON_HOLD, disabled: false },
  cancelled: { key: 'cancelled', value: s_CANCELLED, disabled: false },
  completed: { key: 'completed', value: s_COMPLETED, disabled: false },
}

const formInputs_TableStatusFilter: TsInterface_FormInputs = {
  status: {
    key: 'status',
    label: s_PROJECT_STATUS,
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: objectToArray(projectStatusOptions),
  },
}

const customerStatusOptions: TsInterface_UnspecifiedObject = {
  5: {
    key: 5,
    value: s_PROMOTER,
    icon: (
      <Box
        className="tw-inline-block"
        sx={{ color: themeVariables.info_main }}
      >
        <Icon icon="face-party" />{' '}
      </Box>
    ),
  },
  4: {
    key: 4,
    value: s_HAPPY_CUSTOMER,
    icon: (
      <Box
        className="tw-inline-block"
        sx={{ color: themeVariables.success_main }}
      >
        <Icon icon="face-smile-beam" />{' '}
      </Box>
    ),
  },
  3: {
    key: 3,
    value: s_NEUTRAL_CUSTOMER,
    icon: (
      <Box
        className="tw-inline-block"
        sx={{ color: themeVariables.warning_main }}
      >
        <Icon icon="face-meh" />{' '}
      </Box>
    ),
  },
  2: {
    key: 2,
    value: s_UNHAPPY_CUSTOMER,
    icon: (
      <Box
        className="tw-inline-block"
        sx={{ color: themeVariables.orange_500 }}
      >
        <Icon icon="face-worried" />{' '}
      </Box>
    ),
  },
  1: {
    key: 1,
    value: s_HIGHLY_ESCALATED_CUSTOMER,
    icon: (
      <Box
        className="tw-inline-block"
        sx={{ color: themeVariables.error_main }}
      >
        <Icon icon="face-pouting" />{' '}
      </Box>
    ),
  },
  0: {
    key: 0,
    value: s_CANCELLED,
    icon: (
      <Box
        className="tw-inline-block"
        sx={{ color: themeVariables.gray_700 }}
      >
        <Icon icon="skull" />{' '}
      </Box>
    ),
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Page Specific Permissions
///////////////////////////////

const defaultPagePermissions: TsInterface_UnspecifiedObject = {
  new_project_button: false,
  new_proto_button: false,
  scheduling_button: false,
  import_button: false,
  stats_button: false,
  my_projects: true,
  all_projects: true,
  unassigned_projects: false,
  unlinked_projects: false,
  project_phases: false,
  project_search: true,
  powerwall_orders: false,
}

const pageRolePermissions: TsInterface_UnspecifiedObject = {
  api_user: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: false,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: false,
    powerwall_orders: false,
  },
  accounts_payable: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: true,
    project_search: true,
    powerwall_orders: false,
  },
  accounts_receivable: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: true,
    project_search: true,
    powerwall_orders: false,
  },
  admin: {
    new_project_button: false,
    new_proto_button: true,
    scheduling_button: true,
    import_button: true,
    stats_button: true,
    my_projects: true,
    all_projects: true,
    unassigned_projects: true,
    unlinked_projects: true,
    project_phases: true,
    project_search: true,
    powerwall_orders: true,
  },
  construction_lead: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: true,
    project_search: true,
    powerwall_orders: false,
  },
  css_manager: {
    new_project_button: false,
    new_proto_button: true,
    scheduling_button: true,
    import_button: true,
    stats_button: true,
    my_projects: true,
    all_projects: true,
    unassigned_projects: true,
    unlinked_projects: true,
    project_phases: true,
    project_search: true,
    powerwall_orders: true,
  },
  css_rep: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: true,
    my_projects: true,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: true,
    project_search: true,
    powerwall_orders: false,
  },
  design: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  electrical_foreman: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  field_service: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  general_manager: {
    new_project_button: false,
    new_proto_button: true,
    scheduling_button: true,
    import_button: false,
    stats_button: true,
    my_projects: false,
    all_projects: true,
    unassigned_projects: true,
    unlinked_projects: true,
    project_phases: true,
    project_search: true,
    powerwall_orders: true,
  },
  home_upgrades_electrician: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  home_upgrades_electrician_trainee: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  inspection: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  install_coordinator: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: true,
    project_search: true,
    powerwall_orders: false,
  },
  install_lead: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  installer: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  interconnection: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: true,
    project_search: true,
    powerwall_orders: false,
  },
  permitting: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: true,
    project_search: true,
    powerwall_orders: false,
  },
  repair: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  scheduling: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: false,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: false,
    powerwall_orders: false,
  },
  site_auditor: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  warehouse: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
}

const determinePageSpecificPermissions = (clientUser: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  let pageSpecificPermissions: TsInterface_UnspecifiedObject = { ...defaultPagePermissions }
  if (clientUser != null && clientUser.user_role != null) {
    for (let loopPermissionKey in defaultPagePermissions) {
      if (
        pageRolePermissions != null &&
        pageRolePermissions[clientUser.user_role] != null &&
        pageRolePermissions[clientUser.user_role][loopPermissionKey] === true
      ) {
        pageSpecificPermissions[loopPermissionKey] = true
      }
    }
  }
  if (clientUser != null && clientUser.task_roles != null) {
    for (let loopTaskRole in clientUser.task_roles) {
      let loopRoleValue = clientUser.task_roles[loopTaskRole]
      if (loopRoleValue === true) {
        for (let loopPermissionKey in defaultPagePermissions) {
          if (pageRolePermissions != null && pageRolePermissions[loopTaskRole] != null && pageRolePermissions[loopTaskRole][loopPermissionKey] === true) {
            pageSpecificPermissions[loopPermissionKey] = true
          }
        }
      }
    }
  }
  return pageSpecificPermissions
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_cssRepOptions, us_setCssRepOptions] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_filterByCSS, us_setFilterByCSS] = useState<boolean>(false)
  const [us_filterByCustomerHappiness, us_setFilterByCustomerHappiness] = useState<boolean>(false)
  const [us_filterBySales, us_setFilterBySales] = useState<boolean>(false)
  const [us_filterByWorkflow, us_setFilterByWorkflow] = useState<boolean>(false)
  const [us_pageSpecificUserRolePermission, us_setPageSpecificUserRolePermission] = useState<TsInterface_UnspecifiedObject>(defaultPagePermissions)
  const [us_phaseActiveProjects, us_setPhaseActiveProjects] = useState<TsInterface_UnspecifiedObject>({})
  const [us_phaseActiveProjectTimestampsData, us_setPhaseActiveProjectTimestampsData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_phaseWorkflowKey, us_setPhaseWorkflowKey] = useState<string | null>('')
  const [us_phaseWorkflowTasks, us_setPhaseWorkflowTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_powerwallTableViewType, us_setPowerwallTableViewType] = useState<string>('active')
  const [us_projectFilterCSS, us_setProjectFilterCSS] = useState<string | null>('')
  const [us_projectFilterCustomerHappiness, us_setProjectFilterCustomerHappiness] = useState<number>(3)
  const [us_projectFilterSales, us_setProjectFilterSales] = useState<string | null>('')
  const [us_projectFilterStatus, us_setProjectFilterStatus] = useState<string | null>('active')
  const [us_projectFilterWorkflow, us_setProjectFilterWorkflow] = useState<string | null>('')
  const [us_projectFilterWorkflowSelectedTask, us_setProjectFilterWorkflowSelectedTask] = useState<string | null>(null)
  const [us_projectsSortedByMilestone, us_setProjectsSortedByMilestone] = useState<TsInterface_UnspecifiedObject>({})
  const [us_salesRepOptions, us_setSalesRepOptions] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_showSearchResults, us_setShowSearchResults] = useState<boolean>(false)
  const [us_showingProjectNotes, us_setShowingProjectNotes] = useState<boolean>(true)
  const [us_sortedMilestoneFilterTasksArray, us_setSortedMilestoneFilterTasksArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_sortedMilestonePhaseTasksArray, us_setSortedMilestonePhaseTasksArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_tableFilterStatus, us_setTableFilterStatus] = useState<string>('active')
  const [us_taskWorkflowsArray, us_setTaskWorkflowsArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_taskWorkflowsObject, us_setTaskWorkflowsObject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskWorkflowsProdTasks, us_setTaskWorkflowsProdTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unassignedProjects, us_setUnassignedProjects] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unlinkedProjects, us_setUnlinkedProjects] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  // useEffect(() => {
  // document.title = se_ACTIVE_PROJECTS
  // }, [])

  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      us_setPageSpecificUserRolePermission(determinePageSpecificPermissions(uc_RootData_ClientUser))
    }
  }, [uc_RootData_ClientUser])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUnassignedProjects(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_UnassignedProjects_Query(res_GCK.clientKey, 10), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUnlinkedProjects(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveProtoProjects_Query(res_GCK.clientKey, 10), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_UsersWithTaskRole_Query(res_GCK.clientKey, 'css_rep'))
          .then((res_DGC) => {
            let users: TsInterface_UnspecifiedObject[] = []
            for (let userKey in res_DGC.data) {
              let loopUser = res_DGC.data[userKey]
              users.push({ key: loopUser.key, value: loopUser.name })
            }
            us_setCssRepOptions(users)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_UsersWithUserRole_Query(res_GCK.clientKey, 'sales_rep'))
          .then((res_DGC) => {
            let users: TsInterface_UnspecifiedObject[] = []
            for (let userKey in res_DGC.data) {
              let loopUser = res_DGC.data[userKey]
              users.push({ key: loopUser.key, value: loopUser.name })
            }
            us_setSalesRepOptions(users)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_UsersWithUserRole_Query(res_GCK.clientKey, 'sales_rep'))
          .then((res_DGC) => {
            let users: TsInterface_UnspecifiedObject[] = []
            for (let userKey in res_DGC.data) {
              let loopUser = res_DGC.data[userKey]
              users.push({ key: loopUser.key, value: loopUser.name })
            }
            us_setSalesRepOptions(users)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    if (
      us_filterByWorkflow === true &&
      us_projectFilterWorkflow != null &&
      us_projectFilterWorkflow !== '' &&
      us_taskWorkflowsObject != null &&
      us_taskWorkflowsObject[us_projectFilterWorkflow] != null &&
      us_taskWorkflowsObject[us_projectFilterWorkflow]['filter_milestones'] != null &&
      us_taskWorkflowsProdTasks != null &&
      us_taskWorkflowsProdTasks['tasks'] != null
    ) {
      // Order Tasks
      let sortedMilestoneTasks: TsInterface_UnspecifiedObject[] = []
      let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(us_taskWorkflowsProdTasks['tasks']))
      let sortedTaskRows = returnTaskRows(us_taskWorkflowsProdTasks['tasks'], {}, tasksThatReferenceThemselves, ['Active Projects List useEffect'])
      // Loop through grouped rows
      let loopIndex = 0
      for (let loopRowKey in sortedTaskRows) {
        let loopRow = sortedTaskRows[loopRowKey]
        if (loopRow != null && loopRow['tasks'] != null) {
          for (let loopTaskIndex in loopRow['tasks'].sort(dynamicSort('timestamp_created', null))) {
            let loopTask = loopRow['tasks'][loopTaskIndex]
            if (loopTask != null && loopTask.key != null && us_taskWorkflowsObject[us_projectFilterWorkflow]['filter_milestones'][loopTask.key] === true) {
              sortedMilestoneTasks.push({
                name: getProp(loopTask, 'name', null),
                key: getProp(loopTask, 'key', null),
                index: loopIndex,
              })
              loopIndex++
            }
          }
        }
      }
      us_setSortedMilestoneFilterTasksArray(sortedMilestoneTasks)
    }
  }, [us_filterByWorkflow, us_projectFilterWorkflow, us_taskWorkflowsObject, us_taskWorkflowsProdTasks])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_TaskWorkflowsActive_Query(res_GCK.clientKey, 'name', false, null, {}))
          .then((res_DGC) => {
            us_setTaskWorkflowsObject(res_DGC.data)
            let workflows: TsInterface_UnspecifiedObject[] = []
            for (let workflowKey in res_DGC.data) {
              let loopWorkflow = res_DGC.data[workflowKey]
              workflows.push({ key: loopWorkflow.key, value: loopWorkflow.name })
            }
            us_setTaskWorkflowsArray(workflows)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    if (us_projectFilterWorkflow != null && us_projectFilterWorkflow !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(res_GCK.clientKey, us_projectFilterWorkflow))
            .then((res_DGD) => {
              us_setTaskWorkflowsProdTasks(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_projectFilterWorkflow])

  useEffect(() => {
    if (us_phaseWorkflowKey != null && us_phaseWorkflowKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(DatabaseRef_ActiveProjectsForSpecificWorkflow_Query(res_GCK.clientKey, us_phaseWorkflowKey))
            .then((res_DGD) => {
              us_setPhaseActiveProjects(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_phaseWorkflowKey])

  useEffect(() => {
    if (us_phaseWorkflowKey != null && us_phaseWorkflowKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(DatabaseRef_ActiveProjectTimestampsForSpecificWorkflow_Query(res_GCK.clientKey, us_phaseWorkflowKey))
            .then((res_DGD) => {
              us_setPhaseActiveProjectTimestampsData(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_phaseWorkflowKey])

  useEffect(() => {
    if (us_phaseWorkflowKey != null && us_phaseWorkflowKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(res_GCK.clientKey, us_phaseWorkflowKey))
            .then((res_DGD) => {
              us_setPhaseWorkflowTasks(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_phaseWorkflowKey])

  useEffect(() => {
    if (
      us_phaseWorkflowKey != null &&
      us_phaseWorkflowKey !== '' &&
      us_taskWorkflowsObject != null &&
      us_taskWorkflowsObject[us_phaseWorkflowKey] != null &&
      us_taskWorkflowsObject[us_phaseWorkflowKey]['filter_milestones'] != null &&
      us_phaseWorkflowTasks != null &&
      us_phaseWorkflowTasks['tasks'] != null
    ) {
      // Order Tasks
      let sortedMilestoneTasks: TsInterface_UnspecifiedObject[] = []
      let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(us_phaseWorkflowTasks['tasks']))
      let sortedTaskRows = returnTaskRows(us_phaseWorkflowTasks['tasks'], {}, tasksThatReferenceThemselves, ['Active Projects List useEffect'])
      // Loop through grouped rows
      let loopIndex = 0
      for (let loopRowKey in sortedTaskRows) {
        let loopRow = sortedTaskRows[loopRowKey]
        if (loopRow != null && loopRow['tasks'] != null) {
          for (let loopTaskIndex in loopRow['tasks'].sort(dynamicSort('timestamp_created', null))) {
            let loopTask = loopRow['tasks'][loopTaskIndex]
            if (loopTask != null && loopTask.key != null && us_taskWorkflowsObject[us_phaseWorkflowKey]['filter_milestones'][loopTask.key] === true) {
              sortedMilestoneTasks.push({
                name: getProp(loopTask, 'name', null),
                key: getProp(loopTask, 'key', null),
                index: loopIndex,
              })
              loopIndex++
            }
          }
        }
      }
      us_setSortedMilestonePhaseTasksArray(sortedMilestoneTasks)
    }
  }, [us_filterByWorkflow, us_phaseWorkflowKey, us_taskWorkflowsObject, us_phaseWorkflowTasks])

  useEffect(() => {
    if (
      us_phaseWorkflowKey != null &&
      us_phaseWorkflowKey !== '' &&
      us_phaseActiveProjects != null &&
      us_sortedMilestonePhaseTasksArray != null &&
      us_sortedMilestonePhaseTasksArray.length > 0
    ) {
      let projectsSortedByMilestone: TsInterface_UnspecifiedObject = {
        NO_MILESTONE_REACHED: {},
      }
      // Loop through milestones and create starting project milestone object
      for (let loopMilestoneIndex in us_sortedMilestonePhaseTasksArray) {
        let loopMilestone = us_sortedMilestonePhaseTasksArray[loopMilestoneIndex]
        projectsSortedByMilestone[loopMilestone.key] = {}
      }
      let firstMilestoneTaskKey = us_sortedMilestonePhaseTasksArray[0].key
      // Loop through projects and determine where they fall in the milestone array
      for (let loopProjectKey in us_phaseActiveProjects) {
        let loopProject = us_phaseActiveProjects[loopProjectKey]
        let projectLeastFarMilestoneIndex = us_sortedMilestonePhaseTasksArray.length
        let projectLeastFarMilestoneKey = null
        if (loopProject != null && loopProject.task_statuses != null) {
          let foundMilestoneLocation = false
          for (let loopMilestoneTaskIndex in us_sortedMilestonePhaseTasksArray) {
            let loopMilestoneTask = getProp(us_sortedMilestonePhaseTasksArray, loopMilestoneTaskIndex, {})
            let loopMilestoneTaskKey = getProp(loopMilestoneTask, 'key', '')
            if (loopProject != null && loopProject['task_statuses'] != null && loopProject['task_statuses'][loopMilestoneTaskKey] !== true) {
              if (parseInt(loopMilestoneTaskIndex) <= projectLeastFarMilestoneIndex) {
                projectLeastFarMilestoneIndex = parseInt(loopMilestoneTaskIndex)
                projectLeastFarMilestoneKey = loopMilestoneTaskKey
                foundMilestoneLocation = true
              }
            }
          }
          if (foundMilestoneLocation === true && projectLeastFarMilestoneKey != null) {
            projectsSortedByMilestone[projectLeastFarMilestoneKey][loopProjectKey] = us_phaseActiveProjects[loopProjectKey]
          } else {
            projectsSortedByMilestone[firstMilestoneTaskKey][loopProjectKey] = us_phaseActiveProjects[loopProjectKey]
          }
        } else {
          projectsSortedByMilestone[firstMilestoneTaskKey][loopProjectKey] = us_phaseActiveProjects[loopProjectKey]
        }
      }
      // Determine Days spent in phase
      let currentDate = new Date()
      for (let loopMilestoneKey in projectsSortedByMilestone) {
        let loopMilestone = projectsSortedByMilestone[loopMilestoneKey]
        for (let loopProjectKey in loopMilestone) {
          let loopProjectTimestamps = getProp(us_phaseActiveProjectTimestampsData, loopProjectKey, {})
          let loopProjectDaysInPhase: number | null = null
          let lastMilestoneTimestamp: number | null = null
          // Get the index of the milestone
          let milestoneIndex = us_sortedMilestonePhaseTasksArray.findIndex((milestone) => milestone.key === loopMilestoneKey)
          if (loopProjectTimestamps != null && objectToArray(loopProjectTimestamps).length > 0) {
            if (milestoneIndex > 1) {
              // Get the index of the previous milestone
              let previousMilestoneIndex = milestoneIndex - 1
              let previousMilestoneKey = us_sortedMilestonePhaseTasksArray[previousMilestoneIndex].key
              // Use the last updated date of the previous milestone
              if (
                loopProjectTimestamps != null &&
                loopProjectTimestamps['task_completion_timestamps'] != null &&
                loopProjectTimestamps['key'] != null &&
                loopProjectTimestamps['task_completion_timestamps'][loopProjectTimestamps['key'] + '_' + previousMilestoneKey] != null
              ) {
                let previousMilestoneDate = returnTimestampFromUnknownDateFormat(
                  loopProjectTimestamps['task_completion_timestamps'][loopProjectTimestamps['key'] + '_' + previousMilestoneKey],
                )
                if (previousMilestoneDate != null) {
                  loopProjectDaysInPhase = Math.floor((currentDate.getTime() - previousMilestoneDate) / 86400000)
                  lastMilestoneTimestamp = previousMilestoneDate
                }
              }
            }
            if (milestoneIndex === -1 || milestoneIndex === 0 || loopProjectDaysInPhase == null) {
              // Use Oldest Task Date
              let oldestTaskDate = null
              if (loopProjectTimestamps != null && loopProjectTimestamps['task_completion_timestamps'] != null) {
                for (let loopTaskKey in loopProjectTimestamps['task_completion_timestamps']) {
                  if (
                    oldestTaskDate == null ||
                    (loopProjectTimestamps['task_completion_timestamps'][loopTaskKey] != null &&
                      returnTimestampFromUnknownDateFormat(loopProjectTimestamps['task_completion_timestamps'][loopTaskKey]) < oldestTaskDate)
                  ) {
                    oldestTaskDate = returnTimestampFromUnknownDateFormat(loopProjectTimestamps['task_completion_timestamps'][loopTaskKey])
                  }
                }
              }
              // Number of days between oldest task date and now
              if (oldestTaskDate != null) {
                loopProjectDaysInPhase = Math.floor((currentDate.getTime() - oldestTaskDate) / 86400000)
                lastMilestoneTimestamp = oldestTaskDate
              }
            }
          }
          if (
            projectsSortedByMilestone != null &&
            projectsSortedByMilestone[loopMilestoneKey] != null &&
            projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]
          ) {
            projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]['TEMP_days_in_phase'] = loopProjectDaysInPhase
            projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]['TEMP_last_milestone_timestamp'] = lastMilestoneTimestamp
          }
        }
      }
      us_setProjectsSortedByMilestone(projectsSortedByMilestone)
      // OLDER VERSION
      // // Loop through projects and determine where they fall in the milestone array
      // for (let loopProjectKey in us_phaseActiveProjects) {
      //   let loopProject = us_phaseActiveProjects[loopProjectKey]
      //   let projectFurthestMilestoneIndex = 0
      //   let projectFurthestMilestoneKey = null
      //   if (loopProject != null && loopProject.task_statuses != null) {
      //     let foundMilestoneLocation = false
      //     for (let loopMilestoneTaskIndex in us_sortedMilestonePhaseTasksArray) {
      //       let loopMilestoneTask = getProp(us_sortedMilestonePhaseTasksArray, loopMilestoneTaskIndex, {})
      //       let loopMilestoneTaskKey = getProp(loopMilestoneTask, 'key', '')
      //       if (loopProject != null && loopProject['task_statuses'] != null && loopProject['task_statuses'][loopMilestoneTaskKey] === true) {
      //         if (parseInt(loopMilestoneTaskIndex) >= projectFurthestMilestoneIndex) {
      //           projectFurthestMilestoneIndex = parseInt(loopMilestoneTaskIndex)
      //           projectFurthestMilestoneKey = loopMilestoneTaskKey
      //           foundMilestoneLocation = true
      //         }
      //       }
      //     }
      //     if (foundMilestoneLocation === true && projectFurthestMilestoneKey != null) {
      //       projectsSortedByMilestone[projectFurthestMilestoneKey][loopProjectKey] = us_phaseActiveProjects[loopProjectKey]
      //     } else {
      //       projectsSortedByMilestone['NO_MILESTONE_REACHED'][loopProjectKey] = us_phaseActiveProjects[loopProjectKey]
      //     }
      //   } else {
      //     projectsSortedByMilestone['NO_MILESTONE_REACHED'][loopProjectKey] = us_phaseActiveProjects[loopProjectKey]
      //   }
      // }
      // // Bump each task to next milestone
      // let adjustedProjectsSortedByMilestone: TsInterface_UnspecifiedObject = {}
      // for (let loopPhaseIndex in us_sortedMilestonePhaseTasksArray) {
      //   let loopPhase = us_sortedMilestonePhaseTasksArray[loopPhaseIndex]
      //   let loopPhaseKey = getProp(loopPhase, 'key', null)
      //   if (parseInt(loopPhaseIndex) === 0) {
      //     if (loopPhaseKey != null) {
      //       adjustedProjectsSortedByMilestone[loopPhaseKey] = getProp(projectsSortedByMilestone, 'NO_MILESTONE_REACHED', {})
      //     }
      //   } else if (parseInt(loopPhaseIndex) === objectToArray(us_sortedMilestonePhaseTasksArray).length - 1) {
      //     if (loopPhaseKey != null) {
      //       adjustedProjectsSortedByMilestone[loopPhaseKey] = getProp(projectsSortedByMilestone, loopPhaseKey, {})
      //     }
      //   } else {
      //     let previousPhase = us_sortedMilestonePhaseTasksArray[parseInt(loopPhaseIndex) - 1]
      //     let previousPhaseKey = getProp(previousPhase, 'key', null)
      //     if (loopPhaseKey != null && previousPhaseKey != null) {
      //       adjustedProjectsSortedByMilestone[loopPhaseKey] = getProp(projectsSortedByMilestone, previousPhaseKey, {})
      //     }
      //   }
      // }
      // us_setProjectsSortedByMilestone(adjustedProjectsSortedByMilestone)
    }
  }, [us_phaseWorkflowKey, us_phaseActiveProjects, us_sortedMilestonePhaseTasksArray, us_phaseActiveProjectTimestampsData])

  // Other Variables
  const tabsSettings: TsInterface_TabsSettingsUrl = {
    tabQueryParam: 'tab',
    baseUrl: ApplicationPages['AdminActiveProjectsListPage'].url(),
    overridePageTitle: true,
    basePageTitle: se_ACTIVE_PROJECTS,
  }

  // Functions
  const tableDatabaseEndpoint_MyProjects = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let userRoleQueryProp = 'associated_css_rep_key'
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: us_tableFilterStatus },
      { prop: userRoleQueryProp, comparator: '==', value: uc_RootData_GlobalUser.key as string },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'associated_customer_name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(DatabaseRef_Projects_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_AllProjects = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    // let queryOperatorsArray: TsInterface_QueryOperatorsArray = [ { prop: "status", comparator: "==", value: "active" } ]
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: us_tableFilterStatus }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'associated_customer_name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(DatabaseRef_Projects_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_UnassignedProjects = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'unassigned' }]
    // let orderByArray: TsInterface_OrderByArray = [ { prop: "timestamp_customer_signed", desc: false } ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'associated_customer_name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(DatabaseRef_Projects_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_UnlinkedProjects = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = []
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_ProtoProjects_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_PowerwallOrders = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    if (us_powerwallTableViewType === 'archived') {
      queryOperatorsArray = [{ prop: 'status', comparator: 'in', value: ['archived'] }]
    } else {
      queryOperatorsArray = [{ prop: 'status', comparator: 'in', value: ['new', 'pending_deposit_payment', 'deposit_placed', 'order_placed'] }]
    }
    // let orderByArray: TsInterface_OrderByArray = [ { prop: "timestamp_agreed_to_terms", desc: true } ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 15)
    // limit = 2
    return generateDatabaseQuery(
      DatabaseRef_PowerwallOrders_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_SearchProjects = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    // queryOperatorsArray: TsInterface_QueryOperatorsArray = [ { prop: "status", comparator: "==", value: "unassigned" } ]
    if (us_projectFilterStatus != null && us_projectFilterStatus !== '') {
      queryOperatorsArray.push({ prop: 'status', comparator: '==', value: us_projectFilterStatus })
    }
    if (us_filterByCSS === true && us_projectFilterCSS != null && us_projectFilterCSS !== '') {
      queryOperatorsArray.push({ prop: 'associated_css_rep_key', comparator: '==', value: us_projectFilterCSS })
    }
    if (us_filterBySales === true && us_projectFilterSales != null && us_projectFilterSales !== '') {
      queryOperatorsArray.push({ prop: 'associated_sales_rep_name', comparator: '==', value: us_projectFilterSales })
    }
    if (us_filterByCustomerHappiness === true && us_projectFilterCustomerHappiness != null) {
      queryOperatorsArray.push({ prop: 'associated_customer_risk_status', comparator: '==', value: us_projectFilterCustomerHappiness })
    }
    if (us_filterByWorkflow === true && us_projectFilterWorkflow != null && us_projectFilterWorkflow !== '') {
      queryOperatorsArray.push({ prop: 'associated_task_workflow_key', comparator: '==', value: us_projectFilterWorkflow })
    }
    if (
      us_filterByWorkflow === true &&
      us_projectFilterWorkflow != null &&
      us_projectFilterWorkflow !== '' &&
      us_sortedMilestoneFilterTasksArray != null &&
      us_sortedMilestoneFilterTasksArray.length > 0 &&
      us_projectFilterWorkflowSelectedTask != null
    ) {
      // Loop through milestone tasks
      let selectedTask: TsInterface_UnspecifiedObject | null = null
      let followupTask: TsInterface_UnspecifiedObject | null = null
      let foundSelectedTask = false
      let foundFollowupTask = false
      let setFollowupTask = false
      for (let loopTaskIndex in us_sortedMilestoneFilterTasksArray) {
        let loopTask = us_sortedMilestoneFilterTasksArray[loopTaskIndex]
        if (setFollowupTask === true) {
          followupTask = loopTask
          foundFollowupTask = true
          setFollowupTask = false
        }
        if (loopTask != null && loopTask.key != null && loopTask.key === us_projectFilterWorkflowSelectedTask) {
          selectedTask = loopTask
          foundSelectedTask = true
          setFollowupTask = true
        }
      }
      if (foundSelectedTask === true && selectedTask != null && selectedTask.key != null) {
        queryOperatorsArray.push({ prop: 'task_statuses.' + selectedTask.key, comparator: '==', value: true })
      }
      if (foundFollowupTask === true && followupTask != null && followupTask.key != null) {
        queryOperatorsArray.push({ prop: 'task_statuses.' + followupTask.key, comparator: '==', value: false })
      }
    }

    // TODO - task statuses from workflow options
    // task_statuses.$taskKey

    let orderByArray: TsInterface_OrderByArray = []
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Projects_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableSearchEndpoint_UnlinkedProjects = (searchProperty: string, searchInput: string) => {
    let limit = tableSettings_UnlinkedProjects.rows_per_page
    if (limit === null) {
      limit = 10
    }
    return DatabaseRef_ProtoProjects_Search(uc_RootData_ClientKey as string, searchProperty, searchInput, limit)
  }

  const openNewProtoProjectDialog = () => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_ProtoProjectNew,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: formSettings_Project,
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let projectUpdateObject = cloneObjectWithoutReference(formSubmittedData)
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  cloudFunctionManageRequest('manageTasks', {
                    function: 'createProtoProjectAndUnassignedSiteAudit',
                    // function: "createProtoProjectAndAssignedSiteAudit",
                    // selected_date: "2023-01-19",
                    // selected_time: "10am to 2pm",
                    client_key: res_GCK.clientKey,
                    unique_identifier: getProp(projectUpdateObject, 'unique_identifier', null),
                    associated_customer_name: getProp(projectUpdateObject, 'associated_customer_name', null),
                    associated_customer_email: getProp(projectUpdateObject, 'associated_customer_email', null),
                    associated_customer_phone: getProp(projectUpdateObject, 'associated_customer_phone', null),
                    location_address: getProp(projectUpdateObject, 'location_address', null),
                    location_city: getProp(projectUpdateObject, 'location_city', null),
                    location_state: getProp(projectUpdateObject, 'location_state', null),
                    location_zip: getProp(projectUpdateObject, 'location_zip', null),
                    project_notes: getProp(projectUpdateObject, 'project_notes', null),
                  })
                    .then((res_CFMTR) => {
                      resolve(res_CFMTR)
                    })
                    .catch((rej_CFMTR) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMTR.error })
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: s_NEW_UNLINKED_PROJECT,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const downloadPowerwallConversionStats = () => {
    let today = new Date()
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
    let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {
            start_date: returnFormattedDate(firstDay, 'YYYY-MM-DDThh:mm'),
            end_date: returnFormattedDate(lastDay, 'YYYY-MM-DDThh:mm'),
          },
          formInputs: {
            start_date: {
              key: 'start_date',
              label: s_START_DATE,
              input_type: 'timestamp_datetime',
              required: true,
              data_type: 'string',
            },
            end_date: {
              key: 'end_date',
              label: s_END_DATE,
              input_type: 'timestamp_datetime',
              required: true,
              data_type: 'string',
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: formSettings_Project,
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              downloadPowerwallConversionStatsProper(new Date(formSubmittedData.start_date), new Date(formSubmittedData.end_date))
                .then((res_DPCSP) => {
                  resolve(res_DPCSP)
                })
                .catch((rej_DPCSP) => {
                  reject(rej_DPCSP)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: s_DOWNLOAD_POWERWALL_CONVERSION_STATS,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const downloadPowerwallConversionStatsProper = (startDate: Date, endDate: Date) => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(DatabaseRef_PowerwallOrders_Query(res_GCK.clientKey, startDate, endDate))
            .then((res_DGC) => {
              let exportData: string[][] = [
                [
                  'ID',
                  'First Name',
                  'Last Name',
                  'Email',
                  'Phone',
                  'Number of Powerwalls',
                  'Status',
                  'Page 1 (Contact info) status',
                  'Page 2 (Home info) status',
                  'Page 3 (Backup loads)',
                  'Page 4 (Order)',
                  'Start Timstamp',
                  'Agreed Timstamp',
                  'Deposit Timstamp',
                ],
              ]
              for (let loopUserOrderKey in res_DGC.data) {
                let loopUserOrder = res_DGC.data[loopUserOrderKey]
                if (loopUserOrder != null) {
                  let row: string[] = []
                  if (loopUserOrder.key != null) {
                    row.push(loopUserOrder.key)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.first_name != null) {
                    row.push(loopUserOrder.first_name)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.last_name != null) {
                    row.push(loopUserOrder.last_name)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.email != null) {
                    row.push(loopUserOrder.email)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.phone != null) {
                    row.push(loopUserOrder.phone)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.number_of_powerwalls_to_install != null) {
                    row.push(loopUserOrder.number_of_powerwalls_to_install)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.status != null) {
                    row.push(loopUserOrder.status)
                  } else {
                    row.push('')
                  }
                  // Page Progress
                  if (loopUserOrder.step > 0) {
                    row.push('100%')
                  } else {
                    let totalContactItems = 7
                    let contactInfoItemsComplete = 0
                    if (loopUserOrder['email'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['first_name'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['last_name'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['phone'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['best_time_to_contact'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['best_time_to_contact_other'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['best_contact_method'] != null) {
                      contactInfoItemsComplete++
                    }
                    let percentComplete = Math.round((contactInfoItemsComplete / totalContactItems) * 100).toFixed(0)
                    row.push(percentComplete + '%')
                  }
                  if (loopUserOrder.step > 1) {
                    row.push('100%')
                  } else {
                    let totalHomeItems = 9
                    let homeInfoItemsComplete = 0
                    if (loopUserOrder['install_location'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['utility_company'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['home_square_footage'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['home_year_built'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['is_home_owner'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['home_type'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['include_batteries_in_order'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['has_solar'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['expanding_powerwall'] != null) {
                      homeInfoItemsComplete++
                    }
                    let percentComplete = Math.round((homeInfoItemsComplete / totalHomeItems) * 100).toFixed(0)
                    row.push(percentComplete + '%')
                  }
                  if (loopUserOrder.step > 2) {
                    row.push('100%')
                  } else {
                    let totalBackupItems = 2
                    let backupLoadsItemsComplete = 0
                    if (loopUserOrder['number_of_powerwalls_to_install'] != null) {
                      backupLoadsItemsComplete++
                    }
                    if (loopUserOrder['include_batteries_in_order'] != null) {
                      backupLoadsItemsComplete++
                    }
                    let percentComplete = Math.round((backupLoadsItemsComplete / totalBackupItems) * 100).toFixed(0)
                    row.push(percentComplete + '%')
                  }
                  if (loopUserOrder.timestamp_deposit_checkout_session_created != null) {
                    row.push('100%')
                  } else if (loopUserOrder.timestamp_agreed_to_terms != null) {
                    row.push('100%')
                  } else {
                    row.push('0%')
                  }
                  if (loopUserOrder.timestamp_created != null) {
                    row.push(returnFormattedDate(loopUserOrder.timestamp_created, 'D MMM YYYY h:mma'))
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.timestamp_agreed_to_terms != null) {
                    row.push(returnFormattedDate(loopUserOrder.timestamp_agreed_to_terms, 'D MMM YYYY h:mma'))
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.timestamp_deposit_placed != null) {
                    row.push(returnFormattedDate(loopUserOrder.timestamp_deposit_placed, 'D MMM YYYY h:mma'))
                  } else {
                    row.push('')
                  }
                  exportData.push(row)
                }
              }
              // Download
              let fileName =
                'Powerwall Conversion Stats (' + returnFormattedDate(startDate, 'D MMM YYYY') + ' to ' + returnFormattedDate(endDate, 'D MMM YYYY') + ').csv'
              downloadCSV(fileName, exportData)
                .then((res_DCSV) => {
                  resolve(res_DCSV)
                })
                .catch((rej_DCSV) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DCSV.error })
                  reject(rej_DCSV)
                })
            })
            .catch((rej_DGC) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
              reject(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          reject(rej_GCK)
        })
    })
  }

  // JSX Generation
  const rJSX_MyProjectsTable = (): JSX.Element => {
    let tableJSX = <></>
    if (tableSettings_MyProjects.search_settings_database == null) {
      tableSettings_MyProjects.search_settings_database = { search_type: 'meilisearch' }
    }
    tableSettings_MyProjects.search_settings_database.search_client_key = uc_RootData_ClientKey
    let userKey = getProp(uc_RootData_GlobalUser, 'key', null)
    let searchFilters = ["status = 'active'", "associated_css_rep_key = '" + userKey + "'"]
    tableSettings_MyProjects.search_settings_database.search_filters = searchFilters
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{ visible_active_tasks: {} }}
              tableColumns={tableColumns_ActiveProjects}
              tableDatabaseEndpoint={tableDatabaseEndpoint_MyProjects}
              tableSettings={tableSettings_MyProjects}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_AllProjectsTable = (): JSX.Element => {
    let tableJSX = <></>
    if (tableSettings_AllProjects.search_settings_database == null) {
      tableSettings_AllProjects.search_settings_database = { search_type: 'meilisearch' }
    }
    tableSettings_AllProjects.search_settings_database.search_client_key = uc_RootData_ClientKey
    let searchFilters = ["status = 'active'"]
    tableSettings_AllProjects.search_settings_database.search_filters = searchFilters
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={{ visible_active_tasks: {} }}
            tableColumns={tableColumns_ActiveProjects}
            tableDatabaseEndpoint={tableDatabaseEndpoint_AllProjects}
            tableSettings={tableSettings_AllProjects}
          />
        </Card>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_UnassignedTable = (): JSX.Element => {
    let tableJSX = <></>
    if (tableSettings_UnassignedProjects.search_settings_database == null) {
      tableSettings_UnassignedProjects.search_settings_database = { search_type: 'meilisearch' }
    }
    tableSettings_UnassignedProjects.search_settings_database.search_client_key = uc_RootData_ClientKey
    let searchFilters = ["status = 'unassigned'"]
    tableSettings_UnassignedProjects.search_settings_database.search_filters = searchFilters
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={{ visible_active_tasks: {} }}
            tableColumns={tableColumns_ActiveProjects}
            tableDatabaseEndpoint={tableDatabaseEndpoint_UnassignedProjects}
            tableSettings={tableSettings_UnassignedProjects}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_UnlinkedTable = (): JSX.Element => {
    let tableJSX = <></>
    if (tableSettings_UnlinkedProjects.search_settings_database == null) {
      tableSettings_UnlinkedProjects.search_settings_database = { search_type: 'firebase' }
    }
    tableSettings_UnlinkedProjects.search_settings_database.search_endpoint = tableSearchEndpoint_UnlinkedProjects
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_UnlinkedProjects}
            tableDatabaseEndpoint={tableDatabaseEndpoint_UnlinkedProjects}
            tableSettings={tableSettings_UnlinkedProjects}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_UnassignedCountBadgeLabel = (count: number): JSX.Element => {
    let labelJSX = <>{count}</>
    if (count >= 10) {
      labelJSX = <>10+</>
    }
    return labelJSX
  }

  const rJSX_UnassignedProjectsTabHeader = (): JSX.Element => {
    let labelJSX = <></>
    if (objectToArray(us_unassignedProjects).length > 0) {
      labelJSX = (
        <Box>
          <Badge
            badgeContent={rJSX_UnassignedCountBadgeLabel(objectToArray(us_unassignedProjects).length)}
            color="error"
          >
            {s_UNASSIGNED_PROJECTS}
          </Badge>
        </Box>
      )
    } else {
      labelJSX = <Box>{s_UNASSIGNED_PROJECTS}</Box>
    }
    return labelJSX
  }

  const rJSX_UnlinkedProjectsTabHeader = (): JSX.Element => {
    let labelJSX = <></>
    if (objectToArray(us_unlinkedProjects).length > 0) {
      labelJSX = (
        <Box>
          <Badge
            badgeContent={rJSX_UnassignedCountBadgeLabel(objectToArray(us_unlinkedProjects).length)}
            color="error"
          >
            {s_UNLINKED_PROJECTS}
          </Badge>
        </Box>
      )
    } else {
      labelJSX = <Box>{s_UNLINKED_PROJECTS}</Box>
    }
    return labelJSX
  }

  const rJSX_NewProtoProjectButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    // if( us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.new_proto_button === true ){
    if (shrinkButton === true) {
      buttonJSX = (
        <Tooltip
          title={s_NEW_UNLINKED_PROJECT}
          placement="top"
        >
          <Button
            variant="contained"
            color="success"
            startIcon={
              <Icon
                icon="circle-plus"
                type="solid"
              />
            }
            onClick={() => {
              openNewProtoProjectDialog()
            }}
            className="bp_icon_only_button"
            disabled={us_pageSpecificUserRolePermission == null || us_pageSpecificUserRolePermission.new_proto_button !== true}
          ></Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          variant="contained"
          color="success"
          startIcon={
            <Icon
              icon="circle-plus"
              type="solid"
            />
          }
          onClick={() => {
            openNewProtoProjectDialog()
          }}
          disabled={us_pageSpecificUserRolePermission == null || us_pageSpecificUserRolePermission.new_proto_button !== true}
        >
          {s_NEW_UNLINKED_PROJECT}
        </Button>
      )
    }
    // }
    return buttonJSX
  }

  const rJSX_TableStatusFilterButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrinkButton === true) {
      buttonJSX = (
        <Tooltip
          title={
            <>
              {getProp(projectStatusOptions[us_tableFilterStatus], 'value', '')} {s_PROJECTS}
            </>
          }
          placement="top"
        >
          <Button
            variant="contained"
            color="secondary"
            className="bp_icon_only_button"
            startIcon={
              <Icon
                icon="filters"
                type="solid"
              />
            }
            onClick={() => {
              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: { status: us_tableFilterStatus },
                    formInputs: formInputs_TableStatusFilter,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        us_setTableFilterStatus(formSubmittedData.status)
                        resolve({ success: true })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: s_TABLE_STATUS_FILTER,
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="filters"
                      />
                    ),
                  },
                },
              })
            }}
          ></Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            <Icon
              icon="filters"
              type="solid"
            />
          }
          onClick={() => {
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: { status: us_tableFilterStatus },
                  formInputs: formInputs_TableStatusFilter,
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: {},
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      us_setTableFilterStatus(formSubmittedData.status)
                      resolve({ success: true })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'success',
                  formDialogHeaderText: s_TABLE_STATUS_FILTER,
                  formDialogIcon: (
                    <Icon
                      type="solid"
                      icon="filters"
                    />
                  ),
                },
              },
            })
          }}
        >
          {getProp(projectStatusOptions[us_tableFilterStatus], 'value', '')} {s_PROJECTS}
        </Button>
      )
    }
    return buttonJSX
  }

  // Search
  const rJSX_StatusFilterInput = (): JSX.Element => {
    let inputJSX = <></>
    inputJSX = (
      <FormControl className="bp_thin_select_input">
        <Select
          color="primary"
          value={us_projectFilterStatus}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setProjectFilterStatus(event.target.value)
            }
          }}
          variant="outlined"
        >
          {objectToArray(projectStatusOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
            <MenuItem
              key={index}
              value={option['key']}
              disabled={option['disabled'] === true}
            >
              {option['value']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
    return inputJSX
  }

  const rJSX_CssFilterInput = (): JSX.Element => {
    let inputJSX = <></>
    if (us_filterByCSS === true) {
      inputJSX = (
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_projectFilterCSS}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setProjectFilterCSS(event.target.value)
              }
            }}
            variant="outlined"
          >
            {us_cssRepOptions.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
                disabled={option['disabled'] === true}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
    return inputJSX
  }

  const rJSX_SalesFilterInput = (): JSX.Element => {
    let inputJSX = <></>
    if (us_filterBySales === true) {
      inputJSX = (
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_projectFilterSales}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setProjectFilterSales(event.target.value)
              }
            }}
            variant="outlined"
          >
            {us_salesRepOptions.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
                disabled={option['disabled'] === true}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
    return inputJSX
  }

  const rJSX_CustomerHappinessFilterInput = (): JSX.Element => {
    let inputJSX = <></>
    if (us_filterByCustomerHappiness === true) {
      inputJSX = (
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_projectFilterCustomerHappiness}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setProjectFilterCustomerHappiness(event.target.value)
              }
            }}
            variant="outlined"
          >
            {objectToArray(customerStatusOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
                disabled={option['disabled'] === true}
              >
                <Box>
                  {option['icon']} {option['value']}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
    return inputJSX
  }

  const rJSX_WorkflowFilterInput = (): JSX.Element => {
    let inputJSX = <></>
    if (us_filterByWorkflow === true) {
      inputJSX = (
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_projectFilterWorkflow}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setProjectFilterWorkflowSelectedTask(null)
                us_setSortedMilestoneFilterTasksArray([])
                us_setProjectFilterWorkflow(event.target.value)
              }
            }}
            variant="outlined"
          >
            {us_taskWorkflowsArray.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
                disabled={option['disabled'] === true}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
    return inputJSX
  }

  const rJSX_FilterTaskMilestoneButton = (task: TsInterface_UnspecifiedObject, allMilestoneTasks: TsInterface_UnspecifiedObject[]): JSX.Element => {
    let buttonJSX = <></>
    let topLeftRadius: string | number = 0
    let bottomLeftRadius: string | number = 0
    let topRightRadius: string | number = 0
    let bottomRightRadius: string | number = 0
    if (task.index === 0) {
      topLeftRadius = '10px'
      bottomLeftRadius = '10px'
    }
    if (task.index === allMilestoneTasks.length - 1) {
      topRightRadius = '10px'
      bottomRightRadius = '10px'
    }
    if (us_projectFilterWorkflowSelectedTask === task.key) {
      buttonJSX = (
        <Button
          sx={{
            fontSize: '11px',
            borderTopLeftRadius: topLeftRadius,
            borderBottomLeftRadius: bottomLeftRadius,
            borderTopRightRadius: topRightRadius,
            borderBottomRightRadius: bottomRightRadius,
          }}
          variant="contained"
          color="success"
          onClick={() => {
            us_setProjectFilterWorkflowSelectedTask(null)
          }}
        >
          {task['name']}
        </Button>
      )
    } else {
      // Instantiate Variables
      let selectedTask: TsInterface_UnspecifiedObject | null = null
      // Loop through milestone tasks
      for (let loopTaskIndex in allMilestoneTasks) {
        let loopTask = allMilestoneTasks[loopTaskIndex]
        if (loopTask != null && loopTask.key != null && loopTask.key === us_projectFilterWorkflowSelectedTask) {
          selectedTask = loopTask
        }
      }
      let buttonVariant: 'outlined' | 'contained' = 'outlined'
      let buttonColor: 'info' | 'warning' = 'warning'
      if (selectedTask != null && selectedTask.index > task.index) {
        buttonVariant = 'contained'
        buttonColor = 'info'
      }
      buttonJSX = (
        <Button
          sx={{
            fontSize: '11px',
            borderTopLeftRadius: topLeftRadius,
            borderBottomLeftRadius: bottomLeftRadius,
            borderTopRightRadius: topRightRadius,
            borderBottomRightRadius: bottomRightRadius,
          }}
          variant={buttonVariant}
          color={buttonColor}
          onClick={() => {
            us_setProjectFilterWorkflowSelectedTask(task.key)
          }}
        >
          {task['name']}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_WorkflowFilterAdditionalInputs = (): JSX.Element => {
    let inputsJSX = <></>
    if (us_sortedMilestoneFilterTasksArray != null && us_sortedMilestoneFilterTasksArray.length > 0) {
      inputsJSX = (
        <Box>
          {us_sortedMilestoneFilterTasksArray.map((task: TsInterface_UnspecifiedObject, index: number) => (
            <Box
              key={index}
              className="tw-inline-block"
            >
              {rJSX_FilterTaskMilestoneButton(task, us_sortedMilestoneFilterTasksArray)}
            </Box>
          ))}
        </Box>
      )
    }
    return inputsJSX
  }

  const rJSX_SearchButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        className="tw-mt-2"
        variant="contained"
        color="secondary"
        startIcon={
          <Icon
            icon="magnifying-glass"
            type="solid"
          />
        }
        onClick={() => {
          us_setShowSearchResults(true)
          ur_forceRerender()
        }}
      >
        {s_SEARCH}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_SearchAgainButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="secondary"
        className=""
        startIcon={
          <Icon
            icon="magnifying-glass"
            type="solid"
          />
        }
        onClick={() => {
          us_setShowSearchResults(false)
          ur_forceRerender()
        }}
      >
        {s_SEARCH_AGAIN}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_ProjectSearchQuery = (clientKey: string): JSX.Element => {
    let queryJSX = <></>
    if (us_showSearchResults === false) {
      queryJSX = (
        <Box className="tw-p-4">
          <Typography
            variant="h6"
            className="tw-my-1"
          >
            {s_DIRECT_PROJECT_SEARCH}
          </Typography>
          <Box>
            <SearchInput
              clientKey={clientKey}
              searchIndexKey={'projects'}
              searchFilters={[]}
              searchResultRenderer={rJSX_ProjectSearchResultWithStatus}
              additionalSearchData={{}}
            />
          </Box>
          <Divider className="tw-my-2" />
          <Typography
            variant="h6"
            className="tw-my-1"
          >
            {s_FILTER_PROJECT_SEARCH}
          </Typography>
          <Stack
            direction="row"
            spacing={2}
          >
            <Switch
              checked={true}
              disabled={true}
            />
            <Typography
              variant="h6"
              className="tw-my-1"
            >
              {s_FILTER_BY_PROJECT_STATUS}
            </Typography>
            {rJSX_StatusFilterInput()}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
          >
            <Switch
              checked={us_filterByCSS}
              onChange={(event, value) => {
                if (value === true || value === false) {
                  us_setFilterByCSS(value)
                }
              }}
            />
            <Typography
              variant="h6"
              className="tw-my-1"
            >
              {s_FILTER_BY_CSS_REP}
            </Typography>
            {rJSX_CssFilterInput()}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
          >
            <Switch
              checked={us_filterBySales}
              onChange={(event, value) => {
                if (value === true || value === false) {
                  us_setFilterBySales(value)
                }
              }}
            />
            <Typography
              variant="h6"
              className="tw-my-1"
            >
              {s_FILTER_BY_SALES_REP}
            </Typography>
            {rJSX_SalesFilterInput()}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
          >
            <Switch
              checked={us_filterByCustomerHappiness}
              onChange={(event, value) => {
                if (value === true || value === false) {
                  us_setFilterByCustomerHappiness(value)
                }
              }}
            />
            <Typography
              variant="h6"
              className="tw-my-1"
            >
              {s_FILTER_BY_CUSTOMER_SATISFACTION}
            </Typography>
            {rJSX_CustomerHappinessFilterInput()}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
          >
            <Switch
              checked={us_filterByWorkflow}
              onChange={(event, value) => {
                if (value === true || value === false) {
                  us_setFilterByWorkflow(value)
                }
              }}
            />
            <Typography
              variant="h6"
              className="tw-my-1"
            >
              {s_FILTER_BY_WORKFLOW}
            </Typography>
            {rJSX_WorkflowFilterInput()}
          </Stack>
          {rJSX_WorkflowFilterAdditionalInputs()}
          {rJSX_SearchButton()}
        </Box>
      )
    } else {
      queryJSX = (
        <Box>
          <Box className="tw-p-4">{rJSX_SearchAgainButton()}</Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_ActiveProjects}
              tableDatabaseEndpoint={tableDatabaseEndpoint_SearchProjects}
              tableSettings={tableSettings_SearchProjects}
            />
          </Card>
        </Box>
      )
    }
    return queryJSX
  }

  const rJSX_SearchProjectsTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = <Card className="tw-text-left">{rJSX_ProjectSearchQuery(uc_RootData_ClientKey)}</Card>
    } else {
      tabJSX = <></>
    }
    return tabJSX
  }

  // const rJSX_ProjectsForWorkflowMilestonePhase = (
  // 	projects: TsInterface_UnspecifiedObject,

  // ): JSX.Element => {

  // }

  const rJSX_ProjectPhasesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Box>
          <Card className="tw-text-left tw-p-4 tw-mb-2">
            <Box>
              <Stack
                direction="row"
                spacing={0}
              >
                <Typography
                  variant="h6"
                  className="tw-my-1 tw-mr-2"
                >
                  {s_WORKFLOW}
                </Typography>
                <FormControl className="bp_thin_select_input">
                  <Select
                    color="primary"
                    value={us_phaseWorkflowKey}
                    onChange={(event: any) => {
                      if (event != null && event.target != null && event.target.value != null) {
                        us_setPhaseWorkflowKey(event.target.value)
                      }
                    }}
                    variant="outlined"
                  >
                    {us_taskWorkflowsArray.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
                      <MenuItem
                        key={index}
                        value={option['key']}
                        disabled={option['disabled'] === true}
                      >
                        {option['value']}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Box>
          </Card>
          {rJSX_ProjectPhasesTabContent(
            us_phaseWorkflowKey,
            us_taskWorkflowsObject,
            us_sortedMilestonePhaseTasksArray,
            us_projectsSortedByMilestone,
            un_routerNavigation,
            'AdminActiveProjectViewPage',
            us_showingProjectNotes,
            'write',
            uc_setUserInterface_FormDialogDisplay,
            uc_setUserInterface_ErrorDialogDisplay,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
          )}
        </Box>
      )
    } else {
      tabJSX = <></>
    }
    return tabJSX
  }

  const rJSX_ArchivedPowerwallOrdersButton = () => {
    let buttonJSX = <></>
    if (us_powerwallTableViewType === 'archived') {
      buttonJSX = (
        <Button
          variant="contained"
          color="secondary"
          className="tw-mr-2"
          startIcon={
            <Icon
              icon="filter"
              type="solid"
            />
          }
          onClick={() => {
            us_setPowerwallTableViewType('active')
          }}
        >
          {s_VIEW_ACTIVE_ORDERS}
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          variant="contained"
          color="secondary"
          className="tw-mr-2"
          startIcon={
            <Icon
              icon="filter"
              type="solid"
            />
          }
          onClick={() => {
            us_setPowerwallTableViewType('archived')
          }}
        >
          {s_VIEW_ARCHIVED_ORDERS}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_DownloadPowerwallConversionStatsButton = () => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="info"
        startIcon={
          <Icon
            icon="cloud-arrow-down"
            type="solid"
          />
        }
        onClick={() => {
          downloadPowerwallConversionStats()
        }}
      >
        {s_DOWNLOAD_CONVERSION_STATS}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_PowerwallOrdersTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <Card className="">
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_PowerwallOrders}
            tableDatabaseEndpoint={tableDatabaseEndpoint_PowerwallOrders}
            tableSettings={tableSettings_PowerwallOrders}
          />
        </Card>
        <Box className="tw-text-right"></Box>
      </Box>
    )
    return tabJSX
  }

  // const rJSX_ShowingProjectNotesButton = (minimize: boolean) => {
  // let buttonJSX = <></>
  // buttonJSX = (
  //   <Button
  //     variant="outlined"
  //     color="secondary"
  //     className="tw-mr-2"
  //     startIcon={<Icon icon="notes" />}
  //     onClick={() => {
  //       us_setShowingProjectNotes(!us_showingProjectNotes)
  //     }}
  //   >
  //     {us_showingProjectNotes ? rLIB('Showing Project Notes') : rLIB('Hiding Project Notes')}
  //   </Button>
  // )
  // return buttonJSX
  // }

  const rJSX_Page = (): JSX.Element => {
    let pageTabs: TsInterface_TabContentUrlArray = []
    if (us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.my_projects === true) {
      pageTabs.push({
        tabUrlKey: 'My_Projects',
        tabHeader: (
          <>
            {s_MY} {getProp(projectStatusOptions[us_tableFilterStatus], 'value', '')} {s_PROJECTS}
          </>
        ),
        tabContent: <Box>{rJSX_MyProjectsTable()}</Box>,
        tabButtons: [
          // { fullJSX: rJSX_SchedulingButton(false), minJSX: rJSX_SchedulingButton(true), sizeCutoff: 380 },
          // { fullJSX: rJSX_ImportsButton(false), minJSX: rJSX_ImportsButton(true), sizeCutoff: 510 },
          // { fullJSX: rJSX_StatsButton(false), minJSX: rJSX_StatsButton(true), sizeCutoff: 560 },
          // { fullJSX: rJSX_NewProtoProjectButton( false ), minJSX: rJSX_NewProtoProjectButton( true ), sizeCutoff: 0 },
          { fullJSX: rJSX_TableStatusFilterButton(false), minJSX: rJSX_TableStatusFilterButton(true), sizeCutoff: 0 },
        ],
      })
    }
    if (us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.all_projects === true) {
      pageTabs.push({
        tabUrlKey: 'All_Projects',
        tabHeader: (
          <>
            {rLIB('All')} {getProp(projectStatusOptions[us_tableFilterStatus], 'value', '')} {s_PROJECTS}
          </>
        ),
        tabContent: <Box>{rJSX_AllProjectsTable()}</Box>,
        tabButtons: [
          // { fullJSX: rJSX_SchedulingButton(false), minJSX: rJSX_SchedulingButton(true), sizeCutoff: 380 },
          // { fullJSX: rJSX_ImportsButton(false), minJSX: rJSX_ImportsButton(true), sizeCutoff: 510 },
          // { fullJSX: rJSX_StatsButton(false), minJSX: rJSX_StatsButton(true), sizeCutoff: 560 },
          // { fullJSX: rJSX_NewProtoProjectButton( false ), minJSX: rJSX_NewProtoProjectButton( true ), sizeCutoff: 0 },
          { fullJSX: rJSX_TableStatusFilterButton(false), minJSX: rJSX_TableStatusFilterButton(true), sizeCutoff: 0 },
        ],
      })
    }
    if (us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.unassigned_projects === true) {
      pageTabs.push({
        tabUrlKey: 'Unassigned_Projects',
        tabHeader: rJSX_UnassignedProjectsTabHeader(),
        tabContent: <Box>{rJSX_UnassignedTable()}</Box>,
        tabButtons: [
          // { fullJSX: rJSX_SchedulingButton(false), minJSX: rJSX_SchedulingButton(true), sizeCutoff: 315 },
          // { fullJSX: rJSX_ImportsButton(false), minJSX: rJSX_ImportsButton(true), sizeCutoff: 445 },
          // { fullJSX: rJSX_StatsButton(false), minJSX: rJSX_StatsButton(true), sizeCutoff: 490 },
          { fullJSX: rJSX_NewProtoProjectButton(false), minJSX: rJSX_NewProtoProjectButton(true), sizeCutoff: 0 },
          // { fullJSX: rJSX_TableStatusFilterButton( false ), minJSX: rJSX_TableStatusFilterButton( true ), sizeCutoff: 0 },
        ],
      })
    }
    if (us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.unlinked_projects === true) {
      pageTabs.push({
        tabUrlKey: 'Unlinked_Projects',
        tabHeader: rJSX_UnlinkedProjectsTabHeader(),
        tabContent: <Box>{rJSX_UnlinkedTable()}</Box>,
        tabButtons: [
          // { fullJSX: rJSX_SchedulingButton(false), minJSX: rJSX_SchedulingButton(true), sizeCutoff: 375 },
          // { fullJSX: rJSX_ImportsButton(false), minJSX: rJSX_ImportsButton(true), sizeCutoff: 505 },
          // { fullJSX: rJSX_StatsButton(false), minJSX: rJSX_StatsButton(true), sizeCutoff: 550 },
          { fullJSX: rJSX_NewProtoProjectButton(false), minJSX: rJSX_NewProtoProjectButton(true), sizeCutoff: 730 },
          // { fullJSX: rJSX_TableStatusFilterButton( false ), minJSX: rJSX_TableStatusFilterButton( true ), sizeCutoff: 0 },
        ],
      })
    }
    if (us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.project_phases === true) {
      pageTabs.push({
        tabUrlKey: 'Project_Phases',
        tabHeader: rLIB('Project Phases'),
        tabContent: <Box>{rJSX_ProjectPhasesTab()}</Box>,
        tabButtons: [
          // { fullJSX: rJSX_ShowingProjectNotesButton(false), minJSX: rJSX_ShowingProjectNotesButton(true), sizeCutoff: 0 },
        ],
      })
    }
    if (us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.project_search === true) {
      pageTabs.push({
        tabUrlKey: 'Project_Search',
        tabHeader: rLIB('Project Search'),
        tabContent: <Box>{rJSX_SearchProjectsTab()}</Box>,
        tabButtons: [
          // { fullJSX: rJSX_SchedulingButton(false), minJSX: rJSX_SchedulingButton(true), sizeCutoff: 315 },
          // { fullJSX: rJSX_ImportsButton(false), minJSX: rJSX_ImportsButton(true), sizeCutoff: 445 },
          // { fullJSX: rJSX_StatsButton(false), minJSX: rJSX_StatsButton(true), sizeCutoff: 490 },
          // { fullJSX: rJSX_NewProtoProjectButton( false ), minJSX: rJSX_NewProtoProjectButton( true ), sizeCutoff: 0 },
          // { fullJSX: rJSX_TableStatusFilterButton( false ), minJSX: rJSX_TableStatusFilterButton( true ), sizeCutoff: 0 },
        ],
      })
    }
    if (us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.powerwall_orders === true) {
      pageTabs.push({
        tabUrlKey: 'Powerwall_Orders',
        tabHeader: rLIB('Powerwall Orders'),
        tabContent: <Box>{rJSX_PowerwallOrdersTab()}</Box>,
        tabButtons: [
          { fullJSX: rJSX_ArchivedPowerwallOrdersButton(), minJSX: rJSX_ArchivedPowerwallOrdersButton(), sizeCutoff: 0 },
          { fullJSX: rJSX_DownloadPowerwallConversionStatsButton(), minJSX: rJSX_DownloadPowerwallConversionStatsButton(), sizeCutoff: 0 },
        ],
      })
    }
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_ACTIVE_PROJECTS}
        pageKey={pageKey}
        content={
          <Box>
            {/* { rJSX_NewProtoProjectButton() } */}
            {/* { rJSX_TableStatusFilterButton() } */}
            {/* { rJSX_StatsButton() } */}
            {/* { rJSX_SchedulingButton() } */}
            {/* { rJSX_ImportsButton() } */}
            <TabsUrl
              tabs={pageTabs}
              tabsSettings={tabsSettings}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
