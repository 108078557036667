///////////////////////////////
// Description
///////////////////////////////

/*

         __          __     _____  _   _ _____ _   _  _____
         \ \        / /\   |  __ \| \ | |_   _| \ | |/ ____|
          \ \  /\  / /  \  | |__) |  \| | | | |  \| | |  __
           \ \/  \/ / /\ \ |  _  /| . ` | | | | . ` | | |_ |
            \  /\  / ____ \| | \ \| |\  |_| |_| |\  | |__| |
             \/  \/_/    \_\_|  \_\_| \_|_____|_| \_|\_____|


		THIS FILE IS COPIED TO THE SERVER - ANY UPDATE NEEDS TO NE IN BOTH PLACES
	*/

///////////////////////////////
// Imports
///////////////////////////////

import { ClientUserRoles } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_InternalUsers_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import {
  DatabaseRef_FinalizedPayroll_CachedCancelledTasksData_Document,
  DatabaseRef_FinalizedPayroll_CachedEfficiencyClockHourData_Document,
  DatabaseRef_FinalizedPayroll_CachedProjectData_Document,
} from 'rfbp_aux/services/database_endpoints/finances/finalized_payroll'
import { DatabaseRef_PayrollRates_EmployeeBaseRates_Document } from 'rfbp_aux/services/database_endpoints/finances/payroll_rates'
import {
  DatabaseRef_AllCancelledScheduledTasksForSpecificTaskAndProject_Query,
  DatabaseRef_AllCancelledScheduledTasksForSpecificTask_Query,
} from 'rfbp_aux/services/database_endpoints/operations/cancelled_scheduled_tasks'
import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_TimeSheetsAllocationTypes_Collection } from 'rfbp_aux/services/database_endpoints/timesheets/allocation_types'
import {
  DatabaseRef_TimePunches_DateRange_Query,
  DatabaseRef_TimePunches_SpecificTask_Query,
  DatabaseRef_TimePunches_UserNextPunch_Query,
} from 'rfbp_aux/services/database_endpoints/timesheets/time_punches'
import { rLIB } from 'rfbp_core/localization/library'
import {
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseStagedBatchUpdate,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnDateFromUnknownDateFormat, returnFormattedDateKey } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// COPIED TO SERVER - ANY UPDATE NEEDS TO NE IN BOTH PLACES
export const itemsToCacheFromProjects: TsInterface_UnspecifiedObject = {
  id_number: {
    error_type: 'error',
    key: 'id_number',
    name: 'Job Code',
  },
  associated_region_key: {
    error_type: 'error',
    key: 'associated_region_key',
    name: 'Region',
  },
  location_distance_from_warehouse: {
    error_type: 'error',
    key: 'location_distance_from_warehouse',
    name: 'Distance from the Warehouse',
  },
  location_state: {
    error_type: 'error',
    key: 'location_state',
    name: 'State',
  },
  system_max_roof_pitch: {
    error_type: 'error',
    key: 'system_max_roof_pitch',
    name: 'Max Roof Pitch',
  },
  system_panel_quantity: {
    error_type: 'error',
    key: 'system_panel_quantity',
    name: 'Panel Quantity',
  },
  system_storage_manufacturer: {
    error_type: null,
    key: 'system_storage_manufacturer',
    name: 'Storage Manufacturer',
  },
  system_storage_model: {
    error_type: null,
    key: 'system_storage_model',
    name: 'Storage Model',
  },
  system_storage_quantity: {
    error_type: 'CUSTOM_storage_error',
    key: 'system_storage_quantity',
    name: 'Storage Quantity',
  },
}

export const unitWorkTypes: TsInterface_UnspecifiedObject = {
  scope_of_work: {
    key: 'scope_of_work',
    unit_type: 'units',
    abbreviation: 'FW-SOW',
    min_employees: 1,
    max_employees: 3,
    name: rLIB('Scope of Work'),
    include_in_efficiency_calculation: true,
    include_in_lifetime_calculation: true,
  },
  install: {
    key: 'install',
    unit_type: 'panels',
    abbreviation: 'FW-INST',
    min_employees: 2,
    max_employees: 4,
    name: rLIB('Install'),
    include_in_efficiency_calculation: true,
    include_in_lifetime_calculation: true,
  },
  repair: {
    key: 'repair',
    unit_type: 'units',
    abbreviation: 'FW-REP',
    min_employees: 1,
    max_employees: 4,
    name: rLIB('Repair'),
    include_in_efficiency_calculation: true,
    include_in_lifetime_calculation: true,
  },
  field_service_dispatch: {
    key: 'field_service_dispatch',
    unit_type: 'units',
    abbreviation: 'FW-FSD',
    min_employees: 1,
    max_employees: 4,
    name: rLIB('Field Service Dispatch'),
    include_in_efficiency_calculation: true,
    include_in_lifetime_calculation: true,
  },
  inspection: {
    key: 'inspection',
    unit_type: 'units',
    abbreviation: 'FW-INSP',
    min_employees: 1,
    max_employees: 1,
    name: rLIB('Inspection'),
    include_in_efficiency_calculation: true,
    include_in_lifetime_calculation: true,
  },
  audit: {
    key: 'audit',
    unit_type: 'units',
    abbreviation: 'FW-AUD',
    min_employees: 1,
    max_employees: 2,
    name: rLIB('Audit'),
    include_in_efficiency_calculation: true,
    include_in_lifetime_calculation: true,
  },
  removal: {
    key: 'removal',
    unit_type: 'panels',
    abbreviation: 'FW-REMV',
    min_employees: 1,
    max_employees: 4,
    name: rLIB('Removal'),
    include_in_efficiency_calculation: false,
    include_in_lifetime_calculation: false,
  },
  resinstall: {
    // TODO: Need to fix parts of the database along with this
    key: 'resinstall', // TODO: Need to fix parts of the database along with this
    unit_type: 'panels',
    abbreviation: 'FW-REINST',
    min_employees: 1,
    max_employees: 4,
    name: rLIB('Reinstall'),
    include_in_efficiency_calculation: true,
    include_in_lifetime_calculation: true,
  },
  warehouse: {
    key: 'warehouse', // TODO: Need to fix parts of the database along with this
    unit_type: 'units',
    abbreviation: 'FW-WARE',
    min_employees: 1,
    max_employees: 1,
    name: rLIB('Warehouse'),
    include_in_efficiency_calculation: false,
    include_in_lifetime_calculation: false,
  },

  // TODO - Warehouse
}

///////////////////////////////
// Functions
///////////////////////////////

const getStateAbbreviation = (address: string) => {
  const stateRegex = /(?:, | )([A-Z]{2})(?: \d{5}, |, |$)/
  const match = address.match(stateRegex)
  if (match && match[1]) {
    return match[1]
  } else {
    return null
  }
}

const splitName = (name: string) => {
  if (name != null && name !== '' && name !== undefined && name !== 'undefined') {
    const indexOfSpace = name.indexOf(' ')
    if (indexOfSpace === -1) {
      return [name, ''] // If no space found, return the whole name and an empty string
    } else {
      const firstName = name.slice(0, indexOfSpace)
      const lastName = name.slice(indexOfSpace + 1)
      return [firstName, lastName]
    }
  } else {
    return ['', '']
  }
}

export const getStartAndEndOfWeek = (date: Date) => {
  const startOfWeek = new Date(date)
  startOfWeek.setHours(0, 0, 0, 0)
  startOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 0)
  const endOfWeek = new Date(date)
  endOfWeek.setHours(23, 59, 59, 999)
  endOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 6)
  return {
    startOfWeek,
    endOfWeek,
  }
}

export const getLastChronologicalDate = (dateArray: string[]) => {
  if (!Array.isArray(dateArray) || dateArray.length === 0) {
    return null // Handle invalid input or empty array
  }
  // Use the Date constructor to parse the dates and convert them to milliseconds
  const dateInMilliseconds = dateArray.map((dateString) => new Date(dateString).getTime())
  // Find the index of the maximum date value
  const maxIndex = dateInMilliseconds.indexOf(Math.max(...dateInMilliseconds))
  // Return the corresponding date string
  return dateArray[maxIndex]
}

export const returnAggregatePunchDataForPayrollCalculations = (clientKey: string, startDate: Date, endDate: Date): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    // Instantiate Variables
    let promiseArray: TsType_UnknownPromise[] = []
    let errorsLoadingData: boolean = false
    let employeeBaseRates: TsInterface_UnspecifiedObject = {}
    let rawPunchData: TsInterface_UnspecifiedObject = {}
    let employeePunchData: TsInterface_UnspecifiedObject = {}
    let internalUsers: TsInterface_UnspecifiedObject = {}
    let aggregatedPunchData: TsInterface_UnspecifiedObject = {}
    let punchDataErrors: TsInterface_UnspecifiedObject = {}
    let punchDataWarnings: TsInterface_UnspecifiedObject = {}
    let allocationTypes: TsInterface_UnspecifiedObject = {}
    let employeeTaskStates: TsInterface_UnspecifiedObject = {}
    // Load Data
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_TimePunches_DateRange_Query(clientKey, startDate, endDate))
        .then((res_DGC) => {
          rawPunchData = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
          errorsLoadingData = true
        }),
    )
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_TimeSheetsAllocationTypes_Collection(clientKey))
        .then((res_DGC) => {
          allocationTypes = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
          errorsLoadingData = true
        }),
    )
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_InternalUsers_Query(clientKey))
        .then((res_DGC) => {
          internalUsers = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
          errorsLoadingData = true
        }),
    )
    promiseArray.push(
      DatabaseGetDocument(DatabaseRef_PayrollRates_EmployeeBaseRates_Document(clientKey))
        .then((res_GCC) => {
          employeeBaseRates = res_GCC.data
        })
        .catch((rej_GCC) => {
          console.error(rej_GCC)
        }),
    )
    // After Data Loaded
    Promise.all(promiseArray).finally(() => {
      if (errorsLoadingData === false) {
        // Loop through and separate punches by employee
        for (let loopPunchKey in rawPunchData) {
          let loopPunch = rawPunchData[loopPunchKey]
          if (employeePunchData[loopPunch.associated_user_key] == null) {
            employeePunchData[loopPunch.associated_user_key] = []
          }
          loopPunch.timestamp = returnDateFromUnknownDateFormat(loopPunch.timestamp).getTime()
          if (loopPunch.status !== 'deleted') employeePunchData[loopPunch.associated_user_key].push(loopPunch)
        }
        // Loop through employees and sort punches by timestamp
        for (let loopEmployeeKey in employeePunchData) {
          let loopEmployeePunches = employeePunchData[loopEmployeeKey]
          loopEmployeePunches.sort((a: { timestamp: number }, b: { timestamp: number }) => {
            return a.timestamp - b.timestamp
          })
          // Loop through punches and make sure that the first punch is a clock in and the last punch is a clock out
          if (loopEmployeePunches != null && loopEmployeePunches[0] != null && loopEmployeePunches[0].type !== 'clock_in') {
            // Error: First punch is not a clock in
            if (punchDataErrors[loopEmployeeKey] == null) {
              punchDataErrors[loopEmployeeKey] = {
                name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                key: loopEmployeeKey,
              }
            }
            punchDataErrors[loopEmployeeKey]['first_punch_not_clock_in'] = true
          }
          if (
            loopEmployeePunches != null &&
            loopEmployeePunches[loopEmployeePunches.length - 1] != null &&
            loopEmployeePunches[loopEmployeePunches.length - 1].type !== 'clock_out'
          ) {
            // Error: Last punch is not a clock out
            if (punchDataErrors[loopEmployeeKey] == null) {
              punchDataErrors[loopEmployeeKey] = {
                name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                key: loopEmployeeKey,
              }
            }
            punchDataErrors[loopEmployeeKey]['last_punch_not_clock_out'] = true
          }
          // Loop through punches and make sure that they alternate between clock in and clock out
          // Also total up the time between punches
          for (let loopPunchIndex in loopEmployeePunches) {
            let loopPunch = loopEmployeePunches[loopPunchIndex]
            if (parseInt(loopPunchIndex) !== 0) {
              let previousLoopPunch = loopEmployeePunches[parseInt(loopPunchIndex) - 1]
              if (loopPunch.type === previousLoopPunch.type) {
                // Error: Back to back punches
                if (punchDataErrors[loopEmployeeKey] == null) {
                  punchDataErrors[loopEmployeeKey] = {
                    name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                    key: loopEmployeeKey,
                  }
                }
                punchDataErrors[loopEmployeeKey]['back_to_back_punches'] = true
              }
              // Error: If loop punch type is not a clock_in or clock_out
              if (loopPunch.type !== 'clock_in' && loopPunch.type !== 'clock_out') {
                if (punchDataErrors[loopEmployeeKey] == null) {
                  punchDataErrors[loopEmployeeKey] = {
                    name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                    key: loopEmployeeKey,
                  }
                }
                punchDataErrors[loopEmployeeKey]['punch_type_not_clock_in_or_clock_out'] = true
              }
              if (loopPunch.type === 'clock_out') {
                // Subtract previous punch timestamp from current punch timestamp to get total time
                let totalMinutes = (loopPunch.timestamp - previousLoopPunch.timestamp) / (1000 * 60)
                let totalHours = totalMinutes / 60
                // Group by allocation subtype codes
                if (aggregatedPunchData[loopEmployeeKey] == null) {
                  aggregatedPunchData[loopEmployeeKey] = {
                    associated_region_key: getProp(internalUsers, loopEmployeeKey + '.associated_region_key', null),
                    associated_region_name: getProp(internalUsers, loopEmployeeKey + '.associated_region_name', null),
                    codes: {},
                    non_working_codes: {},
                    default_location_state: getProp(internalUsers, loopEmployeeKey + '.default_location_state', null),
                    hourly_or_salaried: getProp(internalUsers, loopEmployeeKey + '.hourly_or_salaried', null),
                    id_number_payroll: getProp(internalUsers, loopEmployeeKey + '.id_number_payroll', null),
                    id_organization_payroll: getProp(internalUsers, loopEmployeeKey + '.id_organization_payroll', null),
                    key: loopEmployeeKey,
                    name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                    hardcoded_bonus: getProp(internalUsers, loopEmployeeKey + '.hardcoded_bonus', null),
                    payroll_category_breakdown: {},
                    raw_codes: {},
                    grouped_codes: {},
                    totals: {},
                    user_role: getProp(internalUsers, loopEmployeeKey + '.user_role', null),
                  }
                }
                // Get State from full address
                let state = null
                if (loopPunch.location_state != null) {
                  state = loopPunch.location_state
                  if (employeeTaskStates[loopEmployeeKey] == null) {
                    employeeTaskStates[loopEmployeeKey] = {}
                  }
                  employeeTaskStates[loopEmployeeKey][loopPunch.key] = {
                    state: state,
                    timestamp: loopPunch.timestamp,
                  }
                } else if (loopPunch.location_address != null) {
                  state = getStateAbbreviation(loopPunch.location_address)
                  if (employeeTaskStates[loopEmployeeKey] == null) {
                    employeeTaskStates[loopEmployeeKey] = {}
                  }
                  employeeTaskStates[loopEmployeeKey][loopPunch.key] = {
                    state: state,
                    timestamp: loopPunch.timestamp,
                  }
                }
                // Aggregation Totals
                let selectedAllocationCode = null
                let payrollBreakdownCode = null
                let rawAllocationCode = null
                let nonWorkingAllocationCode = null
                if (previousLoopPunch != null && previousLoopPunch['associated_allocation_type_key'] != null) {
                  let selectedAllocationType = getProp(allocationTypes, previousLoopPunch['associated_allocation_type_key'], {})
                  let selectedAllocationSubtypes = getProp(selectedAllocationType, 'subtypes', {})
                  switch (previousLoopPunch['associated_allocation_type_key']) {
                    case 'admin_time':
                      payrollBreakdownCode = 'OFFICE'
                      selectedAllocationCode = 'REG'
                      // Override Payroll Grouping for specific allocation subtypes
                      if (
                        selectedAllocationSubtypes != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']] != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['include_with_field_work_in_payroll'] === true
                      ) {
                        payrollBreakdownCode = 'FIELD'
                        selectedAllocationCode = 'REG'
                      }
                      // Raw Allocation Code
                      rawAllocationCode = 'OFFICE'
                      if (
                        selectedAllocationSubtypes != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']] != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['name'] != null
                      ) {
                        rawAllocationCode = selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['name']
                      }
                      break
                    case 'break':
                      payrollBreakdownCode = 'OFFICE'
                      selectedAllocationCode = 'REG'
                      // Raw Allocation Code
                      rawAllocationCode = 'OFFICE'
                      break
                    case 'field_work':
                      payrollBreakdownCode = 'FIELD'
                      selectedAllocationCode = 'REG'
                      // Raw Allocation Code
                      rawAllocationCode =
                        getProp(previousLoopPunch, 'associated_allocation_type_name', 'FIELD') +
                        ' - ' +
                        getProp(previousLoopPunch, 'associated_allocation_subtype_name', '(UNKNOWN)')
                      break
                    case 'non_working_time':
                      payrollBreakdownCode = 'OFF'
                      if (
                        selectedAllocationSubtypes != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']] != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['code'] != null
                      ) {
                        selectedAllocationCode = selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['code']
                        nonWorkingAllocationCode = selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['code']
                      }
                      // Raw Allocation Code
                      rawAllocationCode = 'OFF'
                      if (
                        selectedAllocationSubtypes != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']] != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['code'] != null
                      ) {
                        rawAllocationCode = selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['code']
                      }
                      break
                  }
                }
                if (selectedAllocationCode === null) {
                  selectedAllocationCode = 'MISSING'
                }
                if (payrollBreakdownCode === null) {
                  payrollBreakdownCode = 'OFFICE'
                }
                if (aggregatedPunchData[loopEmployeeKey]['payroll_category_breakdown'][payrollBreakdownCode] == null) {
                  aggregatedPunchData[loopEmployeeKey]['payroll_category_breakdown'][payrollBreakdownCode] = 0
                }
                aggregatedPunchData[loopEmployeeKey]['payroll_category_breakdown'][payrollBreakdownCode] += totalHours
                if (aggregatedPunchData[loopEmployeeKey]['totals'][selectedAllocationCode] == null) {
                  aggregatedPunchData[loopEmployeeKey]['totals'][selectedAllocationCode] = 0
                }
                aggregatedPunchData[loopEmployeeKey]['totals'][selectedAllocationCode] += totalHours
                if (aggregatedPunchData[loopEmployeeKey]['codes'][selectedAllocationCode] == null) {
                  aggregatedPunchData[loopEmployeeKey]['codes'][selectedAllocationCode] = {}
                }
                // Non Working Codes
                if (nonWorkingAllocationCode != null) {
                  if (aggregatedPunchData[loopEmployeeKey]['non_working_codes'][nonWorkingAllocationCode] == null) {
                    aggregatedPunchData[loopEmployeeKey]['non_working_codes'][nonWorkingAllocationCode] = 0
                  }
                  aggregatedPunchData[loopEmployeeKey]['non_working_codes'][nonWorkingAllocationCode] += totalHours
                }
                let combinedAllocationKey =
                  getProp(previousLoopPunch, 'associated_allocation_type_key', 'MISSING') +
                  '_' +
                  getProp(previousLoopPunch, 'associated_allocation_subtype_key', 'MISSING')
                aggregatedPunchData[loopEmployeeKey]['codes'][selectedAllocationCode][combinedAllocationKey] = true
                if (rawAllocationCode != null) {
                  if (aggregatedPunchData[loopEmployeeKey]['raw_codes'][rawAllocationCode] == null) {
                    aggregatedPunchData[loopEmployeeKey]['raw_codes'][rawAllocationCode] = 0
                  }
                  aggregatedPunchData[loopEmployeeKey]['raw_codes'][rawAllocationCode] += totalHours
                  if (aggregatedPunchData[loopEmployeeKey]['grouped_codes'][payrollBreakdownCode] == null) {
                    aggregatedPunchData[loopEmployeeKey]['grouped_codes'][payrollBreakdownCode] = {}
                  }
                  if (aggregatedPunchData[loopEmployeeKey]['grouped_codes'][payrollBreakdownCode][rawAllocationCode] == null) {
                    aggregatedPunchData[loopEmployeeKey]['grouped_codes'][payrollBreakdownCode][rawAllocationCode] = 0
                  }
                  aggregatedPunchData[loopEmployeeKey]['grouped_codes'][payrollBreakdownCode][rawAllocationCode] += totalHours
                }
              }
            }
          }
        }
        // Warnings
        if (objectToArray(aggregatedPunchData).length === 0) {
          punchDataWarnings['no_data'] = true
        }
        for (let loopUserKey in aggregatedPunchData) {
          // Add Task States to aggregatedPunchData for minimum wage calculations
          if (employeeTaskStates != null && employeeTaskStates[loopUserKey] != null) {
            aggregatedPunchData[loopUserKey]['task_states'] = employeeTaskStates[loopUserKey]
          }
          // Add Base Rates
          let loopUserBaseRate = getProp(employeeBaseRates, loopUserKey, null)
          aggregatedPunchData[loopUserKey]['base_wage'] = loopUserBaseRate
        }
        // Resolve
        resolve({
          success: true,
          data: aggregatedPunchData,
          punchDataErrors: punchDataErrors,
          punchDataWarnings: punchDataWarnings,
          internalUsers: internalUsers,
        })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to aggregate timesheet data'),
            details: rLIB('Failed to query database'),
            code: 'ER-D-PC-RAPDFPC-01',
          },
        })
      }
    })
  })
}

export const recacheProjectAndTimesheetDataForPayroll = (
  clientKey: string,
  us_mondayDateKey: string | null,
  us_weekCompletedTasks: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    // Instantiate Variables
    let promiseArray1: TsType_UnknownPromise[] = []
    let promiseArray2: TsType_UnknownPromise[] = []
    let projectsToLoad: TsInterface_UnspecifiedObject = {}
    let projectsLoaded: TsInterface_UnspecifiedObject = {}
    let cancelledTasks: TsInterface_UnspecifiedObject = {}
    let clockEvents: TsInterface_UnspecifiedObject = {}
    let cachedProjectDataUpdateObject: TsInterface_UnspecifiedObject = {}
    let cachedCancelledTasksDataUpdateObject: TsInterface_UnspecifiedObject = {}
    let cachedEfficiencyHoursDataUpdateObject: TsInterface_UnspecifiedObject = {}
    // Get List of projects to load
    for (let loopTaskKey in us_weekCompletedTasks) {
      let loopTask = us_weekCompletedTasks[loopTaskKey]
      // Skip Tasks Requiring Sales Partner Approval
      if (
        loopTask['sales_partner_approval_required'] != true ||
        (loopTask['sales_partner_approval_required'] == true && loopTask['sales_partner_approval_granted'] == true)
      ) {
        if (loopTask['associated_project_key'] != null) {
          projectsToLoad[loopTask['associated_project_key']] = {
            key: loopTask['associated_project_key'],
            name: getProp(loopTask, 'associated_project_id_number', null),
          }
        }
      }
    }
    // Load projects
    for (let loopProjectKey in projectsToLoad) {
      promiseArray1.push(
        DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, loopProjectKey))
          .then((res_DGD) => {
            projectsLoaded[loopProjectKey] = res_DGD.data
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          }),
      )
    }

    // TODO - revamp efficiency so this will probably change

    // Load Clock Ins linked to tasks
    for (let loopTaskKey in us_weekCompletedTasks) {
      let loopTask = us_weekCompletedTasks[loopTaskKey]
      // Skip Tasks Requiring Sales Partner Approval
      if (
        loopTask['sales_partner_approval_required'] != true ||
        (loopTask['sales_partner_approval_required'] == true && loopTask['sales_partner_approval_granted'] == true)
      ) {
        promiseArray1.push(
          DatabaseGetCollection(DatabaseRef_TimePunches_SpecificTask_Query(clientKey, loopTaskKey))
            .then((res_DGC) => {
              for (let loopPunchKey in res_DGC.data) {
                let loopPunch = res_DGC.data[loopPunchKey]
                clockEvents[loopPunchKey] = { clock_in: loopPunch }
              }
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
      }
    }
    // Load Cancelled Events
    for (let loopTaskKey in us_weekCompletedTasks) {
      let loopTask = us_weekCompletedTasks[loopTaskKey]
      // Skip Tasks Requiring Sales Partner Approval
      if (
        loopTask['sales_partner_approval_required'] != true ||
        (loopTask['sales_partner_approval_required'] == true && loopTask['sales_partner_approval_granted'] == true)
      ) {
        promiseArray1.push(
          DatabaseGetCollection(DatabaseRef_AllCancelledScheduledTasksForSpecificTask_Query(clientKey, loopTaskKey))
            .then((res_DGC) => {
              for (let loopCanceledTaskKey in res_DGC.data) {
                cancelledTasks[loopCanceledTaskKey] = res_DGC.data[loopCanceledTaskKey]
              }
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
        promiseArray1.push(
          DatabaseGetCollection(
            DatabaseRef_AllCancelledScheduledTasksForSpecificTaskAndProject_Query(
              clientKey,
              loopTask['associated_task_key'],
              loopTask['associated_project_key'],
            ),
          )
            .then((res_DGC) => {
              for (let loopCanceledTaskKey in res_DGC.data) {
                cancelledTasks[loopCanceledTaskKey] = res_DGC.data[loopCanceledTaskKey]
              }
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
      }
    }
    // After all projects are loaded
    Promise.all(promiseArray1).then(() => {
      // Loop through and generate cached project data object
      for (let loopProjectKey in projectsLoaded) {
        let loopProject = projectsLoaded[loopProjectKey]
        cachedProjectDataUpdateObject[loopProjectKey] = {}
        if (loopProject != null) {
          for (let loopItemKey in itemsToCacheFromProjects) {
            if (loopProject[loopItemKey] != null) {
              cachedProjectDataUpdateObject[loopProjectKey][loopItemKey] = loopProject[loopItemKey]
            }
          }
        }
      }
      for (let loopTaskKey in cancelledTasks) {
        let loopCanceledTask = cancelledTasks[loopTaskKey]
        if (loopCanceledTask['associated_project_key'] != null) {
          let previousCompletionTimestamp = null
          let previousCompletionTimestampDateKey = null
          if (loopCanceledTask != null && loopCanceledTask['timestamp_previous_completion'] != null) {
            previousCompletionTimestamp = returnDateFromUnknownDateFormat(loopCanceledTask['timestamp_previous_completion'])
            let weekBoundingDates = getStartAndEndOfWeek(previousCompletionTimestamp)
            previousCompletionTimestampDateKey = returnFormattedDateKey(weekBoundingDates.startOfWeek)
          }
          cachedCancelledTasksDataUpdateObject[loopTaskKey] = {
            associated_project_key: getProp(loopCanceledTask, 'associated_project_key', null),
            associated_task_key: getProp(loopCanceledTask, 'associated_task_key', null),
            associated_team_name: getProp(loopCanceledTask, 'associated_team_name', null),
            key: loopTaskKey,
            task_completion_scheduled_dates: getProp(loopCanceledTask, 'task_completion_scheduled_dates', null),
            timestamp_previous_completion: previousCompletionTimestamp,
            timestamp_previous_completion_date_key: previousCompletionTimestampDateKey,
          }
        }
      }
      // Load Subsequent Clock Outs
      for (let loopClockInKey in clockEvents) {
        let loopClockIn = clockEvents[loopClockInKey]['clock_in']
        promiseArray2.push(
          DatabaseGetCollection(
            DatabaseRef_TimePunches_UserNextPunch_Query(
              clientKey,
              loopClockIn['associated_user_key'],
              returnDateFromUnknownDateFormat(loopClockIn['timestamp']),
            ),
          )
            .then((res_DGC) => {
              if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length >= 1) {
                clockEvents[loopClockInKey]['clock_out'] = objectToArray(res_DGC.data)[0]
              }
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
      }
      // After Data Loaded
      Promise.all(promiseArray2).then(() => {
        // Format clock data
        for (let loopClockInKey in clockEvents) {
          let loopClockIn = clockEvents[loopClockInKey]['clock_in']
          let loopClockOut = clockEvents[loopClockInKey]['clock_out']
          if (loopClockIn != null && loopClockOut != null) {
            if (
              loopClockIn != null &&
              loopClockIn['associated_user_key'] != null &&
              cachedEfficiencyHoursDataUpdateObject[loopClockIn['associated_user_key']] == null
            ) {
              cachedEfficiencyHoursDataUpdateObject[loopClockIn['associated_user_key']] = {
                associated_user_key: loopClockIn['associated_user_key'],
                associated_user_name: getProp(loopClockIn, 'associated_user_name', null),
                punched_events: {},
              }
            }
            // Database Ready Data
            cachedEfficiencyHoursDataUpdateObject[loopClockIn['associated_user_key']]['punched_events'][loopClockInKey] = {
              associated_tasks: getProp(loopClockIn, 'associated_tasks', {}),
              hours:
                (returnDateFromUnknownDateFormat(loopClockOut['timestamp']).getTime() - returnDateFromUnknownDateFormat(loopClockIn['timestamp']).getTime()) /
                (1000 * 60 * 60),
              total_tasks: objectToArray(getProp(loopClockIn, 'associated_tasks', {})).length,
            }
            if (loopClockOut['type'] !== 'clock_out') {
              cachedEfficiencyHoursDataUpdateObject[loopClockIn['associated_user_key']]['punched_events'][loopClockInKey]['missing_clock_out'] = true
            }
          }
        }
        // Save Data
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        for (let loopCachedProjectKey in cachedProjectDataUpdateObject) {
          updateArray.push({
            type: 'setMerge',
            ref: DatabaseRef_FinalizedPayroll_CachedProjectData_Document(clientKey, us_mondayDateKey as string, loopCachedProjectKey),
            data: cachedProjectDataUpdateObject[loopCachedProjectKey],
          })
        }
        for (let loopCachedTaskKey in cachedCancelledTasksDataUpdateObject) {
          updateArray.push({
            type: 'setMerge',
            ref: DatabaseRef_FinalizedPayroll_CachedCancelledTasksData_Document(clientKey, us_mondayDateKey as string, loopCachedTaskKey),
            data: cachedCancelledTasksDataUpdateObject[loopCachedTaskKey],
          })
        }
        for (let loopEfficiencyHoursKey in cachedEfficiencyHoursDataUpdateObject) {
          updateArray.push({
            type: 'setOverwrite',
            ref: DatabaseRef_FinalizedPayroll_CachedEfficiencyClockHourData_Document(clientKey, us_mondayDateKey as string, loopEfficiencyHoursKey),
            data: cachedEfficiencyHoursDataUpdateObject[loopEfficiencyHoursKey],
          })
        }
        DatabaseStagedBatchUpdate(updateArray)
          .then((res_DSBU) => {
            resolve({ success: true })
          })
          .catch((rej_DSBU) => {
            reject(rej_DSBU)
          })
      })
    })
  })
}

// Massive Calculation Function that calls a series of helper functions to calculate payroll data
export const returnPayrollCalculatedData = (
  us_mondayDateKey: string | null,
  us_weekStartDate: Date | null,
  us_weekEndDate: Date | null,
  us_finalizedWeekPayroll_CustomMappingData: TsInterface_UnspecifiedObject,
  us_aggregatedWeekPunchData: TsInterface_UnspecifiedObject,
  us_selectedWeekCombineLogs: TsInterface_UnspecifiedObject,
  us_previousUserCombineLogs: TsInterface_UnspecifiedObject,
  us_weekCompletedTasks: TsInterface_UnspecifiedObject,
  us_payrollRatesData: TsInterface_UnspecifiedObject,
  us_minimumWageValues: TsInterface_UnspecifiedObject,
  us_finalizedWeekPayroll_ErrorAndWarningResolutionData: TsInterface_UnspecifiedObject,
  us_weekPayrollAdjustments: TsInterface_UnspecifiedObject,
  us_tasksWithQAReviews: TsInterface_UnspecifiedObject,
  us_lifetimeReferralsData: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject => {
  // Instantiate Variables
  let cachedProjects: TsInterface_UnspecifiedObject = getProp(us_finalizedWeekPayroll_CustomMappingData, 'cached_project_data', {})
  let employeeFullPayrollData: TsInterface_UnspecifiedObject = {}
  let errorCount = 0
  let leaderboardData: TsInterface_UnspecifiedObject = {}
  let referralLeaderboardData: TsInterface_UnspecifiedObject = {}
  let leaderboardUserRoles: TsInterface_UnspecifiedObject = {}
  let payrollCalculatedData: TsInterface_UnspecifiedObject = {
    payroll_data: {},
    leaderboard_data: {},
    tasks_missing_cached_projects: {},
    leaderboard_user_roles: {},
  }
  let projectTaskRequireFieldMappingHelperObject: TsInterface_UnspecifiedObject = {}
  let userRoleErrors: TsInterface_UnspecifiedObject = {}
  let usersMissingTaskHours: TsInterface_UnspecifiedObject = {}
  let warningCount = 0
  // If required data is present
  if (us_mondayDateKey != null && us_weekStartDate != null && us_weekEndDate != null) {
    // Generate Base Pay for Hourly Employees
    generateBasePayForHourlyEmployees(us_aggregatedWeekPunchData, employeeFullPayrollData)
    // Generate Current Combine Status Object
    generateCurrentCombineStatusObject(us_selectedWeekCombineLogs, us_previousUserCombineLogs, employeeFullPayrollData)
    // Classify Tasks for Unit Pay Task Generation
    let classifiedUnitPayTasks = classifyTasksForUnitPay(us_weekCompletedTasks, us_payrollRatesData)
    let adHocTasksList = classifiedUnitPayTasks['adHocTasksList']
    let tasksThatNeedCustomUnitMappingData = classifiedUnitPayTasks['tasksThatNeedCustomUnitMappingData']
    let tasksThatNeedPanelCountsForUnitMappingData = classifiedUnitPayTasks['tasksThatNeedPanelCountsForUnitMappingData']
    let tasksWithHardcodeUnitMappingData = classifiedUnitPayTasks['tasksWithHardcodeUnitMappingData']
    let workflowTasksMissingUnitMappingData = classifiedUnitPayTasks['workflowTasksMissingUnitMappingData']
    let tasksMissingUnitMappingData = classifiedUnitPayTasks['tasksMissingUnitMappingData']
    let v1Tasks = classifiedUnitPayTasks['v1Tasks']
    // Generate Unit Pay Tasks
    let unitPayTasks = generateUnitPayTasksDataObject(
      us_finalizedWeekPayroll_CustomMappingData,
      us_payrollRatesData,
      adHocTasksList,
      tasksThatNeedCustomUnitMappingData,
      tasksThatNeedPanelCountsForUnitMappingData,
      tasksWithHardcodeUnitMappingData,
      tasksMissingUnitMappingData,
    )
    let qaReviewTasksData = generateQAReviewTasksDataObject(us_tasksWithQAReviews)
    let distanceAdderCalculationObjects = calculateDistanceAddersForUnitPayTasks(us_mondayDateKey, unitPayTasks, employeeFullPayrollData)
    // Sum up unit pay for each user
    generateLeaderboardDataAndPayrollData(
      unitPayTasks,
      projectTaskRequireFieldMappingHelperObject,
      leaderboardData,
      referralLeaderboardData,
      us_finalizedWeekPayroll_CustomMappingData,
      usersMissingTaskHours,
      us_aggregatedWeekPunchData,
      leaderboardUserRoles,
      userRoleErrors,
      employeeFullPayrollData,
      cachedProjects,
      us_minimumWageValues,
      us_mondayDateKey,
      us_lifetimeReferralsData,
    )
    // Minimum Wage from Punch Data
    addMinimumWageDataFromPunchData(us_aggregatedWeekPunchData, us_minimumWageValues, employeeFullPayrollData)
    // Handle Final Wage & Pay Data
    runFinalWageAndPayCleanup(us_mondayDateKey, employeeFullPayrollData, us_minimumWageValues, us_weekEndDate, us_weekStartDate, us_weekPayrollAdjustments)
    // Clean Up Leaderboard Data
    cleanLeaderboardData(leaderboardData, qaReviewTasksData, employeeFullPayrollData)
    // User Role Errors
    errorCount += Object.keys(userRoleErrors).length
    // Project Mapping Errors
    let projectMappingErrorsAndWarnings = determineProjectMappingErrorsAndWarnings(us_weekCompletedTasks, cachedProjects)
    errorCount += projectMappingErrorsAndWarnings['errorCount']
    let tasksMissingCachedProjects = projectMappingErrorsAndWarnings['tasksMissingCachedProjects']
    // Tasks Missing Unit Mapping Data
    let tasksMissingUnitMappingDataErrorsAndWarnings = determineTasksMissingUnitMappingDataErrorsAndWarnings(workflowTasksMissingUnitMappingData)
    errorCount += tasksMissingUnitMappingDataErrorsAndWarnings['errorCount']
    // Project Field Errors
    let projectFieldsErrorsAndWarningsData = determineProjectFieldsErrorsAndWarnings(
      us_finalizedWeekPayroll_CustomMappingData['cached_project_data'],
      projectTaskRequireFieldMappingHelperObject,
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData,
    )
    errorCount += projectFieldsErrorsAndWarningsData['errorCount']
    warningCount += projectFieldsErrorsAndWarningsData['warningCount']
    // Punch Data Errors
    let punchDataErrorsAndWarningsData = determinePunchDataErrorsAndWarnings(us_aggregatedWeekPunchData)
    errorCount += punchDataErrorsAndWarningsData['errorCount']
    warningCount += punchDataErrorsAndWarningsData['warningCount']
    // Unit Pay Errors
    let unitPayErrorsAndWarningsData = determineUnitPayErrorsAndWarnings(unitPayTasks, us_finalizedWeekPayroll_ErrorAndWarningResolutionData)
    errorCount += unitPayErrorsAndWarningsData['errorCount']
    warningCount += unitPayErrorsAndWarningsData['warningCount']
    // Distance Adder Errors
    let distanceAdderErrorsAndWarningsData = determineDistanceAdderErrorsAndWarnings(
      distanceAdderCalculationObjects['tasksDisqualifiedForDistanceAdders'],
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData,
    )
    warningCount += distanceAdderErrorsAndWarningsData['warningCount']
    // QA Errors
    let qaAdderErrorsAndWarningsData = determineQAAdderErrorsAndWarnings(
      getProp(qaReviewTasksData, 'qa_tasks_errors_object', {}),
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData,
    )
    errorCount += qaAdderErrorsAndWarningsData['errorCount']
    // Team Composition Errors
    let teamCompositionErrorsAndWarningsData = determineTeamCompositionErrorsAndWarnings(
      us_weekCompletedTasks,
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData,
    )
    errorCount += teamCompositionErrorsAndWarningsData['errorCount']
    // Final Data Object to Return
    payrollCalculatedData = {
      payroll_data: {
        ad_hoc_tasks: adHocTasksList,
        distance_adder_user_data: getProp(distanceAdderCalculationObjects, 'distanceAdderUserData', {}),
        employee_full_payroll_data: employeeFullPayrollData,
        error_and_warning_totals: {
          total_error_count_cached_projects: projectMappingErrorsAndWarnings['errorCount'],
          total_error_count_project_mapping: projectFieldsErrorsAndWarningsData['errorCount'],
          total_error_count_punch_data: punchDataErrorsAndWarningsData['errorCount'],
          total_error_count_qa_tasks: qaAdderErrorsAndWarningsData['errorCount'],
          total_error_count_tasks_missing_unit_mapping_data: tasksMissingUnitMappingDataErrorsAndWarnings['errorCount'],
          total_error_count_team_composition: teamCompositionErrorsAndWarningsData['errorCount'],
          total_error_count_unit_pay: unitPayErrorsAndWarningsData['errorCount'],
          total_error_count_user_roles: Object.keys(userRoleErrors).length,
          total_error_counts: errorCount,
          total_info_count_v1_tasks: Object.keys(v1Tasks).length,
          total_warning_count_distance_adders: distanceAdderErrorsAndWarningsData['warningCount'],
          total_warning_count_project_mapping: projectFieldsErrorsAndWarningsData['warningCount'],
          total_warning_count_punch_data: punchDataErrorsAndWarningsData['warningCount'],
          total_warning_count_unit_pay: unitPayErrorsAndWarningsData['warningCount'],
          total_warning_counts: warningCount,
        },
        errors_project_mapping_data: projectFieldsErrorsAndWarningsData['projectMissingFieldErrors'],
        errors_punch_data: getProp(us_aggregatedWeekPunchData, 'punchDataErrors', {}),
        errors_tasks_missing_unit_mapping_data: workflowTasksMissingUnitMappingData,
        errors_team_composition_correction_requests: teamCompositionErrorsAndWarningsData['teamCompositionErrors'],
        errors_unit_pay: unitPayErrorsAndWarningsData['unitPayErrors'],
        errors_unresolved_qa_tasks: qaAdderErrorsAndWarningsData['qaTaskErrors'],
        errors_user_roles: userRoleErrors,
        scheduled_dates_included_in_payroll: distanceAdderCalculationObjects.daysForUnitPay,
        tasks_with_custom_unit_mapping: tasksThatNeedCustomUnitMappingData,
        unit_pay_tasks: unitPayTasks,
        v1_tasks: v1Tasks,
        warnings_distance_adders: distanceAdderErrorsAndWarningsData['distanceAdderWarnings'],
        warnings_project_mapping_data: projectFieldsErrorsAndWarningsData['projectMissingFieldWarnings'],
        warnings_punch_data: getProp(us_aggregatedWeekPunchData, 'punchDataWarnings', {}),
        warnings_unit_pay: unitPayErrorsAndWarningsData['unitPayWarnings'],
      },
      leaderboard_data: leaderboardData,
      leaderboard_referral_data: referralLeaderboardData,
      tasks_missing_cached_projects: tasksMissingCachedProjects,
      leaderboard_user_roles: leaderboardUserRoles,
    }
  }
  // Return Data
  return payrollCalculatedData
}

const generateWeekDates = (startDate: string) => {
  const result: TsInterface_UnspecifiedObject = {}
  const currentDate = new Date(startDate)
  for (let i = 0; i < 7; i++) {
    const dateString = currentDate.toISOString().split('T')[0]
    result[dateString] = true
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return result
}

const findLatestDate = (dateArray: string[]) => {
  // Initialize with the first date
  let latestDate = dateArray[0]
  // Loop through the array starting from the second date
  for (let i = 1; i < dateArray.length; i++) {
    // Compare the current date with the latest date found so far
    if (dateArray[i] > latestDate) {
      // If the current date is later, update the latestDate variable
      latestDate = dateArray[i]
    }
  }
  // Return the latest date
  return latestDate
}

const calculateDistanceAddersForUnitPayTasks = (
  us_mondayDateKey: string,
  unitPayTasks: TsInterface_UnspecifiedObject,
  employeeFullPayrollData: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject => {
  let distanceAdderUserData: TsInterface_UnspecifiedObject = {}
  let daysForUnitPay = generateWeekDates(us_mondayDateKey)
  let tasksDisqualifiedForDistanceAdders: TsInterface_UnspecifiedObject = {}
  // Update unitPayTasks variable
  for (let loopTaskKey in unitPayTasks) {
    let loopTask = unitPayTasks[loopTaskKey]
    // Determine day that this task is completed
    if (loopTask != null && loopTask.task_completion_scheduled_dates != null && loopTask.unit_pay_totals != null) {
      let dateCompleted = findLatestDate(loopTask.task_completion_scheduled_dates)
      // Task must be completed during the week to be considered for distance adders
      if (daysForUnitPay[dateCompleted] === true) {
        for (let loopUserKey in loopTask.unit_pay_totals) {
          let loopUserData = loopTask.unit_pay_totals[loopUserKey]
          // If user hasn't been added to the distanceAdderUserData object, add them
          if (distanceAdderUserData[loopUserKey] == null) {
            distanceAdderUserData[loopUserKey] = {
              key: loopUserKey,
              name: getProp(loopUserData, 'name', null),
              dates: {},
            }
            for (let loopDateKey in daysForUnitPay) {
              distanceAdderUserData[loopUserKey]['dates'][loopDateKey] = {}
            }
          }
          if (loopUserData['possible_distance_units'] != null && loopUserData['possible_distance_units'] > 0) {
            // Add all possible distance adders to the distanceAdderUserData object
            distanceAdderUserData[loopUserKey]['dates'][dateCompleted][loopTaskKey] = {
              selected_distance_adder_task: false,
              possible_distance_pay: loopUserData['possible_distance_pay'],
              possible_distance_units: loopUserData['possible_distance_units'],
              key: loopTaskKey,
              associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
              associated_team_name: getProp(loopTask, 'associated_team_name', null),
              name: getProp(loopTask, 'name', null),
            }
          }
        }
      } else {
        let taskHasPossibleDistancePay = false
        for (let loopUserKey in loopTask.unit_pay_totals) {
          let loopUserData = loopTask.unit_pay_totals[loopUserKey]
          if (loopUserData['possible_distance_units'] != null && loopUserData['possible_distance_units'] > 0) {
            taskHasPossibleDistancePay = true
          }
        }
        if (taskHasPossibleDistancePay === true) {
          tasksDisqualifiedForDistanceAdders[loopTaskKey] = {
            associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
            associated_team_name: getProp(loopTask, 'associated_team_name', null),
            key: loopTaskKey,
            name: getProp(loopTask, 'name', null),
            task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', []),
            timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
            unit_pay_totals: getProp(loopTask, 'unit_pay_totals', {}),
            warnings: {
              date_not_in_week: 'The last scheduled date is not during this week',
              // "no_adder": "This task will not be considered for distance adders"
              no_adder: 'Manual adjustment required',
            },
          }
        }
      }
    }
  }
  for (let loopUserKey in distanceAdderUserData) {
    let loopUser = distanceAdderUserData[loopUserKey]
    // Loop through each day
    for (let loopDateKey in loopUser['dates']) {
      let loopDate = loopUser['dates'][loopDateKey]
      let maxDistanceAdderPay = 0
      let selectedDistanceAdderTask = null
      // Loop through each task
      for (let loopTaskKey in loopDate) {
        let loopTask = loopDate[loopTaskKey]
        if (loopTask['possible_distance_pay'] > maxDistanceAdderPay) {
          maxDistanceAdderPay = loopTask['possible_distance_pay']
          selectedDistanceAdderTask = loopTaskKey
        }
      }
      // Update the user's distance adder data with the selected task
      if (selectedDistanceAdderTask !== null) {
        loopDate[selectedDistanceAdderTask]['selected_distance_adder_task'] = true
        // Update Unit Pay Tasks Data
        if (
          unitPayTasks != null &&
          unitPayTasks[selectedDistanceAdderTask] != null &&
          unitPayTasks[selectedDistanceAdderTask]['unit_pay_totals'] != null &&
          unitPayTasks[selectedDistanceAdderTask]['unit_pay_totals'][loopUserKey] != null
        ) {
          let distanceUnits = 0
          let distancePay = 0
          if (unitPayTasks[selectedDistanceAdderTask]['unit_pay_totals'][loopUserKey]['possible_distance_units'] != null) {
            distanceUnits = unitPayTasks[selectedDistanceAdderTask]['unit_pay_totals'][loopUserKey]['possible_distance_units']
          }
          if (unitPayTasks[selectedDistanceAdderTask]['unit_pay_totals'][loopUserKey]['possible_distance_pay'] != null) {
            distancePay = unitPayTasks[selectedDistanceAdderTask]['unit_pay_totals'][loopUserKey]['possible_distance_pay']
          }
          unitPayTasks[selectedDistanceAdderTask]['unit_pay_totals'][loopUserKey]['distance_units'] += distanceUnits
          unitPayTasks[selectedDistanceAdderTask]['unit_pay_totals'][loopUserKey]['total_unit_pay'] += distancePay
          unitPayTasks[selectedDistanceAdderTask]['unit_pay_totals'][loopUserKey]['total_units'] += distanceUnits
        }
      }
    }
  }
  // Add Other Users
  for (let loopUserKey in employeeFullPayrollData) {
    if (loopUserKey !== 'undefined' && distanceAdderUserData[loopUserKey] == null) {
      distanceAdderUserData[loopUserKey] = {
        key: loopUserKey,
        name: getProp(employeeFullPayrollData[loopUserKey], 'name', null),
        dates: {},
      }
      for (let loopDateKey in daysForUnitPay) {
        distanceAdderUserData[loopUserKey]['dates'][loopDateKey] = {}
      }
    }
  }
  return {
    daysForUnitPay: daysForUnitPay,
    distanceAdderUserData: distanceAdderUserData,
    tasksDisqualifiedForDistanceAdders: tasksDisqualifiedForDistanceAdders,
  }
}

const returnUnitPayForTask = (
  task: TsInterface_UnspecifiedObject,
  taskUnits: number,
  unitWorkType: string,
  unitPayData: TsInterface_UnspecifiedObject,
  cachedProjectData: TsInterface_UnspecifiedObject,
  payrollRatesData: TsInterface_UnspecifiedObject,
  us_finalizedWeekPayroll_CustomMappingData: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject => {
  let cachedCancelledTasks: TsInterface_UnspecifiedObject = getProp(us_finalizedWeekPayroll_CustomMappingData, 'cached_cancelled_task_data', {})
  let unitPayResult: TsInterface_UnspecifiedObject = {
    unit_pay_totals: {},
    errors: {},
    warnings: {},
    unit_pay_required_project_fields: {},
    associated_project_key: getProp(task, 'associated_project_key', null),
  }
  // Get Project
  let associatedProject: TsInterface_UnspecifiedObject = {}
  if (task != null && task['associated_project_key'] != null && cachedProjectData != null && cachedProjectData[task['associated_project_key']] != null) {
    associatedProject = cachedProjectData[task['associated_project_key']]
  }
  // Get Last Day Team Composition
  let lastScheduledWorkDay = getLastChronologicalDate(task.task_completion_scheduled_dates)
  let lastDayTeamComposition: TsInterface_UnspecifiedObject = {}
  if (lastScheduledWorkDay === null) {
    unitPayResult['errors']['missing_last_scheduled_work_day'] = 'Missing Last Scheduled Work Day'
  } else if (
    lastScheduledWorkDay != null &&
    task != null &&
    task.task_completion_scheduled_team_keys != null &&
    task.task_completion_scheduled_team_keys[lastScheduledWorkDay] != null &&
    task.task_completion_scheduled_team_roles != null &&
    task.task_completion_scheduled_team_roles[lastScheduledWorkDay] != null
  ) {
    for (let loopUserKey in task.task_completion_scheduled_team_keys[lastScheduledWorkDay]) {
      if (task.task_completion_scheduled_team_roles[lastScheduledWorkDay][loopUserKey] == null) {
        unitPayResult['errors']['missing_last_scheduled_work_day_team_roles'] = 'Missing Last Scheduled Work Day Team Roles'
      }
      // Create Last Day Team Composition
      lastDayTeamComposition[loopUserKey] = {
        key: loopUserKey,
        user_role: task.task_completion_scheduled_team_roles[lastScheduledWorkDay][loopUserKey],
      }
    }
    // Check against other days
    let hasOtherTeamMembers = false
    let teamMembersChangedRoles = false
    for (let loopDateKey in task.task_completion_scheduled_team_roles) {
      let loopDate = task.task_completion_scheduled_team_roles[loopDateKey]
      if (loopDateKey !== lastScheduledWorkDay) {
        for (let loopUserKey in loopDate) {
          if (lastDayTeamComposition[loopUserKey] == null) {
            if (
              task != null &&
              task.task_completion_scheduled_team_keys != null &&
              task.task_completion_scheduled_team_keys[loopDateKey] != null &&
              task.task_completion_scheduled_team_keys[loopDateKey][loopUserKey] != null
            ) {
              hasOtherTeamMembers = true
            }
          }
          if (lastDayTeamComposition[loopUserKey] != null && loopDate[loopUserKey] !== lastDayTeamComposition[loopUserKey]['user_role']) {
            if (
              task != null &&
              task.task_completion_scheduled_team_keys != null &&
              task.task_completion_scheduled_team_keys[loopDateKey] != null &&
              task.task_completion_scheduled_team_keys[loopDateKey][loopUserKey] != null
            ) {
              teamMembersChangedRoles = true
            }
          }
        }
        for (let loopUserKey in lastDayTeamComposition) {
          if (loopDate[loopUserKey] == null) {
            if (
              task != null &&
              task.task_completion_scheduled_team_keys != null &&
              task.task_completion_scheduled_team_keys[loopDateKey] != null &&
              task.task_completion_scheduled_team_keys[loopDateKey][loopUserKey] != null
            ) {
              hasOtherTeamMembers = true
            }
          } else if (lastDayTeamComposition[loopUserKey] != null && loopDate[loopUserKey] !== lastDayTeamComposition[loopUserKey]['user_role']) {
            if (
              task != null &&
              task.task_completion_scheduled_team_keys != null &&
              task.task_completion_scheduled_team_keys[loopDateKey] != null &&
              task.task_completion_scheduled_team_keys[loopDateKey][loopUserKey] != null
            ) {
              teamMembersChangedRoles = true
            }
          }
        }
      }
    }
    // Invariant Team Composition Errors
    if (hasOtherTeamMembers === true) {
      unitPayResult['warnings']['different_team_members_on_different_days'] = 'Different Team Members on Different Days'
    }
    if (teamMembersChangedRoles === true) {
      unitPayResult['warnings']['different_team_members_changed_roles_on_different_days'] = 'Different Team Members Changed Roles on Different Days'
    }
  } else {
    if (task == null || task.task_completion_scheduled_team_keys == null || task.task_completion_scheduled_team_keys[lastScheduledWorkDay] == null) {
      unitPayResult['errors']['missing_last_scheduled_work_day_team_keys'] = 'Missing Last Scheduled Work Day Team Keys'
    }
    if (task == null || task.task_completion_scheduled_team_roles == null || task.task_completion_scheduled_team_roles[lastScheduledWorkDay] == null) {
      unitPayResult['errors']['missing_last_scheduled_work_day_team_roles'] = 'Missing Last Scheduled Work Day Team Roles'
    }
  }
  // Check for other invariant team compositions
  let teamMemberCount = Object.keys(lastDayTeamComposition).length
  let foundForeman = false
  let foundLead = false
  let foundHelper1 = false
  let foundHelper2 = false
  let multipleForeman = false
  let multipleLead = false
  let cleanTeamComposition: TsInterface_UnspecifiedObject = {}
  for (let loopUserKey in lastDayTeamComposition) {
    let loopUser = lastDayTeamComposition[loopUserKey]
    if (
      loopUser['user_role'] !== 'install_foreman' &&
      loopUser['user_role'] !== 'install_lead' &&
      loopUser['user_role'] !== 'installer' &&
      loopUser['user_role'] !== 'sow_foreman' &&
      loopUser['user_role'] !== 'sow_helper'
    ) {
      unitPayResult['errors']['invalid_user_role'] = 'Invalid User Role'
    }
    if (loopUser['user_role'] === 'sow_foreman' || loopUser['user_role'] === 'install_foreman') {
      if (foundForeman === true) {
        multipleForeman = true
      }
      foundForeman = true
      cleanTeamComposition['foreman'] = loopUser['key']
    }
    if (loopUser['user_role'] === 'sow_lead' || loopUser['user_role'] === 'install_lead') {
      if (foundLead === true) {
        multipleLead = true
      }
      foundLead = true
      cleanTeamComposition['lead'] = loopUser['key']
    }
    if (loopUser['user_role'] === 'installer' || loopUser['user_role'] === 'sow_helper') {
      if (foundHelper1 === true) {
        foundHelper2 = true
        cleanTeamComposition['helper2'] = loopUser['key']
      } else {
        cleanTeamComposition['helper1'] = loopUser['key']
      }
      foundHelper1 = true
    }
  }
  // Empty Team
  if (teamMemberCount === 0) {
    unitPayResult['errors']['no_team_members'] = 'No Team Members'
  }
  if (teamMemberCount > 4) {
    unitPayResult['errors']['too_many_team_members'] = 'Too Many Team Members'
  }
  // Duplicate Roles on Last Day
  if (multipleForeman === true) {
    unitPayResult['errors']['multiple_foreman_roles'] = 'Multiple Foreman Roles'
  }
  if (multipleLead === true) {
    unitPayResult['errors']['multiple_lead_roles'] = 'Multiple Lead Roles'
  }
  // Missing Foreman
  if (foundForeman === false) {
    unitPayResult['errors']['missing_foreman_user_role'] = 'Missing Foreman User Role'
  }
  // Missing Lead
  if (teamMemberCount > 2 && foundLead === false) {
    unitPayResult['errors']['missing_lead_user_role'] = 'Missing Lead User Role'
  }
  if (teamMemberCount > 2 && foundHelper1 === false) {
    unitPayResult['errors']['missing_helper_1_user_role'] = 'Missing Helper 1 User Role'
  }
  if (teamMemberCount > 3 && foundHelper2 === false) {
    unitPayResult['errors']['missing_helper_2_user_role'] = 'Missing Helper 2 User Role'
  }
  // Clean 2 Person Team
  if (teamMemberCount === 2) {
    if (foundHelper1 === true && foundLead === false) {
      cleanTeamComposition['lead'] = cleanTeamComposition['helper1']
      delete cleanTeamComposition['helper1']
    }
  }
  // Get Region Key
  let regionKey = 'DEFAULT'
  if (
    cachedProjectData != null &&
    task != null &&
    task.associated_project_key != null &&
    cachedProjectData[task.associated_project_key] != null &&
    cachedProjectData[task.associated_project_key]['associated_region_key'] != null
  ) {
    regionKey = cachedProjectData[task.associated_project_key]['associated_region_key']
  } else if (cachedProjectData != null && task != null && task.associated_project_key != null && cachedProjectData[task.associated_project_key] != null) {
    unitPayResult['warnings']['project_missing_associated_region_key'] = 'Project Missing Associated Region Key'
  } else {
    unitPayResult['errors']['project_data_not_cached'] = 'Project Data Not Cached'
  }
  if (task != null && task.redo_and_retry_attempts != null) {
    let previousScheduledDates: string[] = []
    let cancelledTasksPrecedingThisTask: TsInterface_UnspecifiedObject = {}
    for (let loopCancelledTaskKey in cachedCancelledTasks) {
      let loopCancelledTask = cachedCancelledTasks[loopCancelledTaskKey]
      if (
        getProp(loopCancelledTask, 'associated_task_key', null) === task.key ||
        getProp(loopCancelledTask, 'associated_project_key', '') + '_' + getProp(loopCancelledTask, 'associated_task_key', '') === task.key
      ) {
        cancelledTasksPrecedingThisTask[loopCancelledTaskKey] = loopCancelledTask
        for (let loopDateIndex in loopCancelledTask.task_completion_scheduled_dates) {
          let loopDateKey = loopCancelledTask.task_completion_scheduled_dates[loopDateIndex]
          previousScheduledDates.push(loopDateKey)
        }
      }
    }
    if (previousScheduledDates.length > 0) {
      unitPayResult['warnings']['has_redo_and_retry_attempts'] = 'This task has previous redo and retry attempts: ' + previousScheduledDates.join(', ')
    } else {
      unitPayResult['warnings']['has_redo_and_retry_attempts'] = 'This task has previous redo and retry attempts'
    }
    unitPayResult['previous_redo_and_retry_tasks'] = cancelledTasksPrecedingThisTask
  }
  // Get Unit Pay Data
  let foundMatchingForemanRate = false
  let matchingForemanRate = null
  let foundMatchingLeadRate = false
  let matchingLeadRate = null
  let foundMatchingHelper1Rate = false
  let matchingHelper1Rate = null
  let foundMatchingHelper2Rate = false
  let matchingHelper2Rate = null
  if (
    unitWorkType != null &&
    unitPayData != null &&
    unitPayData[regionKey] != null &&
    unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee'] != null &&
    unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee']['foreman'] != null &&
    !isNaN(unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee']['foreman'])
  ) {
    foundMatchingForemanRate = true
    matchingForemanRate = unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee']['foreman']
  } else if (
    unitWorkType != null &&
    unitPayData != null &&
    unitPayData['DEFAULT'] != null &&
    unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee'] != null &&
    unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee']['foreman'] != null &&
    !isNaN(unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee']['foreman'])
  ) {
    foundMatchingForemanRate = true
    matchingForemanRate = unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee']['foreman']
  }
  if (
    unitWorkType != null &&
    unitPayData != null &&
    unitPayData[regionKey] != null &&
    unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee'] != null &&
    unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee']['lead'] != null &&
    !isNaN(unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee']['lead'])
  ) {
    foundMatchingLeadRate = true
    matchingLeadRate = unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee']['lead']
  } else if (
    unitWorkType != null &&
    unitPayData != null &&
    unitPayData['DEFAULT'] != null &&
    unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee'] != null &&
    unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee']['lead'] != null &&
    !isNaN(unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee']['lead'])
  ) {
    foundMatchingLeadRate = true
    matchingLeadRate = unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee']['lead']
  }
  if (
    unitWorkType != null &&
    unitPayData != null &&
    unitPayData[regionKey] != null &&
    unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee'] != null &&
    unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee']['helper1'] != null &&
    !isNaN(unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee']['helper1'])
  ) {
    foundMatchingHelper1Rate = true
    matchingHelper1Rate = unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee']['helper1']
  } else if (
    unitWorkType != null &&
    unitPayData != null &&
    unitPayData['DEFAULT'] != null &&
    unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee'] != null &&
    unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee']['helper1'] != null &&
    !isNaN(unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee']['helper1'])
  ) {
    foundMatchingHelper1Rate = true
    matchingHelper1Rate = unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee']['helper1']
  }
  if (
    unitWorkType != null &&
    unitPayData != null &&
    unitPayData[regionKey] != null &&
    unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee'] != null &&
    unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee']['helper2'] != null &&
    !isNaN(unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee']['helper2'])
  ) {
    foundMatchingHelper2Rate = true
    matchingHelper2Rate = unitPayData[regionKey][unitWorkType + '_' + teamMemberCount + '_employee']['helper2']
  } else if (
    unitWorkType != null &&
    unitPayData != null &&
    unitPayData['DEFAULT'] != null &&
    unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee'] != null &&
    unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee']['helper2'] != null &&
    !isNaN(unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee']['helper2'])
  ) {
    foundMatchingHelper2Rate = true
    matchingHelper2Rate = unitPayData['DEFAULT'][unitWorkType + '_' + teamMemberCount + '_employee']['helper2']
  }
  ///////////////////////////////
  // Calculate Adders
  ///////////////////////////////
  let adderRelatedFields: TsInterface_UnspecifiedObject = {}
  let distanceUnits = 0
  let pitchUnits = 0
  let batteryUnits = 0
  // Variables used to calculate adders
  let distanceFromWarehouse: null | number = null
  if (
    associatedProject != null &&
    associatedProject['location_distance_from_warehouse'] != null &&
    associatedProject['location_distance_from_warehouse'] !== '' &&
    !isNaN(associatedProject['location_distance_from_warehouse'])
  ) {
    distanceFromWarehouse = associatedProject['location_distance_from_warehouse']
  }
  let systemMaxRoofPitch: null | number = null
  if (
    associatedProject != null &&
    associatedProject['system_max_roof_pitch'] != null &&
    associatedProject['system_max_roof_pitch'] !== '' &&
    !isNaN(associatedProject['system_max_roof_pitch'])
  ) {
    systemMaxRoofPitch = associatedProject['system_max_roof_pitch']
  }
  let systemStorageQuantity: null | number = null
  if (associatedProject != null && associatedProject['system_storage_quantity'] != null && associatedProject['system_storage_quantity'] !== '') {
    systemStorageQuantity = associatedProject['system_storage_quantity']
  }
  adderRelatedFields['distance_from_warehouse'] = distanceFromWarehouse
  adderRelatedFields['system_max_roof_pitch'] = systemMaxRoofPitch
  adderRelatedFields['system_storage_quantity'] = systemStorageQuantity
  // Actual Adder Calculation
  if (payrollRatesData != null && payrollRatesData['region_adders'] != null && payrollRatesData['region_adders'][regionKey] != null) {
    // Distance Adder
    if (payrollRatesData['region_adders'][regionKey]['mileage_cutoffs_' + unitWorkType] != null) {
      unitPayResult['unit_pay_required_project_fields']['location_distance_from_warehouse'] =
        itemsToCacheFromProjects['location_distance_from_warehouse']['name']
      if (distanceFromWarehouse != null) {
        let sortedCutoffArray = objectToArray(payrollRatesData['region_adders'][regionKey]['mileage_cutoffs_' + unitWorkType]).sort(
          dynamicSort('cutoff_miles', null),
        )
        for (let loopCutoffIndex in sortedCutoffArray) {
          let loopCutoff = sortedCutoffArray[loopCutoffIndex]
          if (distanceFromWarehouse >= loopCutoff['cutoff_miles'] && loopCutoff['units'] != null && !isNaN(loopCutoff['units'])) {
            distanceUnits = loopCutoff['units']
          }
        }
      }
    }
    // Pitch Adder
    if (payrollRatesData['region_adders'][regionKey]['pitch_cutoffs_' + unitWorkType] != null) {
      unitPayResult['unit_pay_required_project_fields']['system_max_roof_pitch'] = itemsToCacheFromProjects['system_max_roof_pitch']['name']
      if (systemMaxRoofPitch != null) {
        let sortedCutoffArray = objectToArray(payrollRatesData['region_adders'][regionKey]['pitch_cutoffs_' + unitWorkType]).sort(
          dynamicSort('cutoff_pitch', null),
        )
        for (let loopCutoffIndex in sortedCutoffArray) {
          let loopCutoff = sortedCutoffArray[loopCutoffIndex]
          if (systemMaxRoofPitch >= loopCutoff['cutoff_pitch'] && loopCutoff['units'] != null && !isNaN(loopCutoff['units'])) {
            pitchUnits = loopCutoff['units']
          }
        }
      }
    }
    // Battery Adder
    if (payrollRatesData['region_adders'][regionKey]['battery_cutoffs_' + unitWorkType] != null) {
      unitPayResult['unit_pay_required_project_fields']['system_storage_quantity'] = itemsToCacheFromProjects['system_storage_quantity']['name']
      if (systemStorageQuantity != null) {
        let sortedCutoffArray = objectToArray(payrollRatesData['region_adders'][regionKey]['battery_cutoffs_' + unitWorkType]).sort(
          dynamicSort('cutoff_battery', null),
        )
        for (let loopCutoffIndex in sortedCutoffArray) {
          let loopCutoff = sortedCutoffArray[loopCutoffIndex]
          if (systemStorageQuantity >= loopCutoff['cutoff_battery'] && loopCutoff['units'] != null && !isNaN(loopCutoff['units'])) {
            batteryUnits = loopCutoff['units']
          }
        }
      }
    }
  }
  // Sum of all units
  // let totalUnits = taskUnits + distanceUnits + pitchUnits + batteryUnits
  let totalUnits = taskUnits + pitchUnits + batteryUnits
  // Assign the correct rates to the correct users
  if (!isNaN(taskUnits)) {
    if (teamMemberCount >= 1) {
      if (foundMatchingForemanRate === true) {
        unitPayResult['unit_pay_totals'][cleanTeamComposition['foreman']] = {
          adder_related_fields: adderRelatedFields,
          battery_units: batteryUnits,
          distance_units: 0,
          possible_distance_units: distanceUnits,
          possible_distance_pay: matchingForemanRate * distanceUnits,
          pitch_units: pitchUnits,
          rate: matchingForemanRate,
          task_units: taskUnits,
          team_role: 'foreman',
          total_unit_pay: matchingForemanRate * totalUnits,
          total_units: totalUnits,
        }
        if (
          lastScheduledWorkDay != null &&
          cleanTeamComposition != null &&
          cleanTeamComposition['foreman'] != null &&
          task != null &&
          task.task_completion_scheduled_team_names != null &&
          task.task_completion_scheduled_team_names[lastScheduledWorkDay] != null &&
          task.task_completion_scheduled_team_names[lastScheduledWorkDay][cleanTeamComposition['foreman']] != null
        ) {
          unitPayResult['unit_pay_totals'][cleanTeamComposition['foreman']]['name'] =
            task.task_completion_scheduled_team_names[lastScheduledWorkDay][cleanTeamComposition['foreman']]
        }
      } else {
        unitPayResult['errors']['missing_matching_foreman_rate'] = 'Missing Matching Foreman Rate'
      }
    }
    if (teamMemberCount >= 2) {
      if (foundMatchingLeadRate === true) {
        unitPayResult['unit_pay_totals'][cleanTeamComposition['lead']] = {
          adder_related_fields: adderRelatedFields,
          battery_units: batteryUnits,
          distance_units: 0,
          possible_distance_units: distanceUnits,
          possible_distance_pay: matchingLeadRate * distanceUnits,
          pitch_units: pitchUnits,
          rate: matchingLeadRate,
          task_units: taskUnits,
          team_role: 'lead',
          total_unit_pay: matchingLeadRate * totalUnits,
          total_units: totalUnits,
        }
        if (
          lastScheduledWorkDay != null &&
          cleanTeamComposition != null &&
          cleanTeamComposition['lead'] != null &&
          task != null &&
          task.task_completion_scheduled_team_names != null &&
          task.task_completion_scheduled_team_names[lastScheduledWorkDay] != null &&
          task.task_completion_scheduled_team_names[lastScheduledWorkDay][cleanTeamComposition['lead']] != null
        ) {
          unitPayResult['unit_pay_totals'][cleanTeamComposition['lead']]['name'] =
            task.task_completion_scheduled_team_names[lastScheduledWorkDay][cleanTeamComposition['lead']]
        }
      } else {
        unitPayResult['errors']['missing_matching_lead_rate'] = 'Missing Matching Lead Rate'
      }
    }
    if (teamMemberCount >= 3) {
      if (foundMatchingHelper1Rate === true) {
        unitPayResult['unit_pay_totals'][cleanTeamComposition['helper1']] = {
          adder_related_fields: adderRelatedFields,
          battery_units: batteryUnits,
          distance_units: 0,
          possible_distance_units: distanceUnits,
          possible_distance_pay: matchingHelper1Rate * distanceUnits,
          pitch_units: pitchUnits,
          rate: matchingHelper1Rate,
          task_units: taskUnits,
          team_role: 'helper1',
          total_unit_pay: matchingHelper1Rate * totalUnits,
          total_units: totalUnits,
        }
        if (
          lastScheduledWorkDay != null &&
          cleanTeamComposition != null &&
          cleanTeamComposition['helper1'] != null &&
          task != null &&
          task.task_completion_scheduled_team_names != null &&
          task.task_completion_scheduled_team_names[lastScheduledWorkDay] != null &&
          task.task_completion_scheduled_team_names[lastScheduledWorkDay][cleanTeamComposition['helper1']] != null
        ) {
          unitPayResult['unit_pay_totals'][cleanTeamComposition['helper1']]['name'] =
            task.task_completion_scheduled_team_names[lastScheduledWorkDay][cleanTeamComposition['helper1']]
        }
      } else {
        unitPayResult['errors']['missing_matching_helper_1_rate'] = 'Missing Matching Helper 1 Rate'
      }
    }
    if (teamMemberCount >= 4) {
      if (foundMatchingHelper2Rate === true) {
        unitPayResult['unit_pay_totals'][cleanTeamComposition['helper2']] = {
          adder_related_fields: adderRelatedFields,
          battery_units: batteryUnits,
          distance_units: 0,
          possible_distance_units: distanceUnits,
          possible_distance_pay: matchingHelper2Rate * distanceUnits,
          pitch_units: pitchUnits,
          rate: matchingHelper2Rate,
          task_units: taskUnits,
          team_role: 'helper2',
          total_unit_pay: matchingHelper2Rate * totalUnits,
          total_units: totalUnits,
        }
        if (
          lastScheduledWorkDay != null &&
          cleanTeamComposition != null &&
          cleanTeamComposition['helper2'] != null &&
          task != null &&
          task.task_completion_scheduled_team_names != null &&
          task.task_completion_scheduled_team_names[lastScheduledWorkDay] != null &&
          task.task_completion_scheduled_team_names[lastScheduledWorkDay][cleanTeamComposition['helper2']] != null
        ) {
          unitPayResult['unit_pay_totals'][cleanTeamComposition['helper2']]['name'] =
            task.task_completion_scheduled_team_names[lastScheduledWorkDay][cleanTeamComposition['helper2']]
        }
      } else {
        unitPayResult['errors']['missing_matching_helper_2_rate'] = 'Missing Matching Helper 2 Rate'
      }
    }
  }
  return unitPayResult
}

const classifyTasksForUnitPay = (us_weekCompletedTasks: TsInterface_UnspecifiedObject, us_payrollRatesData: TsInterface_UnspecifiedObject) => {
  // Instantiate Variables
  let adHocTasksList: TsInterface_UnspecifiedObject = {}
  let tasksThatNeedCustomUnitMappingData: TsInterface_UnspecifiedObject = {}
  let tasksThatNeedPanelCountsForUnitMappingData: TsInterface_UnspecifiedObject = {}
  let tasksWithHardcodeUnitMappingData: TsInterface_UnspecifiedObject = {}
  let workflowTasksMissingUnitMappingData: TsInterface_UnspecifiedObject = {}
  let tasksMissingUnitMappingData: TsInterface_UnspecifiedObject = {}
  let v1Tasks: TsInterface_UnspecifiedObject = {}
  // Loop Through Tasks
  for (let loopTaskKey in us_weekCompletedTasks) {
    let loopTask = us_weekCompletedTasks[loopTaskKey]
    // Skip Tasks Requiring Sales Partner Approval
    if (
      loopTask['sales_partner_approval_required'] != true ||
      (loopTask['sales_partner_approval_required'] == true && loopTask['sales_partner_approval_granted'] == true)
    ) {
      if (loopTask.status !== 'deleted') {
        if (
          loopTask != null &&
          loopTask['associated_task_origin'] === 'ad_hoc'
          // || loopTask["associated_task_origin"] === "sow"
        ) {
          adHocTasksList[loopTask.key] = {
            associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
            associated_project_key: getProp(loopTask, 'associated_project_key', null),
            associated_task_key: loopTask['key'],
            associated_team_name: getProp(loopTask, 'associated_team_name', null),
            exclude_from_unit_pay: getProp(loopTask, 'exclude_from_unit_pay', null),
            key: loopTask.key,
            name: getProp(loopTask, 'name', null),
            redo_and_retry_attempts: getProp(loopTask, 'redo_and_retry_attempts', null),
            task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
            task_completion_scheduled_team_keys: getProp(loopTask, 'task_completion_scheduled_team_keys', null),
            task_completion_scheduled_team_names: getProp(loopTask, 'task_completion_scheduled_team_names', null),
            task_completion_scheduled_team_roles: getProp(loopTask, 'task_completion_scheduled_team_roles', null),
            task_unit_evaluation: 'custom',
            task_units: null,
            timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
            unit_work_type: null,
          }
        } else if (loopTask != null && loopTask['associated_task_key'] != null) {
          let cleanTaskKey = loopTask['associated_task_key']
          if (
            // loopTask
            loopTask['associated_task_origin'] === 'sow' &&
            loopTask['associated_task_key'] != null &&
            loopTask['associated_project_key'] != null &&
            loopTask['associated_task_key'].startsWith(loopTask['associated_project_key'] + '_sow_')
          ) {
            cleanTaskKey = loopTask['associated_task_key'].replace(loopTask['associated_project_key'] + '_sow_', '')
          }
          if (
            loopTask != null &&
            cleanTaskKey != null &&
            us_payrollRatesData != null &&
            us_payrollRatesData['task_unit_mapping'] != null &&
            us_payrollRatesData['task_unit_mapping'][cleanTaskKey] != null
          ) {
            let loopTaskUnitMapping = us_payrollRatesData['task_unit_mapping'][cleanTaskKey]
            // V1 Tasks
            if (loopTaskUnitMapping.workflow_version === 1) {
              v1Tasks[loopTask.key] = {
                associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
                associated_project_key: getProp(loopTask, 'associated_project_key', null),
                associated_task_key: loopTask['key'],
                associated_team_name: getProp(loopTask, 'associated_team_name', null),
                key: cleanTaskKey,
                name: getProp(loopTask, 'name', null),
                redo_and_retry_attempts: getProp(loopTask, 'redo_and_retry_attempts', null),
                task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
                task_completion_scheduled_team_keys: getProp(loopTask, 'task_completion_scheduled_team_keys', null),
                task_completion_scheduled_team_names: getProp(loopTask, 'task_completion_scheduled_team_names', null),
                task_completion_scheduled_team_roles: getProp(loopTask, 'task_completion_scheduled_team_roles', null),
                task_unit_evaluation: 'unknown',
                task_units: null,
                timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
                unit_work_type: null,
              }
            }
            if (loopTaskUnitMapping['task_unit_evaluation'] === 'custom') {
              // Need Custom Unit Mapping Data
              tasksThatNeedCustomUnitMappingData[loopTask.key] = {
                associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
                associated_project_key: getProp(loopTask, 'associated_project_key', null),
                associated_task_key: loopTask['key'],
                associated_team_name: getProp(loopTask, 'associated_team_name', null),
                exclude_from_unit_pay: getProp(loopTask, 'exclude_from_unit_pay', null),
                key: loopTask.key,
                name: getProp(loopTask, 'name', null),
                redo_and_retry_attempts: getProp(loopTask, 'redo_and_retry_attempts', null),
                task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
                task_completion_scheduled_team_keys: getProp(loopTask, 'task_completion_scheduled_team_keys', null),
                task_completion_scheduled_team_names: getProp(loopTask, 'task_completion_scheduled_team_names', null),
                task_completion_scheduled_team_roles: getProp(loopTask, 'task_completion_scheduled_team_roles', null),
                task_unit_evaluation: 'custom',
                task_units: null,
                timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
                unit_work_type: getProp(loopTaskUnitMapping, 'unit_work_type', null),
              }
              if (v1Tasks[loopTask.key] != null) {
                v1Tasks[loopTask.key]['task_unit_evaluation'] = 'custom'
                v1Tasks[loopTask.key]['unit_work_type'] = getProp(loopTaskUnitMapping, 'unit_work_type', null)
              }
            } else if (loopTaskUnitMapping['task_unit_evaluation'] === 'panels') {
              // Need Project Specific Panel Counts
              tasksThatNeedPanelCountsForUnitMappingData[loopTask.key] = {
                associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
                associated_project_key: getProp(loopTask, 'associated_project_key', null),
                associated_task_key: loopTask['key'],
                associated_team_name: getProp(loopTask, 'associated_team_name', null),
                exclude_from_unit_pay: getProp(loopTask, 'exclude_from_unit_pay', null),
                key: loopTask.key,
                name: getProp(loopTask, 'name', null),
                redo_and_retry_attempts: getProp(loopTask, 'redo_and_retry_attempts', null),
                task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
                task_completion_scheduled_team_keys: getProp(loopTask, 'task_completion_scheduled_team_keys', null),
                task_completion_scheduled_team_names: getProp(loopTask, 'task_completion_scheduled_team_names', null),
                task_completion_scheduled_team_roles: getProp(loopTask, 'task_completion_scheduled_team_roles', null),
                task_unit_evaluation: 'panels',
                task_units: null,
                timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
                unit_work_type: getProp(loopTaskUnitMapping, 'unit_work_type', null),
              }
              if (v1Tasks[loopTask.key] != null) {
                v1Tasks[loopTask.key]['task_unit_evaluation'] = 'panels'
                v1Tasks[loopTask.key]['unit_work_type'] = getProp(loopTaskUnitMapping, 'unit_work_type', null)
              }
            } else if (
              loopTaskUnitMapping['task_unit_evaluation'] === 'hardcode' &&
              loopTaskUnitMapping['task_units'] != null &&
              loopTaskUnitMapping['unit_work_type'] != null
            ) {
              // Hardcoded Unit Mapping Data
              tasksWithHardcodeUnitMappingData[loopTask.key] = {
                associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
                associated_project_key: getProp(loopTask, 'associated_project_key', null),
                associated_task_key: loopTask['key'],
                associated_team_name: getProp(loopTask, 'associated_team_name', null),
                exclude_from_unit_pay: getProp(loopTask, 'exclude_from_unit_pay', null),
                key: cleanTaskKey,
                name: getProp(loopTask, 'name', null),
                redo_and_retry_attempts: getProp(loopTask, 'redo_and_retry_attempts', null),
                task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
                task_completion_scheduled_team_keys: getProp(loopTask, 'task_completion_scheduled_team_keys', null),
                task_completion_scheduled_team_names: getProp(loopTask, 'task_completion_scheduled_team_names', null),
                task_completion_scheduled_team_roles: getProp(loopTask, 'task_completion_scheduled_team_roles', null),
                task_unit_evaluation: 'hardcode',
                task_units: getProp(loopTaskUnitMapping, 'task_units', null),
                timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
                unit_work_type: getProp(loopTaskUnitMapping, 'unit_work_type', null),
              }
              if (v1Tasks[loopTask.key] != null) {
                v1Tasks[loopTask.key]['task_unit_evaluation'] = 'hardcode'
                v1Tasks[loopTask.key]['unit_work_type'] = getProp(loopTaskUnitMapping, 'unit_work_type', null)
                v1Tasks[loopTask.key]['task_units'] = getProp(loopTaskUnitMapping, 'task_units', null)
              }
            } else {
              tasksMissingUnitMappingData[loopTask.key] = {
                associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
                associated_project_key: getProp(loopTask, 'associated_project_key', null),
                associated_task_key: loopTask['key'],
                associated_team_name: getProp(loopTask, 'associated_team_name', null),
                exclude_from_unit_pay: getProp(loopTask, 'exclude_from_unit_pay', null),
                key: cleanTaskKey,
                name: getProp(loopTask, 'name', null),
                redo_and_retry_attempts: getProp(loopTask, 'redo_and_retry_attempts', null),
                task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
                task_completion_scheduled_team_keys: getProp(loopTask, 'task_completion_scheduled_team_keys', null),
                task_completion_scheduled_team_names: getProp(loopTask, 'task_completion_scheduled_team_names', null),
                task_completion_scheduled_team_roles: getProp(loopTask, 'task_completion_scheduled_team_roles', null),
                task_unit_evaluation: 'unknown',
                task_units: null,
                timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
                unit_work_type: null,
              }
              if (v1Tasks[loopTask.key] != null) {
                v1Tasks[loopTask.key]['task_unit_evaluation'] = 'unknown'
              }
              if (workflowTasksMissingUnitMappingData[cleanTaskKey] == null) {
                workflowTasksMissingUnitMappingData[cleanTaskKey] = {
                  associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
                  associated_project_id_numbers: {},
                  associated_team_name: getProp(loopTask, 'associated_team_name', null),
                  id_number: getProp(loopTask, 'id_number', null),
                  key: cleanTaskKey,
                  name: getProp(loopTask, 'name', null),
                  task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
                  type: 'no_mapping_type',
                }
              }
              if (
                loopTask['associated_project_id_number'] != null &&
                loopTask['associated_project_id_number'] !== '' &&
                loopTask['associated_project_key'] != null &&
                loopTask['associated_project_key'] !== ''
              ) {
                workflowTasksMissingUnitMappingData[cleanTaskKey]['associated_project_id_numbers'] = {
                  [loopTask['associated_project_id_number']]: {
                    key: loopTask['associated_project_key'],
                    name: loopTask['associated_project_id_number'],
                  },
                }
              }
            }
          } else if (loopTask != null && cleanTaskKey != null) {
            tasksMissingUnitMappingData[loopTask.key] = {
              associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
              associated_project_key: getProp(loopTask, 'associated_project_key', null),
              associated_task_key: loopTask['key'],
              associated_team_name: getProp(loopTask, 'associated_team_name', null),
              exclude_from_unit_pay: getProp(loopTask, 'exclude_from_unit_pay', null),
              key: cleanTaskKey,
              name: getProp(loopTask, 'name', null),
              redo_and_retry_attempts: getProp(loopTask, 'redo_and_retry_attempts', null),
              task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
              task_completion_scheduled_team_keys: getProp(loopTask, 'task_completion_scheduled_team_keys', null),
              task_completion_scheduled_team_names: getProp(loopTask, 'task_completion_scheduled_team_names', null),
              task_completion_scheduled_team_roles: getProp(loopTask, 'task_completion_scheduled_team_roles', null),
              task_unit_evaluation: 'unknown',
              task_units: null,
              timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
              unit_work_type: null,
            }
            if (v1Tasks[loopTask.key] != null) {
              v1Tasks[loopTask.key]['task_unit_evaluation'] = 'unknown'
            }
            if (workflowTasksMissingUnitMappingData[cleanTaskKey] == null) {
              workflowTasksMissingUnitMappingData[cleanTaskKey] = {
                associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
                associated_project_id_numbers: {},
                associated_team_name: getProp(loopTask, 'associated_team_name', null),
                id_number: getProp(loopTask, 'id_number', null),
                key: cleanTaskKey,
                name: getProp(loopTask, 'name', null),
                task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
                type: 'no_mapping',
              }
            }
            if (
              loopTask['associated_project_id_number'] != null &&
              loopTask['associated_project_id_number'] !== '' &&
              loopTask['associated_project_key'] != null &&
              loopTask['associated_project_key'] !== ''
            ) {
              workflowTasksMissingUnitMappingData[cleanTaskKey]['associated_project_id_numbers'] = {
                [loopTask['associated_project_id_number']]: {
                  key: loopTask['associated_project_key'],
                  name: loopTask['associated_project_id_number'],
                },
              }
            }
          }
        }
      }
    }
  }
  // Return Data
  return {
    adHocTasksList: adHocTasksList,
    tasksThatNeedCustomUnitMappingData: tasksThatNeedCustomUnitMappingData,
    tasksThatNeedPanelCountsForUnitMappingData: tasksThatNeedPanelCountsForUnitMappingData,
    tasksWithHardcodeUnitMappingData: tasksWithHardcodeUnitMappingData,
    workflowTasksMissingUnitMappingData: workflowTasksMissingUnitMappingData,
    tasksMissingUnitMappingData: tasksMissingUnitMappingData,
    v1Tasks: v1Tasks,
  }
}

const generateUnitPayTasksDataObject = (
  us_finalizedWeekPayroll_CustomMappingData: TsInterface_UnspecifiedObject,
  us_payrollRatesData: TsInterface_UnspecifiedObject,
  adHocTasksList: TsInterface_UnspecifiedObject,
  tasksThatNeedCustomUnitMappingData: TsInterface_UnspecifiedObject,
  tasksThatNeedPanelCountsForUnitMappingData: TsInterface_UnspecifiedObject,
  tasksWithHardcodeUnitMappingData: TsInterface_UnspecifiedObject,
  tasksMissingUnitMappingData: TsInterface_UnspecifiedObject,
) => {
  let unitPayTasks: TsInterface_UnspecifiedObject = {}
  for (let loopTaskKey in adHocTasksList) {
    let unitPayResult: TsInterface_UnspecifiedObject = {
      unit_pay_totals: {},
      errors: {},
      warnings: {},
    }
    let loopTask = adHocTasksList[loopTaskKey]
    let taskUnits = null
    let unitWorkType = null
    let taskUnitEvaluation = null
    if (
      loopTask != null &&
      us_finalizedWeekPayroll_CustomMappingData != null &&
      us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'] != null &&
      us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey] != null &&
      us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['task_units'] != null &&
      us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['unit_work_type'] != null
    ) {
      taskUnits = us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['task_units']
      unitWorkType = us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['unit_work_type']
      taskUnitEvaluation = getProp(us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey], 'task_unit_evaluation', null)
      unitPayResult = returnUnitPayForTask(
        loopTask,
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['task_units'],
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['unit_work_type'],
        getProp(us_payrollRatesData, 'unit_pay', {}),
        getProp(us_finalizedWeekPayroll_CustomMappingData, 'cached_project_data', {}),
        us_payrollRatesData,
        us_finalizedWeekPayroll_CustomMappingData,
      )
    } else {
      if (
        us_finalizedWeekPayroll_CustomMappingData == null ||
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'] == null ||
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey] == null ||
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['task_units'] == null
      ) {
        unitPayResult['errors']['missing_task_units'] = 'Missing Task Units'
      } else {
        taskUnits = us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['task_units']
      }
      if (
        us_finalizedWeekPayroll_CustomMappingData == null ||
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'] == null ||
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey] == null ||
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['unit_work_type'] == null
      ) {
        unitPayResult['errors']['missing_unit_work_type'] = 'Missing Unit Work Type'
      } else {
        unitWorkType = us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['unit_work_type']
      }
      if (
        loopTask != null &&
        us_finalizedWeekPayroll_CustomMappingData != null &&
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'] != null &&
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey] != null &&
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['task_unit_evaluation'] != null
      ) {
        taskUnitEvaluation = getProp(
          us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey],
          'task_unit_evaluation',
          null,
        )
      }
    }
    // Wipe out unit pay for task if excluded
    if (getProp(loopTask, 'exclude_from_unit_pay', false) === true) {
      unitPayResult = {
        unit_pay_totals: {},
        errors: {},
        warnings: {},
      }
    }
    if (taskUnitEvaluation !== 'panels') {
      taskUnitEvaluation = 'units'
    }
    // Add to All Tasks
    unitPayTasks[loopTaskKey] = {
      associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
      associated_project_key: getProp(loopTask, 'associated_project_key', null),
      associated_task_key: getProp(loopTask, 'associated_task_key', null),
      associated_team_name: getProp(loopTask, 'associated_team_name', null),
      exclude_from_unit_pay: getProp(loopTask, 'exclude_from_unit_pay', null),
      key: loopTask.key,
      name: getProp(loopTask, 'name', null),
      task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
      // task_completion_scheduled_team_keys: getProp( loopTask, "task_completion_scheduled_team_keys", null ),
      task_completion_scheduled_team_names: getProp(loopTask, 'task_completion_scheduled_team_names', null),
      task_completion_scheduled_team_roles: getProp(loopTask, 'task_completion_scheduled_team_roles', null),
      task_units: taskUnits,
      task_unit_evaluation: taskUnitEvaluation,
      timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
      unit_pay_errors: getProp(unitPayResult, 'errors', {}),
      unit_pay_totals: getProp(unitPayResult, 'unit_pay_totals', {}),
      unit_pay_warnings: getProp(unitPayResult, 'warnings', {}),
      unit_pay_required_project_fields: getProp(unitPayResult, 'unit_pay_required_project_fields', {}),
      unit_work_type: unitWorkType,
    }
  }
  for (let loopTaskKey in tasksThatNeedCustomUnitMappingData) {
    let unitPayResult: TsInterface_UnspecifiedObject = {
      unit_pay_totals: {},
      errors: {},
      warnings: {},
    }
    let loopTask = tasksThatNeedCustomUnitMappingData[loopTaskKey]
    let taskUnits = null
    let unitWorkType = null
    let taskUnitEvaluation = null
    if (
      loopTask != null &&
      us_finalizedWeekPayroll_CustomMappingData != null &&
      us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'] != null &&
      us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey] != null &&
      us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['task_units'] != null &&
      us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['unit_work_type'] != null
    ) {
      taskUnits = us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['task_units']
      unitWorkType = us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['unit_work_type']
      taskUnitEvaluation = getProp(us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey], 'task_unit_evaluation', null)
      unitPayResult = returnUnitPayForTask(
        loopTask,
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['task_units'],
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['unit_work_type'],
        getProp(us_payrollRatesData, 'unit_pay', {}),
        getProp(us_finalizedWeekPayroll_CustomMappingData, 'cached_project_data', {}),
        us_payrollRatesData,
        us_finalizedWeekPayroll_CustomMappingData,
      )
    } else {
      if (
        us_finalizedWeekPayroll_CustomMappingData == null ||
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'] == null ||
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey] == null ||
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['task_units'] == null
      ) {
        unitPayResult['errors']['missing_task_units'] = 'Missing Task Units'
      } else {
        taskUnits = us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['task_units']
      }
      if (
        us_finalizedWeekPayroll_CustomMappingData == null ||
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'] == null ||
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey] == null ||
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['unit_work_type'] == null
      ) {
        unitPayResult['errors']['missing_unit_work_type'] = 'Missing Unit Work Type'
      } else {
        unitWorkType = us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['unit_work_type']
      }
      if (
        loopTask != null &&
        us_finalizedWeekPayroll_CustomMappingData != null &&
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'] != null &&
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey] != null &&
        us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey]['task_unit_evaluation'] != null
      ) {
        taskUnitEvaluation = getProp(
          us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][loopTaskKey],
          'task_unit_evaluation',
          null,
        )
      }
    }
    if (taskUnitEvaluation !== 'panels') {
      taskUnitEvaluation = 'units'
    }
    // Wipe out unit pay for task if excluded
    if (getProp(loopTask, 'exclude_from_unit_pay', false) === true) {
      unitPayResult = {
        unit_pay_totals: {},
        errors: {},
        warnings: {},
      }
    }
    // Add to All Tasks
    unitPayTasks[loopTaskKey] = {
      associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
      associated_project_key: getProp(loopTask, 'associated_project_key', null),
      associated_task_key: getProp(loopTask, 'associated_task_key', null),
      associated_team_name: getProp(loopTask, 'associated_team_name', null),
      exclude_from_unit_pay: getProp(loopTask, 'exclude_from_unit_pay', null),
      key: loopTask.key,
      name: getProp(loopTask, 'name', null),
      task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
      // task_completion_scheduled_team_keys: getProp( loopTask, "task_completion_scheduled_team_keys", null ),
      task_completion_scheduled_team_names: getProp(loopTask, 'task_completion_scheduled_team_names', null),
      task_completion_scheduled_team_roles: getProp(loopTask, 'task_completion_scheduled_team_roles', null),
      task_units: taskUnits,
      task_unit_evaluation: taskUnitEvaluation,
      timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
      unit_pay_errors: getProp(unitPayResult, 'errors', {}),
      unit_pay_totals: getProp(unitPayResult, 'unit_pay_totals', {}),
      unit_pay_warnings: getProp(unitPayResult, 'warnings', {}),
      unit_pay_required_project_fields: getProp(unitPayResult, 'unit_pay_required_project_fields', {}),
      unit_work_type: unitWorkType,
    }
  }
  for (let loopTaskKey in tasksThatNeedPanelCountsForUnitMappingData) {
    let unitPayResult: TsInterface_UnspecifiedObject = {
      unit_pay_totals: {},
      errors: {},
      warnings: {},
    }
    let loopTask = tasksThatNeedPanelCountsForUnitMappingData[loopTaskKey]
    let taskUnits = null
    let unitWorkType = null
    if (
      loopTask != null &&
      loopTask['unit_work_type'] != null &&
      loopTask['associated_project_key'] != null &&
      us_finalizedWeekPayroll_CustomMappingData != null &&
      us_finalizedWeekPayroll_CustomMappingData['cached_project_data'] != null &&
      us_finalizedWeekPayroll_CustomMappingData['cached_project_data'][loopTask['associated_project_key']] != null &&
      us_finalizedWeekPayroll_CustomMappingData['cached_project_data'][loopTask['associated_project_key']]['system_panel_quantity'] != null
    ) {
      taskUnits = us_finalizedWeekPayroll_CustomMappingData['cached_project_data'][loopTask['associated_project_key']]['system_panel_quantity']
      unitWorkType = loopTask['unit_work_type']
      unitPayResult = returnUnitPayForTask(
        loopTask,
        us_finalizedWeekPayroll_CustomMappingData['cached_project_data'][loopTask['associated_project_key']]['system_panel_quantity'],
        loopTask['unit_work_type'],
        getProp(us_payrollRatesData, 'unit_pay', {}),
        getProp(us_finalizedWeekPayroll_CustomMappingData, 'cached_project_data', {}),
        us_payrollRatesData,
        us_finalizedWeekPayroll_CustomMappingData,
      )
    } else {
      if (loopTask == null || loopTask['unit_work_type'] == null) {
        unitPayResult['errors']['missing_unit_work_type'] = 'Missing Unit Work Type'
      } else {
        unitWorkType = loopTask['unit_work_type']
      }
      if (
        loopTask == null ||
        loopTask['associated_project_key'] == null ||
        us_finalizedWeekPayroll_CustomMappingData == null ||
        us_finalizedWeekPayroll_CustomMappingData['cached_project_data'] == null ||
        us_finalizedWeekPayroll_CustomMappingData['cached_project_data'][loopTask['associated_project_key']] == null ||
        us_finalizedWeekPayroll_CustomMappingData['cached_project_data'][loopTask['associated_project_key']]['system_panel_quantity'] == null
      ) {
        // Panels
        unitPayResult['errors']['missing_panels_count'] = 'Missing Panels Count'
      } else {
        taskUnits = us_finalizedWeekPayroll_CustomMappingData['cached_project_data'][loopTask['associated_project_key']]['system_panel_quantity']
      }
    }
    // Wipe out unit pay for task if excluded
    if (getProp(loopTask, 'exclude_from_unit_pay', false) === true) {
      unitPayResult = {
        unit_pay_totals: {},
        errors: {},
        warnings: {},
      }
    }
    // Add to All Tasks
    unitPayTasks[loopTaskKey] = {
      associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
      associated_project_key: getProp(loopTask, 'associated_project_key', null),
      associated_task_key: getProp(loopTask, 'associated_task_key', null),
      exclude_from_unit_pay: getProp(loopTask, 'exclude_from_unit_pay', null),
      associated_team_name: getProp(loopTask, 'associated_team_name', null),
      key: loopTask.key,
      name: getProp(loopTask, 'name', null),
      task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
      // task_completion_scheduled_team_keys: getProp( loopTask, "task_completion_scheduled_team_keys", null ),
      task_completion_scheduled_team_names: getProp(loopTask, 'task_completion_scheduled_team_names', null),
      task_completion_scheduled_team_roles: getProp(loopTask, 'task_completion_scheduled_team_roles', null),
      task_units: taskUnits,
      task_unit_evaluation: 'panels',
      timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
      unit_pay_errors: getProp(unitPayResult, 'errors', {}),
      unit_pay_totals: getProp(unitPayResult, 'unit_pay_totals', {}),
      unit_pay_warnings: getProp(unitPayResult, 'warnings', {}),
      unit_pay_required_project_fields: getProp(unitPayResult, 'unit_pay_required_project_fields', {}),
      unit_work_type: unitWorkType,
    }
  }
  for (let loopTaskKey in tasksWithHardcodeUnitMappingData) {
    let unitPayResult: TsInterface_UnspecifiedObject = {
      unit_pay_totals: {},
      errors: {},
      warnings: {},
    }
    let loopTask = tasksWithHardcodeUnitMappingData[loopTaskKey]
    let taskUnits = null
    let unitWorkType = null
    if (loopTask != null && loopTask['task_units'] != null && loopTask['unit_work_type'] != null) {
      taskUnits = loopTask['task_units']
      unitWorkType = loopTask['unit_work_type']
      unitPayResult = returnUnitPayForTask(
        loopTask,
        loopTask['task_units'],
        loopTask['unit_work_type'],
        getProp(us_payrollRatesData, 'unit_pay', {}),
        getProp(us_finalizedWeekPayroll_CustomMappingData, 'cached_project_data', {}),
        us_payrollRatesData,
        us_finalizedWeekPayroll_CustomMappingData,
      )
    } else {
      if (loopTask == null || loopTask['task_units'] == null) {
        unitPayResult['errors']['missing_task_units'] = 'Missing Task Units'
      } else {
        taskUnits = loopTask['task_units']
      }
      if (loopTask == null || loopTask['unit_work_type'] == null) {
        unitPayResult['errors']['missing_unit_work_type'] = 'Missing Unit Work Type'
      } else {
        unitWorkType = loopTask['unit_work_type']
      }
    }
    // Wipe out unit pay for task if excluded
    if (getProp(loopTask, 'exclude_from_unit_pay', false) === true) {
      unitPayResult = {
        unit_pay_totals: {},
        errors: {},
        warnings: {},
      }
    }
    // Add to All Tasks
    unitPayTasks[loopTaskKey] = {
      // task_completion_scheduled_team_keys: getProp( loopTask, "task_completion_scheduled_team_keys", null ),
      associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
      associated_project_key: getProp(loopTask, 'associated_project_key', null),
      associated_task_key: getProp(loopTask, 'associated_task_key', null),
      associated_team_name: getProp(loopTask, 'associated_team_name', null),
      exclude_from_unit_pay: getProp(loopTask, 'exclude_from_unit_pay', null),
      key: loopTask.key,
      name: getProp(loopTask, 'name', null),
      previous_redo_and_retry_tasks: getProp(loopTask, 'previous_redo_and_retry_tasks', null),
      task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
      task_completion_scheduled_team_names: getProp(loopTask, 'task_completion_scheduled_team_names', null),
      task_completion_scheduled_team_roles: getProp(loopTask, 'task_completion_scheduled_team_roles', null),
      task_unit_evaluation: 'units',
      task_units: taskUnits,
      timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
      unit_pay_errors: getProp(unitPayResult, 'errors', {}),
      unit_pay_required_project_fields: getProp(unitPayResult, 'unit_pay_required_project_fields', {}),
      unit_pay_totals: getProp(unitPayResult, 'unit_pay_totals', {}),
      unit_pay_warnings: getProp(unitPayResult, 'warnings', {}),
      unit_work_type: unitWorkType,
    }
  }
  for (let loopTaskKey in tasksMissingUnitMappingData) {
    let loopTask = tasksMissingUnitMappingData[loopTaskKey]
    unitPayTasks[loopTaskKey] = {
      associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
      associated_project_key: getProp(loopTask, 'associated_project_key', null),
      associated_task_key: getProp(loopTask, 'associated_task_key', null),
      associated_team_name: getProp(loopTask, 'associated_team_name', null),
      exclude_from_unit_pay: getProp(loopTask, 'exclude_from_unit_pay', null),
      key: loopTask.key,
      name: getProp(loopTask, 'name', null),
      task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
      // task_completion_scheduled_team_keys: getProp( loopTask, "task_completion_scheduled_team_keys", null ),
      task_completion_scheduled_team_names: getProp(loopTask, 'task_completion_scheduled_team_names', null),
      task_completion_scheduled_team_roles: getProp(loopTask, 'task_completion_scheduled_team_roles', null),
      task_units: null,
      task_unit_evaluation: null,
      timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
      unit_pay_errors: {
        missing_unit_mapping_data: 'Missing Unit Mapping Data',
      },
      unit_pay_totals: {},
      unit_pay_warnings: {},
      unit_pay_required_project_fields: {},
      unit_work_type: null,
    }
  }

  return unitPayTasks
}

const generateBasePayForHourlyEmployees = (
  us_aggregatedWeekPunchData: TsInterface_UnspecifiedObject,
  employeeFullPayrollData: TsInterface_UnspecifiedObject,
) => {
  for (let loopUserKey in getProp(us_aggregatedWeekPunchData, 'data', {})) {
    let loopUser = us_aggregatedWeekPunchData.data[loopUserKey]
    // Only process hourly employees
    if (getProp(loopUser, 'hourly_or_salaried', null) === 'hourly') {
      if (employeeFullPayrollData[loopUserKey] == null) {
        employeeFullPayrollData[loopUserKey] = {
          // IDENTIFIERS
          key: loopUserKey,
          name: getProp(loopUser, 'name', null),
          name_first: splitName(getProp(loopUser, 'name', ''))[0],
          name_last: splitName(getProp(loopUser, 'name', ''))[1],
          id_number_payroll: getProp(loopUser, 'id_number_payroll', null),
          id_organization_payroll: getProp(loopUser, 'id_organization_payroll', null),
          default_location_state: getProp(loopUser, 'default_location_state', null),
          associated_region_key: getProp(loopUser, 'associated_region_key', null),
          associated_region_name: getProp(loopUser, 'associated_region_name', null),
          // USER DATA
          hardcoded_bonus: getProp(loopUser, 'hardcoded_bonus', null),
          // HELPER
          raw_codes: getProp(loopUser, 'raw_codes', null),
          grouped_codes: getProp(loopUser, 'grouped_codes', null),
          states_worked_in_minimum_wage: {},
          unit_pay_task_breakdown: {},
          // HOURS
          all_hours: null,
          work_reg_hours: null,
          work_ovt_hours: null,
          // nwt_hours: null,
          // NEW FIELDS
          admin_hours: null,
          field_hours: null,
          non_working_hours: null,
          non_working_hours_breakdown: getProp(loopUser, 'non_working_codes', {}),
          admin_reg_hours: null,
          admin_ovt_hours: null,
          field_reg_hours: null,
          field_ovt_hours: null,
          admin_reg_pay: null,
          admin_ovt_pay: null,
          field_reg_pay: null,
          field_ovt_pay: null,
          // WAGE
          employee_in_combine: null,
          combine_status_timestamp_key: null,
          base_wage: getProp(loopUser, 'base_wage', null),
          minimum_wage: null,
          using_minimum_wage_default: false,
          effective_wage: null,
          reg_pay: null,
          overtime_pay: null,
          non_working_hours_pay: null,
          total_pay: null,
          // UNIT PAY
          unit_pay_all: null,
          unit_pay_bonus: null,
          pay_adjustments: null,
          final_bonus_pay: null,
          pay_inefficiency: null,
        }
      }
      // Non working time

      // New Hours
      if (loopUser != null && loopUser['payroll_category_breakdown'] != null) {
        if (loopUser['payroll_category_breakdown']['OFFICE'] != null) {
          employeeFullPayrollData[loopUserKey]['admin_hours'] = loopUser['payroll_category_breakdown']['OFFICE']
        }
        if (loopUser['payroll_category_breakdown']['FIELD'] != null) {
          employeeFullPayrollData[loopUserKey]['field_hours'] = loopUser['payroll_category_breakdown']['FIELD']
        }
        if (loopUser['payroll_category_breakdown']['OFF'] != null) {
          employeeFullPayrollData[loopUserKey]['non_working_hours'] = loopUser['payroll_category_breakdown']['OFF']
        }
      }
      let totalWorkingHours = getProp(employeeFullPayrollData[loopUserKey], 'admin_hours', 0) + getProp(employeeFullPayrollData[loopUserKey], 'field_hours', 0)
      if (getProp(employeeFullPayrollData[loopUserKey], 'admin_hours', 0) > 40) {
        employeeFullPayrollData[loopUserKey]['admin_reg_hours'] = 40
        employeeFullPayrollData[loopUserKey]['admin_ovt_hours'] = getProp(employeeFullPayrollData[loopUserKey], 'admin_hours', 0) - 40
        employeeFullPayrollData[loopUserKey]['field_reg_hours'] = 0
        employeeFullPayrollData[loopUserKey]['field_ovt_hours'] = getProp(employeeFullPayrollData[loopUserKey], 'field_hours', 0)
      } else if (totalWorkingHours > 40) {
        employeeFullPayrollData[loopUserKey]['admin_reg_hours'] = getProp(employeeFullPayrollData[loopUserKey], 'admin_hours', 0)
        employeeFullPayrollData[loopUserKey]['admin_ovt_hours'] = 0
        employeeFullPayrollData[loopUserKey]['field_reg_hours'] = 40 - getProp(employeeFullPayrollData[loopUserKey], 'admin_hours', 0)
        employeeFullPayrollData[loopUserKey]['field_ovt_hours'] =
          getProp(employeeFullPayrollData[loopUserKey], 'field_hours', 0) - (40 - getProp(employeeFullPayrollData[loopUserKey], 'admin_hours', 0))
      } else {
        employeeFullPayrollData[loopUserKey]['admin_reg_hours'] = getProp(employeeFullPayrollData[loopUserKey], 'admin_hours', 0)
        employeeFullPayrollData[loopUserKey]['admin_ovt_hours'] = 0
        employeeFullPayrollData[loopUserKey]['field_reg_hours'] = getProp(employeeFullPayrollData[loopUserKey], 'field_hours', 0)
        employeeFullPayrollData[loopUserKey]['field_ovt_hours'] = 0
      }
      // Hours
      if (loopUser != null && loopUser['totals'] != null && loopUser['totals']['REG'] != null) {
        let workHours = loopUser['totals']['REG']
        if (employeeFullPayrollData[loopUserKey]['all_hours'] == null) {
          employeeFullPayrollData[loopUserKey]['all_hours'] = 0
        }
        employeeFullPayrollData[loopUserKey]['all_hours'] += workHours
        if (workHours > 40) {
          employeeFullPayrollData[loopUserKey]['work_reg_hours'] = 40
          employeeFullPayrollData[loopUserKey]['work_ovt_hours'] = workHours - 40
        } else {
          employeeFullPayrollData[loopUserKey]['work_reg_hours'] = workHours
          employeeFullPayrollData[loopUserKey]['work_ovt_hours'] = 0
        }
      }
      if (loopUser != null && loopUser['payroll_category_breakdown'] != null && loopUser['payroll_category_breakdown']['OFF'] != null) {
        // employeeFullPayrollData[ loopUserKey ]["nwt_hours"] = loopUser["payroll_category_breakdown"]["OFF"]
        if (employeeFullPayrollData[loopUserKey]['all_hours'] == null) {
          employeeFullPayrollData[loopUserKey]['all_hours'] = 0
        }
        employeeFullPayrollData[loopUserKey]['all_hours'] += loopUser['payroll_category_breakdown']['OFF']
      }
    }
  }
}

const generateQAReviewTasksDataObject = (us_tasksWithQAReviews: TsInterface_UnspecifiedObject) => {
  let qaReviewTasksData: TsInterface_UnspecifiedObject = {
    qa_tasks_by_user: {},

    // TODO:  separate between panels and units?

    qa_tasks_errors_object: {},
  }
  // QA Review Tasks
  for (let loopTaskKey in us_tasksWithQAReviews) {
    let loopTask = us_tasksWithQAReviews[loopTaskKey]
    if (loopTask['qa_review_result'] === 'fail') {
      qaReviewTasksData['qa_tasks_errors_object'][loopTaskKey] = {
        key: loopTaskKey,
        name: getProp(loopTask, 'name', null),
        associated_project_key: getProp(loopTask, 'associated_project_key', null),
        associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
        associated_team_name: getProp(loopTask, 'associated_team_name', null),
        associated_task_key: getProp(loopTask, 'associated_task_key', null),
        task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', []),
        qa_review_result: getProp(loopTask, 'qa_review_result', null),
        qa_review_notes: getProp(loopTask, 'qa_review_notes', null),
        timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
        timestamp_qa_review_date: getProp(loopTask, 'timestamp_qa_review_date', null),
      }
      // Assign to users
      let lastScheduledWorkDay = getLastChronologicalDate(loopTask.task_completion_scheduled_dates)
      if (lastScheduledWorkDay != null) {
        for (let loopUserKey in loopTask.task_completion_scheduled_team_keys[lastScheduledWorkDay]) {
          if (qaReviewTasksData['qa_tasks_by_user'][loopUserKey] == null) {
            qaReviewTasksData['qa_tasks_by_user'][loopUserKey] = {}
          }
          qaReviewTasksData['qa_tasks_by_user'][loopUserKey][loopTaskKey] = getProp(loopTask, 'name', null)
        }
      }
    }
  }
  return qaReviewTasksData
}

const generateLeaderboardDataAndPayrollData = (
  unitPayTasks: TsInterface_UnspecifiedObject,
  projectTaskRequireFieldMappingHelperObject: TsInterface_UnspecifiedObject,
  leaderboardData: TsInterface_UnspecifiedObject,
  referralLeaderboardData: TsInterface_UnspecifiedObject,
  us_finalizedWeekPayroll_CustomMappingData: TsInterface_UnspecifiedObject,
  usersMissingTaskHours: TsInterface_UnspecifiedObject,
  us_aggregatedWeekPunchData: TsInterface_UnspecifiedObject,
  leaderboardUserRoles: TsInterface_UnspecifiedObject,
  userRoleErrors: TsInterface_UnspecifiedObject,
  employeeFullPayrollData: TsInterface_UnspecifiedObject,
  cachedProjects: TsInterface_UnspecifiedObject,
  us_minimumWageValues: TsInterface_UnspecifiedObject,
  us_mondayDateKey: string,
  us_lifetimeReferralsData: TsInterface_UnspecifiedObject,
) => {
  for (let loopTaskKey in unitPayTasks) {
    let loopTask = unitPayTasks[loopTaskKey]
    // Set Fields Required for each Project and the tasks on each project
    if (
      loopTask != null &&
      loopTask['associated_project_key'] != null &&
      projectTaskRequireFieldMappingHelperObject[loopTask['associated_project_key']] == null
    ) {
      projectTaskRequireFieldMappingHelperObject[loopTask['associated_project_key']] = {
        associated_project_key: loopTask['associated_project_key'],
        associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
        associated_team_name: loopTask['associated_team_name'],
        associated_tasks: {},
        unit_pay_required_project_fields: {},
      }
    }
    projectTaskRequireFieldMappingHelperObject[loopTask['associated_project_key']]['associated_tasks'][loopTaskKey] = {
      key: loopTaskKey,
      name: getProp(loopTask, 'name', null),
    }
    for (let loopFieldKey in loopTask['unit_pay_required_project_fields']) {
      projectTaskRequireFieldMappingHelperObject[loopTask['associated_project_key']]['unit_pay_required_project_fields'][loopFieldKey] =
        loopTask['unit_pay_required_project_fields'][loopFieldKey]
    }
    // Leaderboard
    if (loopTask != null && loopTask['unit_pay_totals'] != null) {
      for (let loopUserKey in loopTask['unit_pay_totals']) {
        if (loopUserKey != null && loopUserKey !== 'undefined') {
          let loopUser = loopTask['unit_pay_totals'][loopUserKey]
          // Create Empty Leaderboard User Object
          if (leaderboardData[us_mondayDateKey + '_' + loopUserKey] == null) {
            leaderboardData[us_mondayDateKey + '_' + loopUserKey] = {
              associated_user_key: loopUserKey,
              associated_user_name: getProp(loopUser, 'name', null),
              timestamp_key: us_mondayDateKey,
              // Panels / Units
              total_payroll_panels: 0,
              total_efficiency_panels: 0,
              total_lifetime_panels: 0,
              total_payroll_units: 0,
              total_efficiency_units: 0,
              total_lifetime_units: 0,
              // Dollars
              panel_pay: 0,
              unit_pay: 0,
              tasks: {},
              // Efficiency
              panel_hours: 0,
              unit_hours: 0,
              // panel_hours_v2: 0,
              // unit_hours_v2: 0,
              panel_efficiency: 0,
              unit_efficiency: 0,
              // panel_efficiency_v2: 0,
              // unit_efficiency_v2: 0,
              // Quality
              combined_quality_assurance_failures: 0,
              // panel_quality_assurance_failures: 0,
              // unit_quality_assurance_failures: 0,
            }
          }
          // Linked Tasks
          leaderboardData[us_mondayDateKey + '_' + loopUserKey]['tasks'][loopTaskKey] = {
            timestamp_completed: getProp(loopTask, 'timestamp_completed', null),
            associated_project_id_number: getProp(loopTask, 'associated_project_id_number', null),
            associated_project_key: getProp(loopTask, 'associated_project_key', null),
            name: getProp(loopTask, 'name', null),
            // type: null,
            units: 0,
            pay: 0,
            hours: 0,
          }
          if (loopTask['task_unit_evaluation'] === 'panels') {
            leaderboardData[us_mondayDateKey + '_' + loopUserKey]['tasks'][loopTaskKey]['type'] = 'panels'
          } else {
            leaderboardData[us_mondayDateKey + '_' + loopUserKey]['tasks'][loopTaskKey]['type'] = 'units'
          }
          // Determine Unit Pay or Panel Pay
          if (loopUser != null && loopUser.total_unit_pay != null) {
            leaderboardData[us_mondayDateKey + '_' + loopUserKey]['tasks'][loopTaskKey]['units'] = getProp(loopUser, 'total_units', 0)
            leaderboardData[us_mondayDateKey + '_' + loopUserKey]['tasks'][loopTaskKey]['pay'] = getProp(loopUser, 'total_unit_pay', 0)
            // Sum up panels / unit pay
            if (loopTask['task_unit_evaluation'] === 'panels') {
              leaderboardData[us_mondayDateKey + '_' + loopUserKey]['total_payroll_panels'] += getProp(loopUser, 'total_units', 0)
              leaderboardData[us_mondayDateKey + '_' + loopUserKey]['panel_pay'] += getProp(loopUser, 'total_unit_pay', 0)
            } else {
              leaderboardData[us_mondayDateKey + '_' + loopUserKey]['total_payroll_units'] += getProp(loopUser, 'total_units', 0)
              leaderboardData[us_mondayDateKey + '_' + loopUserKey]['unit_pay'] += getProp(loopUser, 'total_unit_pay', 0)
            }
            // Efficiency
            if (
              unitWorkTypes != null &&
              loopTask['unit_work_type'] != null &&
              unitWorkTypes[loopTask['unit_work_type']] != null &&
              unitWorkTypes[loopTask['unit_work_type']]['include_in_efficiency_calculation'] === true
            ) {
              if (loopTask['task_unit_evaluation'] === 'panels') {
                leaderboardData[us_mondayDateKey + '_' + loopUserKey]['total_efficiency_panels'] += getProp(loopUser, 'total_units', 0)
              } else {
                leaderboardData[us_mondayDateKey + '_' + loopUserKey]['total_efficiency_units'] += getProp(loopUser, 'total_units', 0)
              }
            }
            // Lifetime
            if (
              unitWorkTypes != null &&
              loopTask['unit_work_type'] != null &&
              unitWorkTypes[loopTask['unit_work_type']] != null &&
              unitWorkTypes[loopTask['unit_work_type']]['include_in_lifetime_calculation'] === true
            ) {
              if (loopTask['task_unit_evaluation'] === 'panels') {
                leaderboardData[us_mondayDateKey + '_' + loopUserKey]['total_lifetime_panels'] += getProp(loopUser, 'task_units', 0)
              } else {
                leaderboardData[us_mondayDateKey + '_' + loopUserKey]['total_lifetime_units'] += getProp(loopUser, 'task_units', 0)
              }
            }
          }

          // =================================================
          // MORE SOPHISTICATED EFFICIENCY CALCULATION (V2)
          // TABLED FOR NOW
          // =================================================

          // Determine Efficiency
          let userHasTaskHours = false
          let userTaskHours = 0
          if (
            us_finalizedWeekPayroll_CustomMappingData != null &&
            us_finalizedWeekPayroll_CustomMappingData['cached_efficiency_clock_hour_data'] != null &&
            us_finalizedWeekPayroll_CustomMappingData['cached_efficiency_clock_hour_data'][loopUserKey] != null &&
            us_finalizedWeekPayroll_CustomMappingData['cached_efficiency_clock_hour_data'][loopUserKey]['punched_events'] != null
          ) {
            for (let loopPunchedEventKey in us_finalizedWeekPayroll_CustomMappingData['cached_efficiency_clock_hour_data'][loopUserKey]['punched_events']) {
              let loopPunchedEvent =
                us_finalizedWeekPayroll_CustomMappingData['cached_efficiency_clock_hour_data'][loopUserKey]['punched_events'][loopPunchedEventKey]
              if (
                loopPunchedEvent != null &&
                loopPunchedEvent['associated_tasks'] != null &&
                loopPunchedEvent['associated_tasks'][loopTaskKey] != null &&
                loopPunchedEvent['hours'] != null &&
                loopPunchedEvent['total_tasks'] != null
              ) {
                userHasTaskHours = true
                userTaskHours += loopPunchedEvent['hours'] / loopPunchedEvent['total_tasks']
              }
            }
          }
          if (userHasTaskHours === true) {
            // Sum up panels / unit pay
            leaderboardData[us_mondayDateKey + '_' + loopUserKey]['tasks'][loopTaskKey]['hours'] = userTaskHours
            if (loopTask['task_unit_evaluation'] === 'panels') {
              // if(
              // 	unitWorkTypes != null &&
              // 	loopTask["unit_work_type"] != null &&
              // 	unitWorkTypes[ loopTask["unit_work_type"] ] != null &&
              // 	unitWorkTypes[ loopTask["unit_work_type"] ]["include_in_efficiency_calculation"] === true
              // ){
              // 	leaderboardData[ us_mondayDateKey + "_" + loopUserKey ]["panel_hours_v2"] += userTaskHours
              // }
              // if(
              // 	leaderboardData[ us_mondayDateKey + "_" + loopUserKey ]["panel_hours_v2"] > 0 &&
              // 	!isNaN( leaderboardData[ us_mondayDateKey + "_" + loopUserKey ]["total_efficiency_panels"] )
              // ){
              // 	leaderboardData[ us_mondayDateKey + "_" + loopUserKey ]["panel_efficiency_v2"] = ( leaderboardData[ us_mondayDateKey + "_" + loopUserKey ]["total_efficiency_panels"] / leaderboardData[ us_mondayDateKey + "_" + loopUserKey ]["panel_hours_v2"] )
              // }
            } else {
              // if(
              // 	unitWorkTypes != null &&
              // 	loopTask["unit_work_type"] != null &&
              // 	unitWorkTypes[ loopTask["unit_work_type"] ] != null &&
              // 	unitWorkTypes[ loopTask["unit_work_type"] ]["include_in_efficiency_calculation"] === true
              // ){
              // 	leaderboardData[ us_mondayDateKey + "_" + loopUserKey ]["unit_hours_v2"] += userTaskHours
              // }
              // if(
              // 	leaderboardData[ us_mondayDateKey + "_" + loopUserKey ]["unit_hours_v2"] > 0 &&
              // 	!isNaN( leaderboardData[ us_mondayDateKey + "_" + loopUserKey ]["total_efficiency_units"] )
              // ){
              // 	leaderboardData[ us_mondayDateKey + "_" + loopUserKey ]["unit_efficiency_v2"] = ( leaderboardData[ us_mondayDateKey + "_" + loopUserKey ]["total_efficiency_units"] / leaderboardData[ us_mondayDateKey + "_" + loopUserKey ]["unit_hours_v2"] )
              // }
            }
          } else {
            if (usersMissingTaskHours[loopUserKey] == null) {
              usersMissingTaskHours[loopUserKey] = {
                name: getProp(loopUser, 'name', null),
                key: loopUserKey,
                tasks: {},
              }
            }
            usersMissingTaskHours[loopUserKey]['tasks'][loopTaskKey] = {
              name: getProp(loopTask, 'name', null),
              key: loopTaskKey,
            }
          }

          // Determine User Role
          if (
            us_aggregatedWeekPunchData != null &&
            us_aggregatedWeekPunchData.internalUsers != null &&
            us_aggregatedWeekPunchData.internalUsers[loopUserKey] != null &&
            us_aggregatedWeekPunchData.internalUsers[loopUserKey].user_role != null
          ) {
            leaderboardData[us_mondayDateKey + '_' + loopUserKey]['user_role'] = us_aggregatedWeekPunchData.internalUsers[loopUserKey].user_role
            let userRoleName = us_aggregatedWeekPunchData.internalUsers[loopUserKey].user_role
            if (ClientUserRoles != null && ClientUserRoles[userRoleName] != null && ClientUserRoles[userRoleName].name_string != null) {
              userRoleName = ClientUserRoles[userRoleName].name_string
            }
            leaderboardUserRoles[us_aggregatedWeekPunchData.internalUsers[loopUserKey].user_role] = {
              key: us_aggregatedWeekPunchData.internalUsers[loopUserKey].user_role,
              name: userRoleName,
            }
          } else {
            if (loopUserKey != null && loopUserKey !== 'undefined') {
              userRoleErrors[loopUserKey] = {
                name: getProp(loopUser, 'name', null),
                error: 'Missing User Role',
              }
            }
          }
        }
      }
    }
    // Unit Pay
    if (loopTask != null && loopTask['unit_pay_totals'] != null) {
      for (let loopUserKey in loopTask['unit_pay_totals']) {
        let loopUser = loopTask['unit_pay_totals'][loopUserKey]
        if (loopUser != null && loopUser.total_unit_pay != null) {
          // Create Empty Payroll User Object
          if (employeeFullPayrollData[loopUserKey] == null) {
            employeeFullPayrollData[loopUserKey] = {
              // IDENTIFIERS
              key: loopUserKey,
              name: getProp(loopUser, 'name', null),
              name_first: splitName(getProp(loopUser, 'name', ''))[0],
              name_last: splitName(getProp(loopUser, 'name', ''))[1],
              id_number_payroll: getProp(loopUser, 'id_number_payroll', null),
              id_organization_payroll: getProp(loopUser, 'id_organization_payroll', null),
              default_location_state: getProp(loopUser, 'default_location_state', null),
              associated_region_key: getProp(loopUser, 'associated_region_key', null),
              associated_region_name: getProp(loopUser, 'associated_region_name', null),
              // USER DATA
              hardcoded_bonus: getProp(loopUser, 'hardcoded_bonus', null),
              // HELPER
              raw_codes: null,
              grouped_codes: null,
              states_worked_in_minimum_wage: {},
              unit_pay_task_breakdown: {},
              // HOURS
              all_hours: null,
              work_reg_hours: null,
              work_ovt_hours: null,
              // nwt_hours: null,
              // NEW FIELDS
              admin_hours: null,
              field_hours: null,
              non_working_hours: null,
              non_working_hours_breakdown: {},
              admin_reg_hours: null,
              admin_ovt_hours: null,
              field_reg_hours: null,
              field_ovt_hours: null,
              admin_reg_pay: null,
              admin_ovt_pay: null,
              field_reg_pay: null,
              field_ovt_pay: null,
              // WAGE
              employee_in_combine: null,
              combine_status_timestamp_key: null,
              base_wage: null,
              minimum_wage: null,
              using_minimum_wage_default: false,
              effective_wage: null,
              reg_pay: null, // TODO - remove
              overtime_pay: null, // TODO - remove
              non_working_hours_pay: null,
              total_pay: null,
              // UNIT PAY
              unit_pay_all: null,
              unit_pay_bonus: null,
              pay_adjustments: null,
              final_bonus_pay: null,
              pay_inefficiency: null,
            }
          }
          if (employeeFullPayrollData[loopUserKey]['unit_pay_all'] == null) {
            employeeFullPayrollData[loopUserKey]['unit_pay_all'] = 0
          }
          employeeFullPayrollData[loopUserKey]['unit_pay_all'] += loopUser.total_unit_pay
        }
        //////////////////////////////
        // Unit Pay Task Breakdown
        //////////////////////////////
        employeeFullPayrollData[loopUserKey]['unit_pay_task_breakdown'][loopTaskKey] = {
          key: getProp(loopTask, 'associated_project_id_number', '') + ' - ' + loopTask.name,
          value: loopUser.total_unit_pay,
          breakdown: loopUser,
          task_unit_evaluation: loopTask['task_unit_evaluation'],
        }

        //////////////////////////////
        // Handle Initial Minimum Wage
        // Tasks by each state
        //////////////////////////////

        let locationState = null
        let completedTimestamp = null
        if (loopTask['timestamp_completed'] != null) {
          completedTimestamp = returnDateFromUnknownDateFormat(loopTask['timestamp_completed']).getTime()
        }
        if (
          loopTask != null &&
          loopTask['associated_project_key'] != null &&
          cachedProjects != null &&
          cachedProjects[loopTask['associated_project_key']] != null &&
          cachedProjects[loopTask['associated_project_key']]['location_state'] != null
        ) {
          locationState = cachedProjects[loopTask['associated_project_key']]['location_state']
        }
        addStateMinimumWageToEmployeePayrollDataObject(locationState, completedTimestamp, us_minimumWageValues, loopUserKey, employeeFullPayrollData)
      }
    }
  }
  // Referral Bonuses - loop through referrals and add panels and units to the user who referred them
  for (let loopReferredUserKey in us_lifetimeReferralsData) {
    let loopReferredUser = us_lifetimeReferralsData[loopReferredUserKey]
    if (loopReferredUser != null && loopReferredUser['associated_referrer_key'] != null) {
      // Check and see if the referred user has any panels or units for the week
      if (leaderboardData != null && leaderboardData[us_mondayDateKey + '_' + loopReferredUserKey] != null) {
        let leaderboardUser = leaderboardData[us_mondayDateKey + '_' + loopReferredUserKey]
        if (leaderboardUser != null && leaderboardUser['total_lifetime_panels'] != null && leaderboardUser['total_lifetime_panels'] > 0) {
          if (referralLeaderboardData[loopReferredUser['associated_referrer_key']] == null) {
            referralLeaderboardData[loopReferredUser['associated_referrer_key']] = {
              associated_user_key: loopReferredUser['associated_referrer_key'],
              associated_user_name: getProp(loopReferredUser, 'associated_referrer_name', null),
              date_key: us_mondayDateKey,
              total_lifetime_referral_panels: 0,
            }
          }
          if (referralLeaderboardData[loopReferredUser['associated_referrer_key']]['total_lifetime_referral_panels'] == null) {
            referralLeaderboardData[loopReferredUser['associated_referrer_key']]['total_lifetime_referral_panels'] = 0
          }
          referralLeaderboardData[loopReferredUser['associated_referrer_key']]['total_lifetime_referral_panels'] +=
            leaderboardUser['total_lifetime_panels'] / 10
        }
        if (leaderboardUser != null && leaderboardUser['total_lifetime_units'] != null && leaderboardUser['total_lifetime_units'] > 0) {
          if (referralLeaderboardData[loopReferredUser['associated_referrer_key']] == null) {
            referralLeaderboardData[loopReferredUser['associated_referrer_key']] = {
              associated_user_key: loopReferredUser['associated_referrer_key'],
              associated_user_name: getProp(loopReferredUser, 'associated_referrer_name', null),
              date_key: us_mondayDateKey,
              total_lifetime_referral_units: 0,
            }
          }
          if (referralLeaderboardData[loopReferredUser['associated_referrer_key']]['total_lifetime_referral_units'] == null) {
            referralLeaderboardData[loopReferredUser['associated_referrer_key']]['total_lifetime_referral_units'] = 0
          }
          referralLeaderboardData[loopReferredUser['associated_referrer_key']]['total_lifetime_referral_units'] += leaderboardUser['total_lifetime_units'] / 10
        }
      }
    }
  }
}

const cleanLeaderboardData = (
  leaderboardData: TsInterface_UnspecifiedObject,
  qaReviewTasksData: TsInterface_UnspecifiedObject,
  employeeFullPayrollData: TsInterface_UnspecifiedObject,
) => {
  for (let loopUserDateKey in leaderboardData) {
    let loopUserDate = leaderboardData[loopUserDateKey]
    // if(
    // 	loopUserDate != null &&
    // 	loopUserDate["panel_hours_v2"] != null
    // ){
    // 	loopUserDate["panel_hours_v2"] = parseFloat( loopUserDate["panel_hours_v2"].toFixed(1) )
    // }
    // if(
    // 	loopUserDate != null &&
    // 	loopUserDate["unit_hours_v2"] != null
    // ){
    // 	loopUserDate["unit_hours_v2"] = parseFloat( loopUserDate["unit_hours_v2"].toFixed(1) )
    // }
    // if(
    // 	loopUserDate != null &&
    // 	loopUserDate["panel_efficiency_v2"] != null
    // ){
    // 	loopUserDate["panel_efficiency_v2"] = parseFloat( loopUserDate["panel_efficiency_v2"].toFixed(1) )
    // }
    // if(
    // 	loopUserDate != null &&
    // 	loopUserDate["unit_efficiency_v2"] != null
    // ){
    // 	loopUserDate["unit_efficiency_v2"] = parseFloat( loopUserDate["unit_efficiency_v2"].toFixed(1) )
    // }
    // Quality - QA Fails
    if (
      loopUserDate != null &&
      loopUserDate['associated_user_key'] != null &&
      qaReviewTasksData != null &&
      qaReviewTasksData['qa_tasks_by_user'] != null &&
      qaReviewTasksData['qa_tasks_by_user'][loopUserDate.associated_user_key] != null
    ) {
      leaderboardData[loopUserDateKey]['combined_quality_assurance_failures'] += objectToArray(
        qaReviewTasksData['qa_tasks_by_user'][loopUserDate.associated_user_key],
      ).length
    }
    // Efficiency
    if (
      employeeFullPayrollData != null &&
      employeeFullPayrollData[loopUserDate.associated_user_key] != null &&
      employeeFullPayrollData[loopUserDate.associated_user_key].field_hours != null &&
      employeeFullPayrollData[loopUserDate.associated_user_key].field_hours > 0
    ) {
      let userTaskHours = employeeFullPayrollData[loopUserDate.associated_user_key].field_hours
      let efficiencyPanels = getProp(loopUserDate, 'total_efficiency_panels', 0)
      let efficiencyUnits = getProp(loopUserDate, 'total_efficiency_units', 0)
      let weightedSumOfPanelsAndUnits = efficiencyUnits * 2 + efficiencyPanels
      let panelEstimatedHours = (efficiencyPanels / weightedSumOfPanelsAndUnits) * userTaskHours
      let unitEstimatedHours = (efficiencyUnits / weightedSumOfPanelsAndUnits) * userTaskHours * 2
      leaderboardData[loopUserDateKey]['panel_hours'] = panelEstimatedHours
      leaderboardData[loopUserDateKey]['unit_hours'] = unitEstimatedHours
      if (panelEstimatedHours > 0) {
        let basicPanelEfficiency = efficiencyPanels / panelEstimatedHours
        leaderboardData[loopUserDateKey]['panel_efficiency'] = basicPanelEfficiency
      } else {
        leaderboardData[loopUserDateKey]['panel_efficiency'] = 0
      }
      if (unitEstimatedHours > 0) {
        let basicUnitEfficiency = efficiencyUnits / unitEstimatedHours
        leaderboardData[loopUserDateKey]['unit_efficiency'] = basicUnitEfficiency
      } else {
        leaderboardData[loopUserDateKey]['unit_efficiency'] = 0
      }
    }
  }
}

const addMinimumWageDataFromPunchData = (
  us_aggregatedWeekPunchData: TsInterface_UnspecifiedObject,
  us_minimumWageValues: TsInterface_UnspecifiedObject,
  employeeFullPayrollData: TsInterface_UnspecifiedObject,
) => {
  for (let loopUserKey in getProp(us_aggregatedWeekPunchData, 'data', {})) {
    let loopUser = us_aggregatedWeekPunchData.data[loopUserKey]
    // Only process hourly employees
    if (getProp(loopUser, 'hourly_or_salaried', null) === 'hourly') {
      if (loopUser != null && loopUser['task_states'] != null) {
        for (let loopTaskKey in loopUser['task_states']) {
          let loopTask = loopUser['task_states'][loopTaskKey]
          let locationState = loopTask['state']
          let completedTimestamp = loopTask['timestamp']
          addStateMinimumWageToEmployeePayrollDataObject(locationState, completedTimestamp, us_minimumWageValues, loopUserKey, employeeFullPayrollData)
        }
      }
    }
  }
}

const runFinalWageAndPayCleanup = (
  us_mondayDateKey: string | null,
  employeeFullPayrollData: TsInterface_UnspecifiedObject,
  us_minimumWageValues: TsInterface_UnspecifiedObject,
  us_weekEndDate: Date,
  us_weekStartDate: Date,
  us_weekPayrollAdjustments: TsInterface_UnspecifiedObject,
) => {
  for (let loopUserKey in employeeFullPayrollData) {
    let loopUser = employeeFullPayrollData[loopUserKey]
    // Minimum Wage
    if (loopUser != null && loopUser['states_worked_in_minimum_wage'] != null) {
      for (let loopStateKey in loopUser['states_worked_in_minimum_wage']) {
        let loopStateValue = loopUser['states_worked_in_minimum_wage'][loopStateKey]
        if (loopStateValue != null) {
          if (loopUser['minimum_wage'] == null) {
            loopUser['minimum_wage'] = loopStateValue
          } else if (loopUser['minimum_wage'] > loopStateValue) {
            loopUser['minimum_wage'] = loopStateValue
          }
        }
      }
    }
    // Backup Minimum Wage
    if (loopUser['minimum_wage'] == null && loopUser['default_location_state'] != null) {
      loopUser['using_minimum_wage_default'] = true
      addStateMinimumWageToEmployeePayrollDataObject(
        loopUser['default_location_state'],
        returnDateFromUnknownDateFormat(us_weekEndDate).getTime(),
        us_minimumWageValues,
        loopUserKey,
        employeeFullPayrollData,
      )
      addStateMinimumWageToEmployeePayrollDataObject(
        loopUser['default_location_state'],
        returnDateFromUnknownDateFormat(us_weekStartDate).getTime(),
        us_minimumWageValues,
        loopUserKey,
        employeeFullPayrollData,
      )
      // Run again
      for (let loopStateKey in loopUser['states_worked_in_minimum_wage']) {
        let loopStateValue = loopUser['states_worked_in_minimum_wage'][loopStateKey]
        if (loopStateValue != null) {
          if (loopUser['minimum_wage'] == null) {
            loopUser['minimum_wage'] = loopStateValue
          } else if (loopUser['minimum_wage'] > loopStateValue) {
            loopUser['minimum_wage'] = loopStateValue
          }
        }
      }
    }
    // Effective Wage
    if (loopUser != null && loopUser['employee_in_combine'] === true && loopUser['minimum_wage'] != null) {
      loopUser['effective_wage'] = loopUser['minimum_wage']
    } else if (loopUser != null && loopUser['base_wage'] != null) {
      loopUser['effective_wage'] = loopUser['base_wage']
    }
    // Reg and Overtime Pay
    if (loopUser['base_wage'] != null) {
      if (loopUser['reg_pay'] == null) {
        loopUser['reg_pay'] = 0
      }
      if (loopUser['overtime_pay'] == null) {
        loopUser['overtime_pay'] = 0
      }
      loopUser['admin_reg_pay'] = getProp(loopUser, 'admin_reg_hours', 0) * loopUser['base_wage']
      loopUser['reg_pay'] += loopUser['admin_reg_pay']
      loopUser['admin_ovt_pay'] = getProp(loopUser, 'admin_ovt_hours', 0) * loopUser['base_wage'] * 1.5
      loopUser['overtime_pay'] += loopUser['admin_ovt_pay']
      loopUser['non_working_hours_pay'] = getProp(loopUser, 'non_working_hours', 0) * loopUser['base_wage']
    }
    if (loopUser['effective_wage'] != null) {
      if (loopUser['reg_pay'] == null) {
        loopUser['reg_pay'] = 0
      }
      if (loopUser['overtime_pay'] == null) {
        loopUser['overtime_pay'] = 0
      }
      loopUser['field_reg_pay'] = getProp(loopUser, 'field_reg_hours', 0) * loopUser['effective_wage']
      loopUser['reg_pay'] += loopUser['field_reg_pay']
      loopUser['field_ovt_pay'] = getProp(loopUser, 'field_ovt_hours', 0) * loopUser['effective_wage'] * 1.5
      loopUser['overtime_pay'] += loopUser['field_ovt_pay']
    }
    // NOTE: Switched from totalFieldPay to totalWorkingPay
    // Total Pay
    let totalPay = 0
    let totalFieldPay = 0
    let totalWorkingPay = 0
    if (loopUser['reg_pay'] != null) {
      totalPay += loopUser['reg_pay']
      totalWorkingPay += loopUser['reg_pay']
    }
    if (loopUser['overtime_pay'] != null) {
      totalPay += loopUser['overtime_pay']
      totalWorkingPay += loopUser['overtime_pay']
    }
    if (loopUser['non_working_hours_pay'] != null) {
      totalPay += loopUser['non_working_hours_pay']
    }
    // totalWorkingPay += loopUser['field_reg_pay'] + loopUser['field_ovt_pay']
    loopUser['total_pay'] = totalPay
    totalFieldPay = loopUser['field_reg_pay'] + loopUser['field_ovt_pay']
    let totalUnitPay = getProp(loopUser, 'unit_pay_all', null)
    // if (totalUnitPay > totalFieldPay) {
    if (totalUnitPay > totalWorkingPay) {
      loopUser['unit_pay_bonus'] = totalUnitPay - totalFieldPay
      // loopUser['unit_pay_bonus'] = totalUnitPay - totalWorkingPay
      loopUser['pay_inefficiency'] = 0
    } else {
      loopUser['unit_pay_bonus'] = 0
      loopUser['pay_inefficiency'] = totalFieldPay - totalUnitPay
      // loopUser['pay_inefficiency'] = totalWorkingPay - totalUnitPay
    }
    // Adjustments
    loopUser['pay_adjustments'] = 0
    loopUser['pay_adjustment_details'] = {}
    for (let loopAdjustmentKey in us_weekPayrollAdjustments) {
      let loopAdjustment = us_weekPayrollAdjustments[loopAdjustmentKey]
      if (
        us_mondayDateKey != null &&
        loopAdjustment != null &&
        loopAdjustment['associated_user_key'] != null &&
        loopAdjustment['associated_user_key'] === loopUserKey &&
        loopAdjustment['associated_payroll_week_adjustment_amounts'] != null &&
        loopAdjustment['associated_payroll_week_adjustment_amounts'][us_mondayDateKey] != null &&
        !isNaN(loopAdjustment['associated_payroll_week_adjustment_amounts'][us_mondayDateKey]) != null
      ) {
        if (loopAdjustment['type'] === 'payroll_bonus_decrease') {
          loopUser['pay_adjustments'] -= loopAdjustment['associated_payroll_week_adjustment_amounts'][us_mondayDateKey]
        } else if (loopAdjustment['type'] === 'payroll_bonus_increase') {
          loopUser['pay_adjustments'] += loopAdjustment['associated_payroll_week_adjustment_amounts'][us_mondayDateKey]
        }
        loopUser['pay_adjustment_details'][loopAdjustmentKey] = {
          notes: getProp(loopAdjustment, 'notes', null),
          type: getProp(loopAdjustment, 'type', null),
          amount: loopAdjustment['associated_payroll_week_adjustment_amounts'][us_mondayDateKey],
        }
      }
    }
    loopUser['final_bonus_pay'] = loopUser['unit_pay_bonus'] + loopUser['pay_adjustments']
    loopUser['total_base_plus_bonus_pay'] = loopUser['final_bonus_pay'] + loopUser['total_pay']
  }
}

const addStateMinimumWageToEmployeePayrollDataObject = (
  locationState: string | null,
  completedTimestamp: number | null,
  us_minimumWageValues: TsInterface_UnspecifiedObject,
  loopUserKey: string,
  employeeFullPayrollData: TsInterface_UnspecifiedObject,
) => {
  if (locationState != null && completedTimestamp != null && us_minimumWageValues != null && us_minimumWageValues[locationState] != null) {
    let minWageStateData = us_minimumWageValues[locationState]
    // Missing Minimum Wage for State on Date
    if (minWageStateData != null) {
      if (
        minWageStateData['effective_date_1'] != null &&
        minWageStateData['minimum_wage_1'] != null &&
        minWageStateData['effective_date_2'] != null &&
        minWageStateData['minimum_wage_2'] != null
      ) {
        let minWageDate1 = returnDateFromUnknownDateFormat(minWageStateData['effective_date_1']).getTime()
        let minWageDate2 = returnDateFromUnknownDateFormat(minWageStateData['effective_date_2']).getTime()
        if (minWageDate2 > minWageDate1) {
          if (completedTimestamp >= minWageDate2) {
            if (employeeFullPayrollData[loopUserKey] != null) {
              employeeFullPayrollData[loopUserKey]['states_worked_in_minimum_wage'][locationState + '_' + minWageStateData['minimum_wage_2']] =
                minWageStateData['minimum_wage_2']
            }
          } else if (completedTimestamp >= minWageDate1) {
            if (employeeFullPayrollData[loopUserKey] != null) {
              employeeFullPayrollData[loopUserKey]['states_worked_in_minimum_wage'][locationState + '_' + minWageStateData['minimum_wage_1']] =
                minWageStateData['minimum_wage_1']
            }
          } else {
            // missingMinimumWageData = true
          }
        } else {
          if (completedTimestamp >= minWageDate1) {
            if (employeeFullPayrollData[loopUserKey] != null) {
              employeeFullPayrollData[loopUserKey]['states_worked_in_minimum_wage'][locationState + '_' + minWageStateData['minimum_wage_1']] =
                minWageStateData['minimum_wage_1']
            }
          } else if (completedTimestamp >= minWageDate2) {
            if (employeeFullPayrollData[loopUserKey] != null) {
              employeeFullPayrollData[loopUserKey]['states_worked_in_minimum_wage'][locationState + '_' + minWageStateData['minimum_wage_2']] =
                minWageStateData['minimum_wage_2']
            }
          } else {
            // missingMinimumWageData = true
          }
        }
      } else if (minWageStateData['effective_date_1'] != null && minWageStateData['minimum_wage_1'] != null) {
        let minWageDate1 = returnDateFromUnknownDateFormat(minWageStateData['effective_date_1']).getTime()
        if (completedTimestamp >= minWageDate1) {
          if (employeeFullPayrollData[loopUserKey] != null) {
            employeeFullPayrollData[loopUserKey]['states_worked_in_minimum_wage'][locationState + '_' + minWageStateData['minimum_wage_1']] =
              minWageStateData['minimum_wage_1']
          }
        } else {
          // missingMinimumWageData = true
        }
      } else if (minWageStateData['effective_date_2'] != null && minWageStateData['minimum_wage_2'] != null) {
        let minWageDate2 = returnDateFromUnknownDateFormat(minWageStateData['effective_date_2']).getTime()
        if (completedTimestamp >= minWageDate2) {
          if (employeeFullPayrollData[loopUserKey] != null) {
            employeeFullPayrollData[loopUserKey]['states_worked_in_minimum_wage'][locationState + '_' + minWageStateData['minimum_wage_2']] =
              minWageStateData['minimum_wage_2']
          }
        } else {
          // missingMinimumWageData = true
        }
      } else {
        // missingMinimumWageData = true
      }
    } else {
      // missingMinimumWageData = true
    }
  } else {
    // missingMinimumWageData = true
  }
}

const generateCurrentCombineStatusObject = (
  us_selectedWeekCombineLogs: TsInterface_UnspecifiedObject,
  us_previousUserCombineLogs: TsInterface_UnspecifiedObject,
  employeeFullPayrollData: TsInterface_UnspecifiedObject,
) => {
  // Generate Current Combine Status Object
  for (let loopLogKey in us_selectedWeekCombineLogs) {
    let loopLog = us_selectedWeekCombineLogs[loopLogKey]
    if (
      loopLog != null &&
      loopLog['associated_user_key'] != null &&
      loopLog['in_combine'] != null &&
      employeeFullPayrollData != null &&
      employeeFullPayrollData[loopLog['associated_user_key']] != null
    ) {
      employeeFullPayrollData[loopLog['associated_user_key']]['employee_in_combine'] = loopLog['in_combine']
      if (loopLog['timestamp_key'] != null) {
        employeeFullPayrollData[loopLog['associated_user_key']]['combine_status_timestamp_key'] = loopLog['timestamp_key']
      }
    }
  }
  for (let loopLogKey in us_previousUserCombineLogs) {
    let loopLog = us_previousUserCombineLogs[loopLogKey]
    if (
      loopLog != null &&
      loopLog['associated_user_key'] != null &&
      loopLog['in_combine'] != null &&
      employeeFullPayrollData != null &&
      employeeFullPayrollData[loopLog['associated_user_key']] != null &&
      employeeFullPayrollData[loopLog['associated_user_key']]['employee_in_combine'] == null
    ) {
      employeeFullPayrollData[loopLog['associated_user_key']]['employee_in_combine'] = loopLog['in_combine']
      if (loopLog['timestamp_key'] != null) {
        employeeFullPayrollData[loopLog['associated_user_key']]['combine_status_timestamp_key'] = loopLog['timestamp_key']
      }
    }
  }
}

const determineProjectMappingErrorsAndWarnings = (us_weekCompletedTasks: TsInterface_UnspecifiedObject, cachedProjects: TsInterface_UnspecifiedObject) => {
  let errorCount = 0
  let tasksMissingCachedProjects: TsInterface_UnspecifiedObject = {}
  // let warningCount = 0
  for (let loopTaskKey in us_weekCompletedTasks) {
    let loopTask = us_weekCompletedTasks[loopTaskKey]
    // Skip Tasks Requiring Sales Partner Approval
    if (
      loopTask['sales_partner_approval_required'] != true ||
      (loopTask['sales_partner_approval_required'] == true && loopTask['sales_partner_approval_granted'] == true)
    ) {
      if (loopTask['associated_project_key'] != null) {
        if (getProp(cachedProjects, loopTask['associated_project_key'], null) == null) {
          tasksMissingCachedProjects[loopTaskKey] = loopTask
        }
      }
    }
  }
  // Return Data
  return {
    errorCount: errorCount,
    // warningCount: warningCount,
    tasksMissingCachedProjects: tasksMissingCachedProjects,
  }
}

const determineTasksMissingUnitMappingDataErrorsAndWarnings = (workflowTasksMissingUnitMappingData: TsInterface_UnspecifiedObject) => {
  let errorCount = 0
  // let warningCount = 0
  if (workflowTasksMissingUnitMappingData != null) {
    errorCount += objectToArray(workflowTasksMissingUnitMappingData).length
  }
  // Return Data
  return {
    errorCount: errorCount,
    // warningCount: warningCount,
  }
}

const determinePunchDataErrorsAndWarnings = (us_aggregatedWeekPunchData: TsInterface_UnspecifiedObject) => {
  let errorCount = 0
  let warningCount = 0
  if (us_aggregatedWeekPunchData != null && us_aggregatedWeekPunchData['punchDataErrors'] != null) {
    errorCount += objectToArray(getProp(us_aggregatedWeekPunchData, 'punchDataErrors', {})).length
  }
  if (us_aggregatedWeekPunchData != null && us_aggregatedWeekPunchData['punchDataWarnings'] != null) {
    warningCount += objectToArray(getProp(us_aggregatedWeekPunchData, 'punchDataWarnings', {})).length
  }
  // Return Data
  return {
    errorCount: errorCount,
    warningCount: warningCount,
  }
}

const determineProjectFieldsErrorsAndWarnings = (
  cachedProjectData: TsInterface_UnspecifiedObject,
  projectTaskRequireFieldMappingHelperObject: TsInterface_UnspecifiedObject,
  us_finalizedWeekPayroll_ErrorAndWarningResolutionData: TsInterface_UnspecifiedObject,
) => {
  let errorCount = 0
  let warningCount = 0
  let projectMissingFieldErrors: TsInterface_UnspecifiedObject = {}
  let projectMissingFieldWarnings: TsInterface_UnspecifiedObject = {}
  for (let loopProjectKey in cachedProjectData) {
    let loopProject = cachedProjectData[loopProjectKey]
    let foundResolvedProjectWarning = false
    if (
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData['warnings_project_mapping_data'] != null &&
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData['warnings_project_mapping_data'][loopProjectKey] != null
    ) {
      foundResolvedProjectWarning = true
    }
    let foundResolvedProjectError = false
    if (
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData['errors_project_mapping_data'] != null &&
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData['errors_project_mapping_data'][loopProjectKey] != null
    ) {
      foundResolvedProjectError = true
    }
    for (let loopProjectPropKey in itemsToCacheFromProjects) {
      let loopProjectProp = itemsToCacheFromProjects[loopProjectPropKey]
      // Custom Errors
      if (loopProjectProp['error_type'] === 'CUSTOM_storage_error') {
        if (
          loopProject['system_storage_quantity'] != null &&
          loopProject['system_storage_quantity'] > 0 &&
          (loopProject['system_storage_manufacturer'] == null ||
            loopProject['system_storage_manufacturer'] === '' ||
            loopProject['system_storage_model'] == null ||
            loopProject['system_storage_model'] === '')
        ) {
          if (
            projectTaskRequireFieldMappingHelperObject != null &&
            projectTaskRequireFieldMappingHelperObject[loopProjectKey] != null &&
            projectTaskRequireFieldMappingHelperObject[loopProjectKey]['unit_pay_required_project_fields'] != null &&
            projectTaskRequireFieldMappingHelperObject[loopProjectKey]['unit_pay_required_project_fields']['system_storage_quantity'] != null
          ) {
            if (foundResolvedProjectError === false) {
              errorCount += 1
            }
            if (projectMissingFieldErrors[loopProjectKey] == null) {
              projectMissingFieldErrors[loopProjectKey] = {
                key: loopProjectKey,
                name: loopProject.id_number,
                tasks: getProp(projectTaskRequireFieldMappingHelperObject, loopProjectKey, {}),
                errors: {},
              }
            }
            projectMissingFieldErrors[loopProjectKey]['errors'][loopProjectPropKey] = 'Project has storage units but is missing model and manufacturer data'
          } else {
            if (foundResolvedProjectWarning === false) {
              warningCount += 1
            }
            if (projectMissingFieldWarnings[loopProjectKey] == null) {
              projectMissingFieldWarnings[loopProjectKey] = {
                key: loopProjectKey,
                name: loopProject.id_number,
                tasks: getProp(projectTaskRequireFieldMappingHelperObject, loopProjectKey, {}),
                warnings: {},
              }
            }
            projectMissingFieldWarnings[loopProjectKey]['warnings'][loopProjectPropKey] = 'Project has storage units but is missing model and manufacturer data'
          }
        }
        if (
          (loopProject['system_storage_quantity'] == null || loopProject['system_storage_quantity'] === '' || loopProject['system_storage_quantity'] === 0) &&
          ((loopProject['system_storage_manufacturer'] != null && loopProject['system_storage_manufacturer'] !== '') ||
            (loopProject['system_storage_model'] != null && loopProject['system_storage_model'] !== ''))
        ) {
          if (
            projectTaskRequireFieldMappingHelperObject != null &&
            projectTaskRequireFieldMappingHelperObject[loopProjectKey] != null &&
            projectTaskRequireFieldMappingHelperObject[loopProjectKey]['unit_pay_required_project_fields'] != null &&
            projectTaskRequireFieldMappingHelperObject[loopProjectKey]['unit_pay_required_project_fields']['system_storage_quantity'] != null
          ) {
            if (foundResolvedProjectError === false) {
              errorCount += 1
            }
            if (projectMissingFieldErrors[loopProjectKey] == null) {
              projectMissingFieldErrors[loopProjectKey] = {
                key: loopProjectKey,
                name: loopProject.id_number,
                tasks: getProp(projectTaskRequireFieldMappingHelperObject, loopProjectKey, {}),
                errors: {},
              }
            }
            projectMissingFieldErrors[loopProjectKey]['errors'][loopProjectPropKey] = 'Missing storage quantity but model and manufacturer are present'
          } else {
            if (foundResolvedProjectWarning === false) {
              warningCount += 1
            }
            if (projectMissingFieldWarnings[loopProjectKey] == null) {
              projectMissingFieldWarnings[loopProjectKey] = {
                key: loopProjectKey,
                name: loopProject.id_number,
                tasks: getProp(projectTaskRequireFieldMappingHelperObject, loopProjectKey, {}),
                warnings: {},
              }
            }
            projectMissingFieldWarnings[loopProjectKey]['warnings'][loopProjectPropKey] = 'Missing storage quantity but model and manufacturer are present'
          }
        }
      }
      // Basic Errors
      if (loopProject[loopProjectPropKey] == null || loopProject[loopProjectPropKey] === '') {
        if (loopProjectProp['error_type'] === 'error') {
          if (
            projectTaskRequireFieldMappingHelperObject != null &&
            projectTaskRequireFieldMappingHelperObject[loopProjectKey] != null &&
            projectTaskRequireFieldMappingHelperObject[loopProjectKey]['unit_pay_required_project_fields'] != null &&
            projectTaskRequireFieldMappingHelperObject[loopProjectKey]['unit_pay_required_project_fields'][loopProjectPropKey] != null
          ) {
            if (foundResolvedProjectError === false) {
              errorCount += 1
            }
            if (projectMissingFieldErrors[loopProjectKey] == null) {
              projectMissingFieldErrors[loopProjectKey] = {
                key: loopProjectKey,
                name: loopProject.id_number,
                tasks: getProp(projectTaskRequireFieldMappingHelperObject, loopProjectKey, {}),
                errors: {},
              }
            }
            projectMissingFieldErrors[loopProjectKey]['errors'][loopProjectPropKey] = 'Missing ' + loopProjectProp.name
          } else {
            if (foundResolvedProjectWarning === false) {
              warningCount += 1
            }
            if (projectMissingFieldWarnings[loopProjectKey] == null) {
              projectMissingFieldWarnings[loopProjectKey] = {
                key: loopProjectKey,
                name: loopProject.id_number,
                tasks: getProp(projectTaskRequireFieldMappingHelperObject, loopProjectKey, {}),
                warnings: {},
              }
            }
            projectMissingFieldWarnings[loopProjectKey]['warnings'][loopProjectPropKey] = 'Missing ' + loopProjectProp.name
          }
        } else if (loopProjectProp['error_type'] === 'warning') {
          if (foundResolvedProjectWarning === false) {
            warningCount += 1
          }
          if (projectMissingFieldWarnings[loopProjectKey] == null) {
            projectMissingFieldWarnings[loopProjectKey] = {
              key: loopProjectKey,
              name: loopProject.id_number,
              tasks: getProp(projectTaskRequireFieldMappingHelperObject, loopProjectKey, {}),
              warnings: {},
            }
          }
          projectMissingFieldWarnings[loopProjectKey]['warnings'][loopProjectPropKey] = 'Missing ' + loopProjectProp.name
        }
      }
    }
  }
  // Return Data
  return {
    errorCount: errorCount,
    warningCount: warningCount,
    projectMissingFieldErrors: projectMissingFieldErrors,
    projectMissingFieldWarnings: projectMissingFieldWarnings,
  }
}

const determineUnitPayErrorsAndWarnings = (
  unitPayTasks: TsInterface_UnspecifiedObject,
  us_finalizedWeekPayroll_ErrorAndWarningResolutionData: TsInterface_UnspecifiedObject,
) => {
  let errorCount = 0
  let warningCount = 0
  let unitPayErrors: TsInterface_UnspecifiedObject = {}
  let unitPayWarnings: TsInterface_UnspecifiedObject = {}
  for (let loopTaskKey in unitPayTasks) {
    let loopTask = unitPayTasks[loopTaskKey]
    let foundResolvedUnitPayWarning = false
    if (
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData['warnings_unit_pay'] != null &&
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData['warnings_unit_pay'][loopTaskKey] != null
    ) {
      foundResolvedUnitPayWarning = true
    }
    let foundResolvedUnitPayError = false
    if (
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData['errors_unit_pay'] != null &&
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData['errors_unit_pay'][loopTaskKey] != null
    ) {
      foundResolvedUnitPayError = true
    }
    if (loopTask != null && loopTask['unit_pay_errors'] != null) {
      if (foundResolvedUnitPayError === false) {
        errorCount += objectToArray(loopTask['unit_pay_errors']).length
      }
      if (objectToArray(loopTask['unit_pay_errors']).length > 0) {
        unitPayErrors[loopTaskKey] = {
          name: loopTask['name'],
          key: loopTaskKey,
          associated_project_id_number: loopTask['associated_project_id_number'],
          errors: loopTask['unit_pay_errors'],
          associated_team_name: getProp(loopTask, 'associated_team_name', null),
          task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
        }
      }
    }
    if (loopTask != null && loopTask['unit_pay_warnings'] != null) {
      if (foundResolvedUnitPayWarning === false) {
        warningCount += objectToArray(loopTask['unit_pay_warnings']).length
      }
      if (objectToArray(loopTask['unit_pay_warnings']).length > 0) {
        unitPayWarnings[loopTaskKey] = {
          associated_project_id_number: loopTask['associated_project_id_number'],
          associated_team_name: getProp(loopTask, 'associated_team_name', null),
          errors: loopTask['unit_pay_warnings'],
          key: loopTaskKey,
          name: loopTask['name'],
          previous_redo_and_retry_tasks: getProp(loopTask, 'previous_redo_and_retry_tasks', null),
          task_completion_scheduled_dates: getProp(loopTask, 'task_completion_scheduled_dates', null),
        }
      }
    }
  }
  // Return Data
  return {
    errorCount: errorCount,
    warningCount: warningCount,
    unitPayErrors: unitPayErrors,
    unitPayWarnings: unitPayWarnings,
  }
}

const determineDistanceAdderErrorsAndWarnings = (
  tasksDisqualifiedForDistanceAdders: TsInterface_UnspecifiedObject,
  us_finalizedWeekPayroll_ErrorAndWarningResolutionData: TsInterface_UnspecifiedObject,
) => {
  // Return Data
  let warningCount = objectToArray(tasksDisqualifiedForDistanceAdders).length
  for (let loopTaskKey in tasksDisqualifiedForDistanceAdders) {
    if (
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData['warnings_distance_adders'] != null &&
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData['warnings_distance_adders'][loopTaskKey] != null
    ) {
      warningCount--
    }
  }
  return {
    // errorCount: errorCount,
    warningCount: warningCount,
    // distanceAdderErrors: {},
    distanceAdderWarnings: tasksDisqualifiedForDistanceAdders,
  }
}

const determineQAAdderErrorsAndWarnings = (
  qaReviewTasksData: TsInterface_UnspecifiedObject,
  us_finalizedWeekPayroll_ErrorAndWarningResolutionData: TsInterface_UnspecifiedObject,
) => {
  // Return Data
  let errorCount = objectToArray(qaReviewTasksData).length
  for (let loopTaskKey in qaReviewTasksData) {
    if (
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData['errors_unresolved_qa_tasks'] != null &&
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData['errors_unresolved_qa_tasks'][loopTaskKey] != null
    ) {
      errorCount--
    }
  }
  return {
    // errorCount: errorCount,
    errorCount: errorCount,
    // distanceAdderErrors: {},
    qaTaskErrors: qaReviewTasksData,
  }
}

const determineTeamCompositionErrorsAndWarnings = (
  tasks: TsInterface_UnspecifiedObject,
  us_finalizedWeekPayroll_ErrorAndWarningResolutionData: TsInterface_UnspecifiedObject,
) => {
  let errorCount = 0
  let tasksWithTeamCompositionRequests: TsInterface_UnspecifiedObject = {}
  for (let loopTaskKey in tasks) {
    let task = tasks[loopTaskKey]
    if (task != null && task['requested_changes_to_team_composition'] === true) {
      tasksWithTeamCompositionRequests[loopTaskKey] = {
        associated_project_id_number: getProp(task, 'associated_project_id_number', null),
        associated_team_name: getProp(task, 'associated_team_name', null),
        key: getProp(task, 'key', null),
        name: getProp(task, 'name', null),
        requested_changes_to_team_composition: getProp(task, 'requested_changes_to_team_composition', null),
        requested_changes_to_team_composition_notes: getProp(task, 'requested_changes_to_team_composition_notes', null),
        task_completion_scheduled_dates: getProp(task, 'task_completion_scheduled_dates', null),
        timestamp_completed: getProp(task, 'timestamp_completed', null),
      }
      errorCount += 1
    }
  }
  for (let loopTaskKey in tasks) {
    if (
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData['errors_team_composition_correction_requests'] != null &&
      us_finalizedWeekPayroll_ErrorAndWarningResolutionData['errors_team_composition_correction_requests'][loopTaskKey] != null
    ) {
      errorCount--
    }
  }
  return {
    errorCount: errorCount,
    teamCompositionErrors: tasksWithTeamCompositionRequests,
  }
}
